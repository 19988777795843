import React, { useContext, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Switch, Route } from "react-router-dom";

import AddFindingModal from "./AddFindingModal";
import CompleteFindingsModal from "./CompleteFindingsModal";
import AddNotesModal from "./AddNotesModal";
import FindingDetail from "./FindingDetail";
import FindingListing from "./FindingListing";
import SuccessNotification from "../../Shared/SuccessNotification/SuccessNotification";
import RolesContext from "../../../contexts/RolesContext";
import InspectionContext from "../../../contexts/InspectionContext";
import { ActionButtons } from "../../Shared/ActionButtons";
import SelectAll from "../../Shared/Selection/SelectAll";
import SelectionStatus from "../../Shared/Selection/SelectionStatus";
import { LoadingMessage } from "@sw-sw/lib-ui";
import PrintModal from "../../Shared/Print/PrintModal";
import { UIControlType } from "@sw-sw/lib-form-control-types";

const Findings: React.FC<{}> = () => {
  const location = useLocation<{ checkAll: boolean }>();
  const history = useHistory();
  const inspectionContext = useContext(InspectionContext);
  const permCheck = useContext(RolesContext).userHasPermission;

  if (!inspectionContext.inspection.id) {
    return <LoadingMessage what="Findings" />;
  }

  const {
    findings,
    setFindings,
    selectedFindings,
    bulkAddNotes,
    selectAllFindings,
    allSelected,
    setAllSelected,
    setSelectedFindings,
  } = inspectionContext;

  const inspectionId = inspectionContext.inspection.id;
  const defaultCreatedDate = inspectionContext.getDefaultFindingDate();

  const [showNewFindingModal, setShowNewFindingModal] =
    useState<boolean>(false);
  const [showBulkCompleteModal, setShowBulkCompleteModal] =
    useState<boolean>(false);
  const [showBulkAddNotesModal, setShowBulkAddNotesModal] =
    useState<boolean>(false);
  const [showSuccessNotification, setShowSuccessNotification] =
    useState<boolean>(false);
  const [showPrintModal, setShowPrintModal] = useState<boolean>(false);
  const [timeoutId, setTimeoutId] = useState<number>(-1);
  const [defaultChecks, setDefaultChecks] = useState<boolean>(true);

  useEffect(() => {
    return () => {
      if (timeoutId > -1) {
        window.clearTimeout(timeoutId);
      }
    };
  }, []);

  const onAddFinding = (data: any) => {
    setFindings([...findings, data]);
  };

  const showSuccessAnimation = () => {
    // show save animation
    const newTimeoutId = window.setTimeout(() => {
      setShowSuccessNotification(false);
    }, 2000);

    setShowSuccessNotification(true);
    setTimeoutId(newTimeoutId);
  };

  const listingView = () => {
    setSelectedFindings([]);
  };

  const toggleModal = (target: any, isVisible: boolean) => {
    setShowBulkCompleteModal(false);
    setShowNewFindingModal(false);
    setShowBulkAddNotesModal(false);

    switch (target) {
      case "showBulkCompleteModal":
        setShowBulkCompleteModal(isVisible);
        break;
      case "showBulkAddNotesModal":
        setShowBulkAddNotesModal(isVisible);
        break;
      case "showNewFindingModal":
        setShowNewFindingModal(isVisible);
        break;
      case "showPrintModal":
        setShowPrintModal(isVisible);
        break;
      default:
        break;
    }
  };

  const handleSelectAll = () => {
    selectAllFindings();
  };

  const readOnly = inspectionContext.readOnlyFindings;
  const hasIncompleteFindings =
    findings.filter(
      (finding: { date_completed: Date }) => !finding.date_completed,
    ).length > 0;

  const hasSelectedFindings = selectedFindings.length > 0;

  if (
    location.state &&
    location.state.checkAll &&
    inspectionContext.inspectionLoaded > 0 &&
    defaultChecks
  ) {
    inspectionContext.handleDefaultChecks(findings);
    setDefaultChecks(false);
  }

  return (
    <>
      <Switch>
        <Route path="/inspection/:inspectionId/findings/:findingId/details">
          <FindingDetail
            onBackClick={listingView}
            onSubmit={showSuccessAnimation}
            onDelete={() => {
              // setSelectedFinding(null);
              history.push(`/inspection/${inspectionId}/findings`);
            }}
          />
        </Route>

        <Route path="/">
          <div className="inspection-findings">
            {!readOnly && (
              <div className="controls">
                {hasIncompleteFindings && (
                  <div className="selection-controls">
                    <SelectAll
                      onSelectAll={handleSelectAll}
                      value={allSelected}
                    ></SelectAll>
                    <SelectionStatus
                      current={selectedFindings.length}
                      total={findings.length}
                      noun="finding"
                      onReset={() => {
                        setAllSelected(false);
                        setSelectedFindings([]);
                      }}
                    />
                  </div>
                )}

                {/* Selection actions */}
                {permCheck("update", "Findings") && hasSelectedFindings && (
                  <ActionButtons
                    primary={
                      "Complete Finding" +
                      (selectedFindings.length > 1 ? "s" : "")
                    }
                    onClick={() =>
                      Promise.resolve(
                        toggleModal("showBulkCompleteModal", true),
                      )
                    }
                    secondary={[
                      {
                        label: "Add Notes",
                        icon: "plus",
                        onClick: () =>
                          toggleModal("showBulkAddNotesModal", true),
                      },
                    ]}
                  />
                )}

                {/* Default actions */}
                {!hasSelectedFindings && (
                  <ActionButtons
                    primary={
                      !readOnly && permCheck("create", "Findings")
                        ? "Add Finding"
                        : undefined
                    }
                    onClick={() => toggleModal("showNewFindingModal", true)}
                    secondary={[
                      findings.length
                        ? {
                            label: "Findings Report",
                            icon: "print",
                            onClick: () => toggleModal("showPrintModal", true),
                          }
                        : false,
                    ].filter(_ => _)}
                  />
                )}
              </div>
            )}

            {permCheck("read", "Findings") && <FindingListing />}
          </div>
        </Route>
      </Switch>

      {/* MODALS */}
      <AddFindingModal
        show={showNewFindingModal}
        hideModal={() => toggleModal("showNewFindingModal", false)}
        inspectionId={inspectionId}
        onAddFinding={onAddFinding}
        defaultDate={defaultCreatedDate}
      />

      {showBulkCompleteModal ? (
        <CompleteFindingsModal
          onSubmit={() => {
            toggleModal("showBulkCompleteModal", false);
            setAllSelected(false);
            setSelectedFindings([]);
          }}
          onClose={() => toggleModal("showBulkCompleteModal", false)}
          findings={selectedFindings}
        />
      ) : null}

      <AddNotesModal
        show={showBulkAddNotesModal}
        hideModal={() => toggleModal("showBulkAddNotesModal", false)}
        inspectionId={inspectionId}
        addNotes={bulkAddNotes}
        selectedFindings={selectedFindings}
      />

      {showPrintModal ? (
        <PrintModal
          jobName="findings"
          jobData={{ inspectionId: inspectionId }}
          statusUpdateKey={inspectionId}
          onClose={() => toggleModal("showPrintModal", false)}
          modalTitle="Findings Report"
          initialFormData={{ reportType: "all" }}
          formSchema={(_formData: any, disabled: boolean) => {
            return {
              reportType: {
                controlType: UIControlType.radio,
                validation: {
                  required: true,
                },
                options: [
                  { value: "all", label: "All Findings" },
                  { value: "open", label: "Open Findings" },
                  { value: "closed", label: "Completed Findings" },
                ],
                disabled,
              },
            };
          }}
          validateData={false}
        />
      ) : null}

      <SuccessNotification show={showSuccessNotification} />
    </>
  );
};

export default Findings;
