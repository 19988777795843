import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
// import { bool, string, arrayOf } from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IModalProps, Modal } from "@sw-sw/lib-ui";
import React from "react";
import CheckmarkSVG from "../CheckmarkSVG";

interface ISuccessModalProps extends IModalProps {
  messages?: string[];
  message?: string;
  isAlert?: boolean;
}

const SuccessModal: React.FC<ISuccessModalProps> = props => {
  const { messages, message, isAlert, ...rest } = props;

  return (
    <Modal disableClose {...rest}>
      <section className="success-modal">
        {isAlert ? (
          <FontAwesomeIcon icon={faExclamationTriangle} className="alert" />
        ) : (
          <CheckmarkSVG />
        )}
        {messages && messages.map((m, index) => <p key={index}>{m}</p>)}
        {message && <p>{message}</p>}
      </section>
    </Modal>
  );
};

export default SuccessModal;
