import instance from "../xhrService";

const baseUrl = "/api/positionables";
const url = (a, b, c) =>
  `${baseUrl}/${a}` + (b ? `/${b}` : "") + (c ? `/${c}` : "");

export const positionableApi = {
  index: (tType, tId, pType, pSubType = null) => {
    const params = {};

    if (pSubType) {
      params.type = pSubType;
    }

    return instance
      .get(url(tType, tId, pType), {
        params,
      })
      .then(response => response.data);
  },
  // update or insert en-masse
  bulkUpdate: (tType, id, data) => {
    return instance
      .put(url(tType, id, "update"), data)
      .then(response => response.data);
  },
  bulkDelete(positionableIds) {
    /** @todo refactor to single xhr */
    return Promise.all(positionableIds.map(id => this.delete(id))).then(
      () => null,
    );
  },
  delete: id => {
    const intId = parseInt(id);

    
return instance.delete(url(intId)).then(response => response);
  },
};

export default positionableApi;
