import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FormContextProvider, FormSchema, FormSchemaFields } from "@sw-sw/lib-form";
import { UIControlType } from "@sw-sw/lib-form-control-types";
import { LayoutTypes } from "@sw-sw/lib-inspection-templates";

import TemplateEditorContext, {
  TemplateEditorProvider,
} from "../../../contexts/TemplateEditorContext";
import AddQuestionGroup from "./AddQuestionGroup";
import QuestionGroup from "./QuestionGroup";
import { questionApi } from "../../../utils/api/question";
import { useUpdateQuestionConfig } from "../../../hooks/questionConfig";
import { questionTypeConfigApi } from "../../../utils/api/questionTypeConfig";
import { questionGroupApi } from "../../../utils/api/questionGroup";
import { useQuestionGroups } from "../../../hooks/questionGroups";

export interface ITemplateEditorProps {
  templateId: number;
}

const schema: FormSchema = {
  condition_group: {
    controlType: UIControlType.select,
    label: "Condition Group",
    validation: {
      required: true,
    },
    options: [
      { value: "no", label: "No Condition Group" },
      { value: "apply", label: "Apply Condition Group" },
    ],
  },
};

const TemplateEditor: React.FC<ITemplateEditorProps> = ({ templateId }) => {
  const [group, setGroup] = React.useState('no');
  const isAlreadyAplied = React.useRef(false);
  const updateQuestionConfigQuery = useUpdateQuestionConfig();
  const questionGroupsQuery = useQuestionGroups(templateId);

  const getInitialValues = () => {
    return {
      condition_group: group,
    };
  };

  const updateQuestionConfig = React.useCallback(async (question: any) => {
    const configValues: Array<any> = [];
    const configTypeValues: Array<any> = [];

    configValues.push({
      value: question.input_label,
      questionId: question.id,
      questionFieldId: 1, // options
    });

    configTypeValues.push({
      value: JSON.stringify(question.options),
      questionId: question.id,
      questionTypeFieldId: 1, // options
    });

    return updateQuestionConfigQuery
      .mutateAsync(configValues)
      .then(async () => {
        await questionTypeConfigApi.update({
          questionTypeId: question.question_type_id,
          data: configTypeValues,
        });
      });
  }, []);

  React.useEffect(() => {
    (async () => {
      let isApplied = false;

      const questionGroupQuery = await questionGroupApi.index(templateId);

      if (questionGroupQuery && questionGroupQuery.groupIds) {
        const { groupIds } = questionGroupQuery;

        const questionGroups = await Promise.all([
          ...groupIds.map(x => questionGroupApi.show(templateId, x)),
        ]);

        questionGroups.forEach(({ name }) => {
          if (name === 'Condition Group') {
            isApplied = true;
          }
        });
      }

      isAlreadyAplied.current = isApplied;
    })();
  }, [isAlreadyAplied.current, questionGroupsQuery.isFetching]);

  return (
    <TemplateEditorProvider initialValue={templateId}>
      <TemplateEditorContext.Consumer>
        {templateEditorContext => (
          <div className="template-editor-wrapper container">
            <div className="template-editor-conditions">
              <FormContextProvider>
                <FormSchemaFields
                  schema={schema}
                  initialFormData={getInitialValues()}
                  formData={{
                    condition_group: group,
                  }}
                  onChange={async (key: string, value: string) => {
                    if (key !== 'condition_group') {
                      return;
                    }

                    if (value === 'apply') {
                      if (isAlreadyAplied.current) {
                        alert('Condition Group already applied');

                        return;
                      }

                      const newGroup = {
                        layout_type: LayoutTypes.FORM,
                        name: 'Condition Group',
                        order: templateEditorContext.getNextQuestionGroupOrder(),
                      };

                      const { id: groupId } = await templateEditorContext.addNewQuestionGroup(newGroup);

                      const newQuestion = {
                        features: [],
                        groupId: groupId,
                        input_label: "Condition",
                        options: [
                          '(I) Installation',
                          '(M) BMP Maintenance',
                          '(S) Street Sweeping',
                          '(C) Contractor Responsibility',
                          '(A) Additional Control Measure Needed',
                          '(R) Remove Control Measure'
                        ],
                        question_type_id: 2, // Select One
                        sort_order: 0
                      };

                      const { id: questionId } = await questionApi.create(groupId, newQuestion);

                      await updateQuestionConfig({ ...newQuestion, id: questionId });
                    }
                    setGroup(value);
                  }}
                />
              </FormContextProvider>
            </div>
            <div className="template-editor">
              {templateEditorContext.warnings.length > 0 && (
                <h5 className="error-messages">
                  {templateEditorContext.warnings.includes("type") && (
                    <>
                      <em>
                        <FontAwesomeIcon icon={faExclamationTriangle} />
                        Template must contain question with 'Inspection Type'
                        feature
                      </em>
                      <br />
                    </>
                  )}
                  {templateEditorContext.warnings.includes("date") && (
                    <em>
                      <FontAwesomeIcon icon={faExclamationTriangle} />
                      Template must contain question with 'Inspection Date'
                      feature
                    </em>
                  )}
                </h5>
              )}

              {templateEditorContext.questionGroupIds.map(questionGroupId => {
                return (
                  <QuestionGroup
                    key={questionGroupId}
                    groupId={questionGroupId}
                  />
                );
              })}
              <AddQuestionGroup />
            </div>
          </div>
        )}
      </TemplateEditorContext.Consumer>
    </TemplateEditorProvider>
  );
};

export default TemplateEditor;
