import React, { Fragment } from "react";
import classnames from "classnames";
import Navigation from "../Navigation/Navigation";
import SecondaryNavigation from "../Navigation/SecondaryNavigation";
import HamburgerNavBar from "../Navigation/HamburgerNavBar";
import { ProjectProvider } from "../../contexts/ProjectContext";
import { RegulationStore } from "../../contexts/RegulationContext";
import { InspectionStore } from "../../contexts/InspectionContext";
import { NotificationStore } from "../../contexts/NotificationsContext";
import { DivisionProvider } from "../../contexts/DivisionContext";
import { useRouteMatch } from "react-router";

/**
 * Default application layout, provides a sidebar and content area
 *
 * User should be logged in
 */
export interface ISidebarLayoutProps {
  checking: boolean;
}

const SidebarLayout: React.FC<ISidebarLayoutProps> = props => {
  const match = useRouteMatch();
  const root = match.path.split("/")[1];

  return (
    <section className="layout layout--sidebar pure-g">
      <DivisionProvider>
        <section className="sidebar pure-u">
          <Navigation match={match} {...props} />
        </section>

        {/* sorry for the nested context :( */}

        <ProjectProvider>
          <RegulationStore>
            <InspectionStore>
              <NotificationStore>
                <section className="content pure-u">
                  <HamburgerNavBar match={match} {...props} />
                  <Fragment>
                    <SecondaryNavigation
                      className={classnames({
                        dashboard: root === "dashboard",
                        inspection: root === "inspection",
                      })}
                    />
                    {!props.checking && props.children}
                  </Fragment>
                </section>
              </NotificationStore>
            </InspectionStore>
          </RegulationStore>
        </ProjectProvider>
      </DivisionProvider>
    </section>
  );
};

export default SidebarLayout;

export function withSidebarLayout(Component: any) {
  return ({ children, ...props }: { children: any; checking: any }) => {
    return (
      <SidebarLayout {...props}>
        <Component>{children}</Component>
      </SidebarLayout>
    );
  };
}
