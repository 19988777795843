import React, { useContext, useCallback, useEffect } from "react";
import {
  shape,
  string,
  arrayOf,
  func,
  array,
  object,
  number,
  bool,
} from "prop-types";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

import ProjectContext from "../../contexts/ProjectContext";
import AppDivisionContext from "../../contexts/AppDivisionContext";
import ProjectListContext from "../../contexts/ProjectListContext";
import DataPlaceholder from "../Shared/Loading/DataPlaceholder";

const ProjectList = props => {
  const {
    selectedProjects,
    handleStormSelect,
    handleSelectAll,
    allSelected,
    handleCancelBulkStorm,
  } = useContext(ProjectContext);
  const { getPath } = useContext(AppDivisionContext);
  const projectListContext = useContext(ProjectListContext);
  const { showBulkStorm, handleCancel, history, isArchived } = props;
  const onAllSelect = useCallback(() => {
    handleSelectAll(props.projects.map(project => project.id));
  }, [props.projects, allSelected]);

  const getClientName = clientId => {
    const client = projectListContext.clients.find(c => c.id === clientId);

    return <h4>{client ? client.name : <DataPlaceholder width={7} />}</h4>;
  };

  useEffect(() => handleCancelBulkStorm, []);

  useEffect(() => {
    if (props.projects.length) {
      projectListContext.addClients(props.projects.map(_ => _.client_id));
    }
  }, [props.projects]);

  return (
    <div className="projects">
      {showBulkStorm && (
        <div className="post-storm-controls">
          <div className="select-all" onClick={onAllSelect}>
            <div className="check-holder">
              <input type="checkbox" defaultChecked={allSelected} readOnly />
              <div className={allSelected ? "checkbox checked" : "checkbox"}>
                {allSelected && (
                  <FontAwesomeIcon icon={faCheck} className="checkmark" />
                )}
              </div>
            </div>
            <div className="check-label">Select All</div>
          </div>
          <div className="storm-buttons">
            <button
              onClick={() =>
                history.push(
                  getPath(
                    `/projects/post-storm?pid=${selectedProjects.join("+")}`,
                  ),
                )
              }
              className="primary"
            >
              Continue
            </button>
            <button
              onClick={() => {
                handleCancelBulkStorm();
                handleCancel();
              }}
              className="warn"
            >
              Cancel
            </button>
          </div>
        </div>
      )}

      <div className="flex-row project-list">
        {!props.projects || props.projects.length === 0 ? (
          <p>No projects found</p>
        ) : (
          props.projects.map((project, index) => {
            const className = selectedProjects.includes(project.id)
              ? "checkbox checked"
              : "checkbox";
            const checked = selectedProjects.includes(project.id);

            return (
              <div className="project" key={project.id}>
                <div
                  className="project-main"
                  onClick={() => props.handleClick(project.id, index)}
                >
                  {isArchived && <h4># Archived Project</h4>}
                  <h3>{project.name}</h3>
                  {getClientName(project.client_id)}
                  {project.address && (
                    <h6>
                      {project.address.street_1}{project.address.street_1 ?", ":""}
                      {project.address.city}{project.address.city ?", ":""}
                      {project.address.state
                        ? `${project.address.state.abbreviation} `
                        : ""}
                      {project.address.zip}
                    </h6>
                  )}

                  <div className="flex-row">
                    <span className="flex-item">
                      {project.inspections ? (
                        <>
                          Inspections
                          <i className="simple-box">
                            {project.inspections.length}
                          </i>
                        </>
                      ) : null}
                    </span>
                  </div>
                </div>
                {showBulkStorm && (
                  <div
                    className="check-holder"
                    onClick={() =>
                      handleStormSelect(project.id, props.projects.length)
                    }
                  >
                    <input type="checkbox" defaultChecked={checked} readOnly />
                    <span className={className}>
                      {checked && (
                        <FontAwesomeIcon icon={faCheck} className="checkmark" />
                      )}
                    </span>
                  </div>
                )}
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

ProjectList.propTypes = {
  handleClick: func.isRequired,
  showBulkStorm: bool,
  projects: arrayOf(
    shape({
      id: number.isRequired,
      name: string.isRequired,
      address: shape({
        street_1: string,
        city: string,
        state: object, // State model
        zip: string,
      }),
      inspection_count: number,
      client: shape({ name: string.isRequired, contacts: array }),
    }),
  ).isRequired,
};

ProjectList.defaultProps = {
  handleClick: (projectId, index) => {},
  showBulkStorm: false,
  projects: [],
};

export default withRouter(ProjectList);
