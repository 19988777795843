import React, { useContext } from "react";

import { Context as InteractionContext } from "./../InteractionContext";
import { Context as PositionableInstanceDataContext } from "../../Positionable/InstanceDataContext";
import { Modal } from "@sw-sw/lib-ui";

function DeleteFeature() {
  const interactionContext = useContext(InteractionContext);
  const dataContext = useContext(PositionableInstanceDataContext);

  if (!interactionContext.modeData) {
    return null;
  }

  return (
    <Modal
      show={true}
      handleClose={() => interactionContext.setDefaultMode()}
      handleSubmit={() =>
        dataContext
          .delete(interactionContext.modeData.feature)
          .then(() => interactionContext.setDefaultMode())
      }
      classes="delete-feature-modal"
      submitBtnText="Remove from Map"
      hideHeader
      isSmall
      disableClose
      cancelBtn
      dismissOnEsc
      dismissOnBgClick
    >
      <p>Are you sure you want to remove this from the map?</p>
    </Modal>
  );
}

DeleteFeature.propTypes = {};

export default DeleteFeature;
