import React, { PropsWithChildren } from "react";
import { InspectionStore } from "../../contexts/InspectionContext";

/**
 *
 */
const FullScreenLayout: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <InspectionStore>
      <div className="layout layout--fullscreen">{children}</div>
    </InspectionStore>
  );
};

export default FullScreenLayout;
