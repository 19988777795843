import React, { useContext } from "react";
import RolesContext from "../../contexts/RolesContext";

export interface ISiteMapCard {
  imgSrc: string;
  title: string;
  label: string;
  handleImgClick?: () => void;
  handleDelete?: () => void;
  showActions?: boolean;
}

const SiteMapCard: React.FC<ISiteMapCard> = ({
  imgSrc,
  title,
  label,
  handleImgClick,
  handleDelete,
  showActions,
}) => {
  const permCheck = useContext(RolesContext).userHasPermission;
  const canUpdate = permCheck("update", label);
  const canDelete = canUpdate;

  return (
    <section className="card">
      <div
        className="card-img card-cap"
        style={{
          backgroundImage: `url(${imgSrc})`,
          cursor: handleImgClick && canUpdate ? "pointer" : "initial",
        }}
        title={title}
        onClick={() => (handleImgClick && canUpdate ? handleImgClick() : null)}
      />

      <div className="card-body">
        <p className="card-title">{title}</p>

        {showActions && (
          <div className="card-toolbar">
            {canUpdate && canDelete && (
              <button className="outline small" onClick={handleDelete}>
                <i className="fa fa-trash" />
              </button>
            )}
          </div>
        )}
      </div>

      <div className="card-body card-actions">
        <button
          className="card-action pure-button button-primary"
          onClick={handleImgClick}
        >
          {canUpdate && showActions ? "Update Site Map" : "View Site Map"}
        </button>
      </div>
    </section>
  );
};

export default SiteMapCard;
