import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";

class DashboardViews extends Component {
  static propTypes = {
    views: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string, // correlates to keys in `/dashboard` payload
        label: PropTypes.string, // display label
      }),
    ).isRequired,
  };
  static defaultProps = {
    views: [],
  };

  state = {
    format: "list",
    date: moment(),
    activeView:
      this.props.views[0] && this.props.views[0].hasOwnProperty("key")
        ? this.props.views[0].key
        : "",
  };

  decrement = () =>
    this.setState(({ date }) => ({
      date: moment(date.subtract(1, "weeks")),
    }));
  increment = () =>
    this.setState(({ date }) => ({
      date: moment(date.add(1, "weeks")),
    }));

  setFormat = format => this.setState({ format });
  setView = view => this.setState({ activeView: view });

  getStateAndHelpers() {
    const { format, date, activeView } = this.state;
    const { views } = this.props;

    return {
      switchToList: () => this.setFormat("list"),
      switchToCalendar: () => this.setFormat("calendar"),
      isCalendar: format === "calendar",
      date,
      increment: this.increment,
      decrement: this.decrement,
      setView: this.setView,
      activeView,
      views,
    };
  }

  render() {
    return (
      <section className="inspections-paper-page">
        {this.props.children(this.getStateAndHelpers())}
      </section>
    );
  }
}

export default DashboardViews;
