import instance from "../xhrService";

const baseUrl = "/api/notifications";
const getBaseUrl = a => `${baseUrl}/${a}`;

const notificationApi = {
  index: () => instance.get(baseUrl).then(response => response.data),
  read: id => instance.put(getBaseUrl(id)).then(response => response.data),
  readAll: () => instance.put(baseUrl).then(response => response.data),
  updateTypeSettings: (userId, typeId, isEnabled, defaultMessage) => {
    return instance
      .put(`/api/users/${userId}/notification-types/${typeId}`, {
        is_enabled: isEnabled,
        default_email_message: defaultMessage,
      })
      .then(response => response.data);
  },
  updateFrequencySettings: frequency => {
    return instance
      .put(`/api/notifications/frequency`, {
        frequency,
      })
      .then(response => response.data);
  },
};

export default notificationApi;
