import React, { useContext } from "react";
import PropTypes from "prop-types";

// components and helpers
import RolesContext from "../../contexts/RolesContext";
import ResourceIndex from "../Shared/ResourceIndex/ResourceIndex";
import { getIcon } from "../../utils/positionable";
import LIDetailsForm from "./Form/LIDetailsForm";
import { extractInitialFormData, defaultData } from "./Form/FormSchema";
import { get } from "lodash";
import DivisionError from "../Shared/DivisionError/DivisionError";
import { AppDivisionContext } from "../../contexts/AppDivisionContext";

function LegendItems() {
  const roleStore = useContext(RolesContext);
  const appDivisionContext = useContext(AppDivisionContext);

  if (!appDivisionContext.appDivisionId) {
    return <DivisionError resource="legend items" />;
  }

  return (
    <ResourceIndex
      resourceName="legendItems"
      resourceTableHeaders={[
        ["Name", "name"],
        ["Map Symbol", "map_symbol"],
      ]}
      resourceSearchProps={["name", "type"]}
      enableRead={roleStore.userHasPermission("read", "Legend Item")}
      enableEdit={roleStore.userHasPermission("update", "Legend Item")}
      enableCallToAction={roleStore.userHasPermission("create", "Legend Item")}
      searchInputPlaceholder="Search Map Legend"
      callToActionContent="Create"
      renderRowCell={(legendItem, propName) => {
        if (propName === "map_symbol") {
          const { src } = getIcon(legendItem.type, legendItem);

          return (
            <img
              src={src}
              className="list-icon"
              alt={`${legendItem.name} map legend icon`}
            />
          );
        }

        return get(legendItem, propName);
      }}
      renderEditor={(item, handleClose, allItems, updateById) => {
        return (
          <LIDetailsForm
            initialValue={extractInitialFormData(item)}
            onCancel={handleClose}
            onSubmit={data => {
              return updateById(item.id, data).then(handleClose);
            }}
            onDelete={() => {
              updateById(item.id, false);
              handleClose();
            }}
            enableDelete={roleStore.userHasPermission("delete", "Legend Item")}
          />
        );
      }}
      renderReader={(item, handleClose) => {
        return (
          <LIDetailsForm
            initialValue={extractInitialFormData(item)}
            onCancel={handleClose}
            onSubmit={() => {
              handleClose();
            }}
            readOnly
            title="Legend Item"
            submitBtnText={"Close"}
            showSubmit={false}
            cancelText="Close"
            enableDelete={false}
          />
        );
      }}
      renderCallToAction={(handleClose, pushData) => {
        return (
          <LIDetailsForm
            onCancel={handleClose}
            onSubmit={data => {
              pushData(data);
              handleClose();
            }}
            initialValue={defaultData()}
          />
        );
      }}
    />
  );
}

LegendItems.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  permCheck: PropTypes.func.isRequired,
  enableDelete: PropTypes.bool,
};

LegendItems.propTypes = {};

export default LegendItems;
