import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";

export default function Checkbox({ handleClick, checked }) {
  return (
    <div className="finding-check-holder" onClick={handleClick}>
      <input type="checkbox" defaultChecked={checked} readOnly />
      <span className={classnames("checkbox", { checked: checked })}>
        {checked && <FontAwesomeIcon icon={faCheck} className="checkmark" />}
      </span>
    </div>
  );
}

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
};
