import React, { Fragment, useContext } from "react";
import { shape, string, number, arrayOf } from "prop-types";
import { withRouter } from "react-router-dom";
import AppDivisionContext from "../../../contexts/AppDivisionContext";

import DashTable from "../utils/DashTable";
import DashboardViews from "../utils/DashboardViews";
import DashHeader from "../utils/DashHeader";

export function getProjectProps(projects) {
  return {
    getCtaText: () => "View Project",
    getCtaRoute: ({ projectId }) => `projects/${projectId}/inspections`,
    tableData: projects,
    tableKeys: [
      "siteName",
      "numberOfInspections",
      "averageFindings",
      "averageCarryOver",
      "averageDaysToCorrect",
      "maxDaysToCorrect",
    ],
    showPopper: false,
    showCTA: false,
  };
}

const tableKeys = (history, getPath) => {
  return {
    siteName: null,
    inspectionsEligibleToBeCertified: ({ projectId }) =>
      history.push(getPath(`/projects/${projectId}/inspections`), {
        checkAll: true,
      }),
    openCorrectiveActions: ({ openCorrectiveActionId }) =>
      history.push(`/inspection/${openCorrectiveActionId}/findings`, {
        checkAll: true,
      }),
  };
};

function getInspectionProps(inspections, history, getPath) {
  return {
    getCtaText: (data) => 
    data.inspectionsEligibleToBeCertified > 0 && data.openCorrectiveActions === 0 
    ? "Certify Inspection" : "View Project",
    getCtaRoute: ({ projectId }) => `/projects/${projectId}/inspections`,
    tableData: inspections,
    tableKeys: Object.keys(tableKeys(history, getPath)),
    getClickHandler: tableKeys(history, getPath),
    showPopper: false,
  };
}

const views = [
  { key: "inspections", label: "Certifications Due" },
  { key: "projects", label: "Project Stats" },
];

const ClientUserView = props => {
  const { getPath } = useContext(AppDivisionContext);

  return (
    <DashboardViews views={views}>
      {({ activeView, ...headerProps }) => {
        const dashTableProps =
          activeView === "inspections"
            ? getInspectionProps(props.data.inspections, props.history, getPath)
            : getProjectProps(props.data.projects);

        return (
          <Fragment>
            <DashHeader {...headerProps} activeView={activeView}>
              <DashHeader.Tabs />
            </DashHeader>
            <DashTable {...dashTableProps} />
          </Fragment>
        );
      }}
    </DashboardViews>
  );
};

export const projectsPropType = arrayOf(
  shape({
    projectId: number.isRequired,
    siteName: string.isRequired,
    numberOfInspections: number.isRequired,
    averageFindings: number.isRequired,
    averageCarryOver: number.isRequired,
    averageDaysToCorrect: number.isRequired,
    maxDaysToCorrect: number.isRequired,
  }),
);

ClientUserView.propTypes = {
  data: shape({
    inspections: arrayOf(
      shape({
        projectId: number.isRequired,
        siteName: string.isRequired,
        inspectionsEligibleToBeCertified: number.isRequired,
        openCorrectiveActions: number.isRequired,
        openCorrectiveActionId: number.isRequired,
      }),
    ),
    projects: projectsPropType.isRequired,
  }),
};

export default withRouter(ClientUserView);
