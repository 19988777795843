import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { isUndefined } from "lodash";
import classnames from "classnames";

const DATE_FORMAT = "ddd M/D";

const CTA = ({ isLink, ctaAction, ctaText }) => {
  if (!ctaText) {
    return null;
  }

  if (isLink)
    return (
      <Link to={ctaAction}>
        <button className="calendar-button">{ctaText}</button>
      </Link>
    );

  return (
    <button onClick={ctaAction} className="calendar-button">
      {ctaText}
    </button>
  );
};

const CalendarView = props => {
  const sortInspectionsByDay = inspections => {
    const sortedInspections = {};

    for (let i = 1; i < 6; i++) {
      sortedInspections[
        props.activeDate.startOf("week").add(i, "days").format(DATE_FORMAT)
      ] = [];
    }

    for (let i = 0; i < inspections.length; i++) {
      const dueDate = inspections[i].dueDate;
      let splitDueDate = dueDate.split("-")
      let sliceDueDate;
      if(splitDueDate[0] < 10){
        sliceDueDate = splitDueDate[0].slice(1,2)
      }
      else{
        sliceDueDate = splitDueDate[0]
      }
      const date = moment().month(sliceDueDate-1).date(splitDueDate[1]).format("ddd") + " " + sliceDueDate + "/" + splitDueDate[1]     
      if (sortedInspections[date]) {
        sortedInspections[date].push(inspections[i]);
      }
    }

    return sortedInspections;
  };

  const renderHeader = days => {
    return days.map(day => {
      const isToday = moment().format(DATE_FORMAT) === day ? "today" : "";

      if (isToday) {
        return (
          <div className="header-day" key={day}>
            <b>{day}</b>
          </div>
        );
      }

      return (
        <div className="header-day" key={day}>
          {day}
        </div>
      );
    });
  };

  const renderInspection = inspectionData => {
    const {
      inspectionOverdue,
      inspectionId,
      siteName,
      clientName,
      dueDate,
      inspectorName,
    } = inspectionData;

    const isLink = isUndefined(props.onCtaClick);

    return (
      <div className="calendar-inspection" key={inspectionId}>
        <aside
          className={classnames("highlight", {
            "overdue-highlight": inspectionOverdue,
          })}
        />
        <div className="inspection-text">
          <p className="site-name">{siteName}</p>
          {inspectorName && <p className="client-name">{inspectorName}</p>}
          <p className="client-name">{clientName}</p>
          <p className={classnames("due-date", { overdue: inspectionOverdue })}>
            Due Date: {dueDate}
          </p>

          <CTA
            isLink={isLink}
            ctaAction={
              isLink
                ? props.getCtaRoute(inspectionData)
                : () => props.onCtaClick(inspectionData)
            }
            ctaText={props.getCtaText(inspectionData)}
          />
        </div>
      </div>
    );
  };

  const renderDay = (day, inspections, i) => {
    const border = i > 0 ? "border-left" : "";
    const isToday = moment().format(DATE_FORMAT) === day ? "today" : "";
    const dayClassName = `calendar-day ${border} ${isToday}`;

    return (
      <div className={dayClassName} key={`CalenHeader-${day}`}>
        {inspections &&
          inspections.map(inspection => renderInspection(inspection))}
      </div>
    );
  };

  const { data } = props;
  const sortedInspections = sortInspectionsByDay(data);
  const days = Object.keys(sortedInspections);

  return (
    <div className="calendar-holder">
      <div className="calendar-view">
        <div className="calendar-header">{renderHeader(days)}</div>
        <div className="calendar-body">
          {days.map((day, i) => renderDay(day, sortedInspections[day], i))}
        </div>
      </div>
    </div>
  );
};

CalendarView.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      inspectionId: PropTypes.number.isRequired,
      dueDate: PropTypes.string.isRequired,
      inspectionOverdue: PropTypes.bool.isRequired,
      siteName: PropTypes.string.isRequired,
      clientName: PropTypes.string.isRequired,
      inspectorName: PropTypes.string,
    }).isRequired,
  ),
  getCtaText: PropTypes.func.isRequired,
  getCtaRoute: PropTypes.func.isRequired,
  onCtaClick: PropTypes.func,
  activeDate: PropTypes.object.isRequired,
};

CalendarView.defaultProps = {
  getCtaRoute: () => "#",
  activeDate: moment(),
};

export default CalendarView;
