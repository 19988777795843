import {
  CertFormSchema,
  CertSignature,
  getCertFormSchema,
  SignatureGroupOptions,
  SignatureGroupType,
  SignatureLine,
} from "@sw-sw/lib-certification";
import { FormContext, FormSchemaFields } from "@sw-sw/lib-form";
import { find } from "lodash";
import React, { Dispatch, Fragment, useContext } from "react";
import RolesContext from "../../../contexts/RolesContext";
import SignatureCheckbox from "./SignatureCheckbox";

function CertificationForm({
  line,
  options = { enabled: true, errors: [] },
  userData = [],
  isCcr: ccr = false,
  isPe: pe = false,
  minCertificationDate: minDate = new Date(),
  onSave,
  readOnly = false,
}: {
  line: SignatureLine;
  type: SignatureGroupType;
  options?: SignatureGroupOptions;
  userData?: CertSignature[];
  isCcr?: boolean;
  isPe?: boolean;
  minCertificationDate?: Date;
  onSave: Dispatch<{
    lineType: string;
    formData: CertFormSchema;
  }>;
  readOnly?: boolean;
}) {
  const formContext = useContext(FormContext);
  const permCheck = useContext(RolesContext).userHasPermission;

  return (
    <Fragment>
      <FormSchemaFields
        className="pure-u pure-u-lg-2-3 pure-u-md-1-2 pure-u-1 insp-form-row"
        formData={formContext.value}
        onChange={formContext.set}
        schema={getCertFormSchema({
          line,
          enabled: !readOnly && options.enabled,
          userSignature: find(userData, { lineType: line.type }),
          date: true,
          minDate,
          ccr,
          pe,
        })}
      />
      {!readOnly && (
        <SignatureCheckbox
          lineType={line.type}
          enabled={
            options.enabled &&
            !readOnly &&
            permCheck(
              "all",
              line.type.startsWith("compliance") ? "Certify" : "Sign Off",
            )
          }
          errors={options.errors}
          userSignatures={userData}
          onSubmit={() =>
            formContext.getTransformedData().then((data: any) =>
              onSave({
                lineType: line.type,
                formData: data,
              }),
            )
          }
        />
      )}
    </Fragment>
  );
}

export default CertificationForm;
