import {
  AnySignatureLineGroupData,
  CertFormSchema,
  CertSignature,
  SignatureGroupAttestations,
  SignatureGroupOptions,
  SignatureGroupType,
  SignatureLineGroups,
} from "@sw-sw/lib-certification";
import React, { FC, PropsWithChildren } from "react";
import {
  SignatureEditorFormType,
  SignatureResourceType,
} from "../../../contexts/SignatureEditorContext";
import { SignatureEditorButton } from "../../InspectionTemplates/SignatureEditor/Buttons/SignatureEditorButton";
import CertificationForm from "./CertificationForm";
import ComplianceCertificationInstructions from "./ComplianceCertificationInstructions";
import InspectionCertificationStatement from "./InspectionCertificationStatement";

type CertUIProps = {
  attestationGroups: SignatureGroupAttestations;
  signatureGroups: SignatureLineGroups;
  optionGroups?: AnySignatureLineGroupData<SignatureGroupOptions>;
  userDataGroups?: AnySignatureLineGroupData<CertSignature[]>;
  onSave(data: {
    group: SignatureGroupType;
    lineType: string;
    formData: CertFormSchema;
  }): Promise<void>;
  isCcr?: boolean;
  isPe?: boolean;
  inspectorMinCertificationDate?: Date;
  minCertificationDate?: Date;
  readOnly?: boolean;
  renderEditButton?: SignatureEditorButton;
  renderDeleteButton?: SignatureEditorButton;
  renderReorderButtons?: SignatureEditorButton;
};

export const CertificationUI: FC<PropsWithChildren<CertUIProps>> = ({
  attestationGroups,
  signatureGroups,
  optionGroups = {},
  userDataGroups = {},
  children,
  onSave,
  isCcr = false,
  isPe = false,
  inspectorMinCertificationDate,
  minCertificationDate,
  readOnly = false,
  renderEditButton,
  renderDeleteButton,
  renderReorderButtons,
}) => {
  const showActions = renderEditButton || renderDeleteButton;

  return (
    <section className="inspection-certification">
      <section>
        <h3>Inspection Certification</h3>
        <InspectionCertificationStatement
          attestations={attestationGroups[SignatureGroupType.inspection]}
        />

        {showActions
          ? (() => {
              const buttonProps = {
                resourceType: SignatureResourceType.ATTESTATION,
                resourceIndex: 0,
                groupType: SignatureGroupType.inspection,
              };

              return (
                <div className="attestation-edit-mode">
                  {renderEditButton
                    ? renderEditButton({
                        ...buttonProps,
                        formType: attestationGroups[
                          SignatureGroupType.inspection
                        ].length
                          ? SignatureEditorFormType.EDIT
                          : SignatureEditorFormType.ADD,
                      })
                    : null}
                  {renderDeleteButton &&
                  attestationGroups[SignatureGroupType.inspection].length > 1
                    ? renderDeleteButton(buttonProps)
                    : null}
                </div>
              );
            })()
          : null}

        {signatureGroups.inspection.map((line, index) => {
          const { type, label } = line;
          const buttonProps = {
            resourceType: SignatureResourceType.LINE,
            resourceIndex: index,
            groupType: SignatureGroupType.inspection,
          };

          return (
            <React.Fragment key={`${type}_${index}`}>
              <div className="pure-g">
                {label ? <h4 className="pure-u-1">{label}</h4> : null}
                <CertificationForm
                  line={line}
                  type={SignatureGroupType.inspection}
                  options={optionGroups[SignatureGroupType.inspection]}
                  userData={userDataGroups[SignatureGroupType.inspection]}
                  isCcr={isCcr && line.userFieldLabel === "Inspector Name"}
                  isPe={isPe && line.label === "Supervising Engineer"}
                  minCertificationDate={inspectorMinCertificationDate}
                  onSave={data => {
                    return onSave({
                      ...data,
                      group: SignatureGroupType.inspection,
                    });
                  }}
                  readOnly={readOnly}
                />
                {renderEditButton && renderEditButton(buttonProps)}
                {renderDeleteButton && renderDeleteButton(buttonProps)}
                {renderReorderButtons && renderReorderButtons(buttonProps)}
              </div>
            </React.Fragment>
          );
        })}
        {renderEditButton &&
          renderEditButton({
            resourceType: SignatureResourceType.LINE,
            resourceIndex: signatureGroups.inspection.length,
            formType: SignatureEditorFormType.ADD,
            groupType: SignatureGroupType.inspection,
          })}
        <hr />
      </section>

      <section>
        <h3>Compliance Certification</h3>
        {readOnly ? null : <ComplianceCertificationInstructions />}
        <InspectionCertificationStatement
          attestations={attestationGroups[SignatureGroupType.compliance]}
        />

        {renderEditButton && (
          <div className="attestation-edit-mode">
            {renderEditButton({
              resourceType: SignatureResourceType.ATTESTATION,
              resourceIndex: 0,
              groupType: SignatureGroupType.compliance,
            })}
            {renderDeleteButton &&
              renderDeleteButton({
                resourceType: SignatureResourceType.ATTESTATION,
                resourceIndex: 0,
                groupType: SignatureGroupType.compliance,
              })}
          </div>
        )}

        {signatureGroups.compliance.map((line, index) => {
          const { type, label } = line;
          const buttonProps = {
            resourceType: SignatureResourceType.LINE,
            resourceIndex: index,
            groupType: SignatureGroupType.compliance,
          };

          return (
            <React.Fragment key={`${type}_${index}`}>
              <div className="pure-g">
                {label && <h4 className="pure-u-1">{label}</h4>}

                <CertificationForm
                  line={line}
                  type={SignatureGroupType.compliance}
                  options={optionGroups[SignatureGroupType.compliance]}
                  userData={userDataGroups[SignatureGroupType.compliance]}
                  minCertificationDate={minCertificationDate}
                  onSave={data =>
                    onSave({
                      ...data,
                      group: SignatureGroupType.compliance,
                    })
                  }
                  readOnly={readOnly}
                />

                {renderEditButton && renderEditButton(buttonProps)}
                {renderDeleteButton && renderDeleteButton(buttonProps)}
                {renderReorderButtons && renderReorderButtons(buttonProps)}
              </div>
            </React.Fragment>
          );
        })}

        {renderEditButton &&
          renderEditButton({
            formType: SignatureEditorFormType.ADD,
            resourceType: SignatureResourceType.LINE,
            resourceIndex: signatureGroups.compliance.length,
            groupType: SignatureGroupType.compliance,
          })}
        <hr />
      </section>

      {children}
    </section>
  );
};

/** @todo Add to `certificiationApi.index` or profile. */
export function getcDOTObservationTemplates(clientName: string) {
  return [
    {
      label: '"Green Book" Statement',
      content: `${
        clientName || "Client"
      } is under contract to use the CDOT "Green Book". However, for ease of use with our electronic inspection platform, CDOT has allowed ${
        clientName || "client"
      } to use the 1176 form for the inspection documentation from the "Red Book"`,
    },
  ];
}
