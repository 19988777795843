import React from "react";

function HandIcon({ title }) {
  return (
    <svg width="15px" height="17px" viewBox="0 0 15 17" version="1.1">
      <title>{title}</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-520.000000, -56.000000)"
          fill="#DBDBE0"
          fillRule="nonzero"
        >
          <g transform="translate(0.000000, 39.000000)">
            <g transform="translate(520.000000, 12.000000)">
              <path d="M6.27616422,12.7832674 L6.67227694,6.37976104 C6.67227694,5.84908372 7.14041015,5 7.93263558,5 C8.65284052,5 9.51708645,5.38916337 9.44506595,6.37976104 L9.04895323,12.3233471 L10.1652709,8.07792848 C10.1652709,7.54725115 11.1015373,6.37976104 11.7497218,6.37976104 C12.4699267,6.30900406 13.0821009,7.29960174 12.8660394,8.21944243 C12.6499779,9.06852615 12.6139677,8.75011975 12.2538652,10.1652593 C11.8577525,11.5450203 11.2455783,13.3139447 11.2455783,13.3139447 C11.2455783,13.3139447 11.9657832,11.4035064 12.6499779,10.6959366 C13.2621521,9.98836684 13.8383161,9.70533894 14.3784698,9.98836684 C14.9186235,10.2360163 15.2427157,11.0851 14.7745825,12.0756976 C13.9103366,13.7031081 13.5862444,14.3045424 13.1901316,15.8611959 C12.1818447,19.8943435 10.6694143,21.9816744 7.75258434,21.9816744 C4.22358014,21.9816744 2.63912928,19.4344232 1.95493458,18.3730686 C0.946647668,16.6749011 0.766596434,16.0380883 0.0824017414,14.7290843 C-0.13365974,14.3399209 -0.0616392464,12.1110761 2.02695508,12.8894029 C2.42306779,13.0309168 3.89948792,15.436654 3.89948792,15.436654 C3.89948792,15.436654 3.82746743,13.2078093 3.5033752,11.0497215 C3.10726249,8.89163371 2.71114977,7.72414359 2.71114977,7.72414359 C2.71114977,7.19346627 3.25130347,6.34438255 3.97150841,6.34438255 C4.61969286,6.34438255 5.23186706,6.66278895 5.23186706,7.19346627 C5.23186706,7.19346627 5.70000027,8.11330697 5.952072,9.66996045 C6.20414372,11.1912354 6.27616422,12.7832674 6.27616422,12.7832674 Z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

HandIcon.defaultProps = {
  title: "Hand",
};

export default HandIcon;
