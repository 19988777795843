import React, { useContext } from "react";
import DashWidgets from "./DashWidgets";
import InspectorView from "./Views/InspectorView";
import ContractorView from "./Views/ContractorView";
import ClientView from "./Views/ClientUserView";
import AreaManagerView from "./Views/AreaManagerView";
import RegionalManagerView from "./Views/RegionalManagerView";
import AdminView from "./Views/AdminView"

import dashboardContext, { DashboardStore } from "../../contexts/DashContext";
import { WeatherStore } from "../../contexts/WeatherContext";
import RolesContext from "../../contexts/RolesContext";

const viewControl = {
  "Admin" : AdminView,
  "Divisions Admin" : AdminView,
  "Client User": ClientView,
  "BMP Contractor": ContractorView,
  Inspector: InspectorView,
  "Client Inspector": InspectorView,
  "Area / Assistant Manager": AreaManagerView,
  "Regional Manager": RegionalManagerView,
  "Regional Manager (External)": RegionalManagerView,
  "Client Manager" : AreaManagerView
};

const DashboardUI = props => {
  const rolesStore = useContext(RolesContext);
  const { name, data } = useContext(dashboardContext).data;
  const { handleReassign } = useContext(dashboardContext);
  const ActiveView = viewControl[name] || <React.Fragment />;

  return (
    <WeatherStore>
      {rolesStore.userHasPermission("read", "Dashboard") && (
        <section className="dashboard-main">
          <DashWidgets />
          {typeof ActiveView === "function" && data && (
            <ActiveView data={data} handleReassign={handleReassign} />
          )}
        </section>
      )}
    </WeatherStore>
  );
};

const Dashboard = props => (
  <DashboardStore>
    <DashboardUI {...props} />
  </DashboardStore>
);

export default Dashboard;
