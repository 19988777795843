import { isEmpty } from "lodash";
import React, { useContext, useState } from "react";
import { useRouteMatch } from "react-router";
import InspectionContext from "../../../contexts/InspectionContext";
import { ProjectContext } from "../../../contexts/ProjectContext";
import { withProjectContext } from "../../../hoc/withProjectContext";
import { useClient } from "../../../hooks/client";
import { getInspectionTypeLabel } from "../../../utils";
import NavTabs from "../../Shared/NavTabs";
import Loading from "../../Shared/ResourceIndex/Loading";
import InspectionCertification from "../Certification/InspectionCertification";
import InspectionQuestions from "../Details/InspectionQuestions";
import Findings from "../Findings/Findings";
import InspectionHeader from "../Header/InspectionHeader";
import InspectionMap from "../Map/InspectionMap";
import InspectionPrint from "./InspectionPrint";

function InspectionDetail() {
  const projectContext = useContext(ProjectContext) as any;
  const inspectionContext = useContext(InspectionContext) as any;
  const match = useRouteMatch<{ id: string }>();
  const [modal, setModal] = useState<null | "print">(null);

  const clientQuery = useClient(projectContext.project.client_id);

  if (
    !projectContext.project ||
    !inspectionContext.inspection ||
    isEmpty(inspectionContext.inspection)
  ) {
    return (
      <section className="inspection-detail">
        <Loading what="inspection details" showLoadingText />
      </section>
    );
  }
  return (
    <section className="inspection-detail">
      <InspectionHeader
        title={getInspectionTypeLabel(inspectionContext.inspection)}
        clientName={clientQuery.data ? clientQuery.data.name : null}
        projectName={projectContext.projectName}
        enablePrint
        handlePrint={() => setModal("print")}
        inspectionNumber={inspectionContext.inspection.number}
        inspectionId={inspectionContext.inspection.id}
      />

      <NavTabs
        tabs={[
          {
            to: "/questionnaire",
            label: "Inspection Questions",
            Component: InspectionQuestions,
          },
          {
            label: "Findings",
            to: "/findings",
            showTab: func => func("read", "Findings"),
            Component: Findings,
          },
          {
            label: "Map",
            to: "/map",
            Component: InspectionMap,
          },
          {
            label: "Certification",
            to: "/certification",
            showTab: func => func("all", "Certify") || func("all", "Sign Off"),
            Component: InspectionCertification,
          },
        ]}
        url={match.url}
      />
      {/* load modals! */}
      {modal === "print" ? (
        <InspectionPrint onClose={() => setModal(null)} />
      ) : null}
    </section>
  );
}

export default withProjectContext(InspectionDetail);
