import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import ContextMenu from "../../../../Shared/ContextMenu";
import useMap from "../../../useMap";

const handleBackgroundClick = (event, callback) => {
  /**
   * @note "dash" and boolean are important here
   *
   * contextmenu targets shared/ContextMenu
   * context-menu targets this component
   */
  if (
    !event.target.classList.contains("contextmenu") ||
    event.target.classList.contains("context-menu")
  ) {
    event.preventDefault();
    callback();
  }
};

export const extractMapEventFeature = (event, map) => {
  const features = (map.getFeaturesAtPixel(event.pixel) || []).filter(
    /**
     * Ignore "uncontrolled" features (ie. without a positionable type)
     * because they are likely to be selection overlay geometries
     */
    _ => _.get("positionable_type"),
  );

  return features.length ? features[0] : null;
};

/**
 * Provides a right-click menu
 *
 * @note only one of these at a time, per map
 */
function ContextMenuInteraction({ getOptions, featureOnly, featureFilter }) {
  const map = useMap();
  const [coordinates, setCoordinates] = useState(null);
  const [options, setOptions] = useState(null);
  const [show, setShow] = useState(false);
  const menuOpenTiming = useRef(Date.now());

  const close = () => {
    setShow(false);
    setOptions(null);
    setCoordinates(null);
  };

  const handleRightClick = event => {
    event.stopPropagation();
    event.preventDefault();
    const feature = extractMapEventFeature(event, map);

    /**
     * Don't continue with features that don't pass the filter
     */

    if (
      feature &&
      typeof featureFilter === "function" &&
      !featureFilter(feature)
    ) {
      return;
    }

    const menuOptions = getOptions(event, feature).map(opt => ({
      ...opt,
      onClick: () => {
        opt.onClick({
          feature,
        });
        close();
      },
    }));

    if (menuOptions && menuOptions.length) {
      setCoordinates([
        event.originalEvent.clientX,
        event.originalEvent.clientY,
      ]);

      setOptions(menuOptions);

      const showMenu = featureOnly ? !!feature : true;

      setShow(showMenu);
      menuOpenTiming.current = Date.now();
    }
  };

  useEffect(() => {
    if (map) {
      map.on("contextmenu", handleRightClick);
      map.on("dblclick", handleRightClick);
    }

    return () => {
      if (map) {
        map.un("contextmenu", handleRightClick);
        map.un("dblclick", handleRightClick);
      }
    };
  }, [getOptions]);

  if (show && coordinates && coordinates.length && options && options.length) {
    return (
      <div
        className="map-interaction-context-menu"
        onClick={e => {
          const currentTime = Date.now();
          const diff = Math.abs((menuOpenTiming.current - currentTime) / 1000);

          console.log({ diff });

          if (diff > 0.5) {
            handleBackgroundClick(e, close);
          }
        }}
        onContextMenu={e => {
          e.preventDefault();
          handleBackgroundClick(e, close);
        }}
      >
        <ContextMenu
          options={options}
          left={coordinates[0]}
          top={coordinates[1]}
        />
      </div>
    );
  }

  return null;
}

ContextMenuInteraction.propTypes = {
  getOptions: PropTypes.func.isRequired,
  /** Only show the menu when a feature is selected */
  featureOnly: PropTypes.bool,
  /** Function to test a feature and determine if interaction should run */
  featureFilter: PropTypes.func,
};

export default ContextMenuInteraction;
