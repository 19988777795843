import React, { useEffect } from "react";
import { DragDropContextProvider } from "react-dnd7";
import HTML5Backend from "react-dnd-html5-backend7";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { ApiDataStore } from "./contexts/ApiDataContext";
import { AppStore } from "./contexts/AppContext";
import { AuthenticationStore } from "./contexts/AuthenticationContext";
import { AppDivisionProvider } from "./contexts/AppDivisionContext";
import { RolesStore } from "./contexts/RolesContext";
import groupedRoutes from "./routes";
import { env } from "./config";
import { useMatomo } from "@datapunt/matomo-tracker-react";

const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  const { trackPageView } = useMatomo();

  useEffect(() => {
    // This is a reference to usersnapApi
    let api: any = null;

    // Please don't change the name otherwise disaster might fall upon you
    // @ts-ignore: Property 'onUsersnapCXLoad' does not exist on type 'Window & typeof globalThis'
    window.onUsersnapCXLoad = function (_api: any) {
      const initParams = {};

      api = _api;
      api.init(initParams);
    };

    const title = document.createElement("title");

    title.innerText = `${env.REACT_APP_DOCUMENT_TITLE}`;
    document.head.appendChild(title);

    let script: HTMLScriptElement;

    if (
      env.REACT_APP_FEATURE_USER_FEEDBACK_WIDGET === "true" &&
      env.REACT_APP_USER_FEEDBACK_WIDGET_SCRIPT_SRC
    ) {
      script = document.createElement("script");

      script.defer = true;
      script.src = env.REACT_APP_USER_FEEDBACK_WIDGET_SCRIPT_SRC;

      document.body.appendChild(script);
    }

    return () => {
      if (api) {
        api.destroy();
      }
      if (script) {
        script.remove();
      }
    };
  }, []);

  useEffect(() => {
    trackPageView({});
  }, [trackPageView]);

  return (
    <QueryClientProvider client={client}>
      <AppStore>
        <AuthenticationStore>
          {/** @todo wrap Router around _authorization_ context provider */}
          <RolesStore>
            <ApiDataStore>
              <Router>
                <AppDivisionProvider>
                  <DragDropContextProvider backend={HTML5Backend}>
                    <Switch>
                      {groupedRoutes.map(({ Layout, Route, routes }) =>
                        routes.map(({ path, Component, exact }) => (
                          <Route
                            path={path}
                            Component={Component}
                            Layout={Layout}
                            exact={exact || false}
                          />
                        )),
                      )}
                    </Switch>
                  </DragDropContextProvider>
                </AppDivisionProvider>
              </Router>
            </ApiDataStore>
          </RolesStore>
        </AuthenticationStore>
      </AppStore>
    </QueryClientProvider>
  );
}

export default App;
