import React, { useEffect, useState } from 'react';
import Toggle from '../../Shared/Toggle/toggle';
import List from '../../Shared/List/List';
import ResourceDetailUpdate from '../../Shared/ResourceDetail/ResourceDetailUpdate';
import findingTypesApi from '../../../utils/api/findingTypes';
import InspectionTemplateDetailsForm from '../Forms/InspectionTemplateDetailsForm';
import { GithubPicker } from 'react-color';
import { Modal } from '@sw-sw/lib-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

type InspectionDetails = {
  name: string;
  id: number;
  details: {
    inspection_template: {
      id: number;
      name: string;
    };
    is_enabled: boolean;
    is_required_for_cert: boolean;
  };
};

export default function InspectionTemplateFinding(props: {
  templateId?: number;
  name?: string;
}) {
  const [basicFindings, setBasicFindings] = useState<any[]>([]);
  const [errorMsg, setErrorMsg] = useState('');
  const [deleteShow, setDeleteShow] = useState<false | number>(false);
  const [show, setShow] = useState<boolean>(false);
  const [counter, setCounter] = useState(0);
  const [EditModal, setEditModal] = useState<false | number>(false);
  const [colorSetter, setColorSetter] = useState<any>({ 1: '#2FF363' });
  const [findingName, setFindingName] = useState<any[]>([]);

  const handleDelete = (id: number) => {
    setErrorMsg('');
    findingTypesApi
      .destroy(id)
      .then(() => {
        hideDeleteModal();
        setCounter(counter + 1);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.message) {
          setErrorMsg(e.response.data.message);
        } else if (e.message) {
          setErrorMsg(e.message);
        } else {
          setErrorMsg('An error has occurred');
        }
      });
  };

  const showModal = () => {
    setShow(true);
    setErrorMsg('');
  };

  const showEditModal = (id: number) => {
    setEditModal(id);
    setErrorMsg('');
  };

  const showDeleteModal = (id: number) => {
    setDeleteShow(id);
    setErrorMsg('');
  };

  const hideDeleteModal = () => {
    setDeleteShow(false);
  };

  const header = [
    {
      id: 1,
      head: 'Findings',
    },
    {
      id: 2,
      head: 'Include',
    },
    {
      id: 3,
      head: 'Required for Compliance Certification',
    },
  ];

  useEffect(() => {
    (async () => {
      if (props.templateId) {
        const data = await findingTypesApi.index(props.templateId);
        setBasicFindings(data);
        setFindingName(data);
        let newColor: any = {};
        for (let i = 0; i < data.length; i++) {
          newColor[data[i].id] = data[i].details.color;
        }
        setColorSetter(newColor);
      }
    })();
  }, [counter]);

  const getInitialEnable = basicFindings.filter((e) => e.details.is_enabled);
  // const getInitialRequired = basicFindings.filter(e => e.details.is_required_for_cert);

  const handleToggle = async (id: number, name: string) => {
    const selectedFinding: any = basicFindings.filter((e) => e.id === id)[0];
    const updateObj: any = {
      inspection_template_id: props.templateId,
    };

    if (
      selectedFinding.details.is_enabled === false &&
      name === 'is_required_for_cert'
    ) {
      setErrorMsg("Cannot enable. 'Include' must be enabled");
      selectedFinding.details.is_required_for_cert = false;
    } 
    else if (
      getInitialEnable.length > 1 ||
      selectedFinding.details['is_enabled'] !== true ||
      name === "is_required_for_cert"
    ) {
      updateObj[name] = !selectedFinding.details[name];
      try {
        await findingTypesApi.update(id, updateObj);
      } catch (x) {
        console.log(x);
      }
      setErrorMsg('');
    } 
    else {
      setErrorMsg("Atleast one 'Include' Type must be true");
    }
    setCounter(counter + 1);
  };
  const handleSubmit = async (data: { name: string }) => {
    if (props.templateId && data.name) {
      try {
        await findingTypesApi.create({
          inspection_template_id: props.templateId,
          name: data.name,
        });
        setCounter(counter + 1);
        setShow(false);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handleUpdate =
    (findingId: number) => async (data: { id: number; name: string }) => {
      if (props.templateId && data.name) {
        try {
          await findingTypesApi.update(findingId, {
            inspection_template_id: props.templateId,
            name: data.name,
          });
          setCounter(counter + 1);
          setEditModal(false);
        } catch (e) {
          console.error(e);
        }
      }
    };

  const handleColor = (findingId: number) => async (color: any) => {
    if (props.templateId && color) {
      await findingTypesApi.update(findingId, {
        inspection_template_id: props.templateId,
        color: color.hex,
      });
      setColorSetter((prevState: any) => ({
        ...prevState,
        [findingId]: color.hex,
      }));
    }
  };

  return (
    <>
      {errorMsg ? (
        <h5 className='error-messages finding-error'>
          <>
            <em>
              <FontAwesomeIcon icon={faExclamationTriangle} />
              {errorMsg}
            </em>
          </>
        </h5>
      ) : null}
      <Modal
        handleSubmit={() => handleDelete(+deleteShow)}
        show={Boolean(deleteShow)}
        handleClose={hideDeleteModal}
        errorMsg={errorMsg}
        title={`Delete Finding`}
        hideButtons={true}
      >
        <h4>Warning: This cannot be undone</h4>
        <p>
          {
            <>
              Are you sure that you want to delete the <strong></strong> Finding
              Template?
            </>
          }
        </p>
        <div className='buttons'>
          <button
            className='reversed outline'
            type='button'
            onClick={hideDeleteModal}
          >
            Cancel
          </button>
          &nbsp;
          <button
            className='warn reversed'
            onClick={() => handleDelete(+deleteShow)}
          >
            Delete
          </button>
        </div>
      </Modal>

      {EditModal && (
        <InspectionTemplateDetailsForm
          handleSubmit={handleUpdate(+EditModal)}
          handleClose={() => setEditModal(false)}
          title='Edit Finding Type'
          inspectionTemplate={findingName.find((e) => e.id === EditModal)}
        />
      )}

      <div>
        <div>
          <List
            headers={header.map((heading) => ({ label: heading.head }))}
            rows={basicFindings
              .filter(({ is_static_type }) => is_static_type === true)
              .map(({ id, name, details }: InspectionDetails) => {
                return {
                  id: id,
                  cells: [
                    name === 'A'
                      ? 'Achievement'
                      : name === 'MI'
                      ? 'Maintenance Item'
                      : 'Corrective Action',
                    <Toggle
                      isChecked={details.is_enabled}
                      handleChange={() => handleToggle(id, 'is_enabled')}
                    />,
                    <Toggle
                      isChecked={details.is_required_for_cert}
                      handleChange={() =>
                        handleToggle(id, 'is_required_for_cert')
                      }
                    />,
                  ],
                };
              })}
            pageItems=''
          />
        </div>
      </div>
      <div>
        <ResourceDetailUpdate
          editString=''
          resource='Create New Finding Type'
          onButtonClick={() => showModal()}
        >
          {show && (
            <InspectionTemplateDetailsForm
              handleSubmit={handleSubmit}
              handleClose={() => setShow(false)}
              title='Create New Finding'
            />
          )}
        </ResourceDetailUpdate>
      </div>

      <div>
        <List
          headers={header.map((heading) => ({ label: heading.head }))}
          rows={basicFindings
            .filter(({ is_static_type }) => is_static_type === false)
            .map(({ id, name, details }: InspectionDetails) => {
              return {
                id: id,
                cells: [
                  name,
                  <Toggle
                    isChecked={details.is_enabled}
                    handleChange={() => handleToggle(id, 'is_enabled')}
                  />,
                  <Toggle
                    isChecked={details.is_required_for_cert}
                    handleChange={() =>
                      handleToggle(id, 'is_required_for_cert')
                    }
                  />,

                  <div
                    style={{
                      backgroundColor: `${colorSetter[id]}`,
                      borderRadius: '20px',
                      height: '40px',
                      width: '40px',
                      border: '1px solid rgba(56, 89, 128, 0.29)',
                    }}
                  ></div>,

                  <GithubPicker width='230px' onChange={handleColor(id)} />,

                  <button
                    className='outline create-new contact.buttons'
                    onClick={() => showEditModal(id)}
                  >
                    <i className={'fa fa-pencil'} />
                  </button>,
                  <button
                    className='icon-only-button outline warn'
                    onClick={() => showDeleteModal(id)}
                  >
                    <i className='fa fa-trash' />
                  </button>,
                ],
              };
            })}
          pageItems=''
        />
      </div>
    </>
  );
}
