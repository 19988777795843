import React, { useContext } from "react";
import { NavLink, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import RolesContext from "../../contexts/RolesContext";
import AppDivisionContext from "../../contexts/AppDivisionContext";
import AppContext from "../../contexts/AppContext";
import Logo from "../../images/SW-Logo-White.svg";
import { shape, string, bool } from "prop-types";

const Navigation = props => {
  const navItems = useContext(RolesContext).getNavItems();
  const appDivisionContext = useContext(AppDivisionContext);
  const appContext = useContext(AppContext);

  const getDivisionPath = () => {
    const regex = /\/divisions\/(\d+)\/([a-z,-]*)/;

    return props.match.url.match(regex);
  };

  const isDivisionRoute = path => {
    const { nonDivisionRoutes } = appContext.get("constants");

    return nonDivisionRoutes && nonDivisionRoutes.includes(path) ? false : true;
  };

  return (
    <nav aria-label="primary side-navigation">
      {props.isHamburger ? (
        <div />
      ) : (
        <Link to="/dashboard" className="home-link">
          <img src={Logo} className="logo pure-img" alt="logo" />
        </Link>
      )}

      <ul className="side-nav">
        {navItems
          .filter(navItem => {
            if (
              !appDivisionContext.appDivisionId &&
              isDivisionRoute(navItem.route)
            ) {
              return false;
            }

            return true;
          })
          .map(navItem => (
            <li key={navItem.name}>
              <NavLink
                activeClassName="selected"
                to={
                  isDivisionRoute(navItem.route)
                    ? appDivisionContext.getPath(navItem.route)
                    : navItem.route
                }
                isActive={
                  navItem.isActivePath
                    ? () => {
                        const activePath = getDivisionPath();

                        return (
                          navItem.isActivePath.indexOf(
                            activePath
                              ? activePath[2]
                              : props.match.path.split("/")[1],
                          ) > -1
                        );
                      }
                    : null
                }
              >
                <FontAwesomeIcon icon={navItem.icon} />

                <span>{navItem.name}</span>
              </NavLink>
            </li>
          ))}
      </ul>
    </nav>
  );
};

Navigation.propTypes = {
  match: shape({
    path: string.isRequired,
  }).isRequired,
  isHamburger: bool,
};

Navigation.defaultProps = {
  isHamburger: false,
};

export default Navigation;
