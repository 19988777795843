import React, { Component } from "react";
import { oneOfType, number, string, arrayOf, shape, func } from "prop-types";

class ContextMenu extends Component {
  static propTypes = {
    left: oneOfType([number, string]).isRequired,
    top: number.isRequired,
    options: arrayOf(
      shape({
        label: string.isRequired,
        onClick: func.isRequired,
      }),
    ).isRequired,
  };

  render() {
    const { options, left, top } = this.props;

    return (
      <ul
        className="contextmenu"
        // subtract 10 for cursor height
        style={{ left: left, top: top - 10 }}
      >
        {options.map((option, $o) => (
          <li
            key={$o}
            className="contextmenu-item"
            onClick={event => option.onClick()}
          >
            {option.label}
          </li>
        ))}
      </ul>
    );
  }
}

export default ContextMenu;
