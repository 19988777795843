import React, { useState, useReducer, useContext } from "react";
import { isNumber } from "lodash";

import Draw from "./Draw/Draw";
import useMap from "../useMap";
import InteractionTrackerContext from "./TrackerContext";
import FeatureMenu from "./Pointer/ContextMenu/FeatureMenu";
import ModifySelectedFeature from "./Modify/SelectedFeature";
import StaticSelectFeature from "./Select/StaticSelectFeature";
import DeleteFeature from "./Modal/DeleteFeature";
import SourceModelForm from "./Form/SourceModelForm";
import SourceModelDetailOverlay from "./Overlay/SourceModelDetail";
import FeatureDetails from "./Pointer/FeatureDetails";
import FeatureDetailsReadOnly from "./Pointer/FeatureDetailsReadOnly";
import { Context as DataSourceContext } from "../DataSourceContext";
import { Context as PositionableInstanceDataContext } from "../Positionable/InstanceDataContext";
import RolesContext from "../../../contexts/RolesContext";
import DeleteSourceModel from "./Modal/DeleteSourceModel";

/**
 * Groups of function components.
 *
 * Share data from State_A to State_B via reducer action payload
 *
 * @type { {[ mode:string]: Array<(...props: any[]) => JSX.Element>} }
 */
const mapModes = {
  select: [FeatureDetails],
  modify: [ModifySelectedFeature, StaticSelectFeature],
  delete: [DeleteFeature],
  create: [Draw],
  createSourceModel: [SourceModelForm],
  editSourceModel: [SourceModelForm],
  deleteSourceModel: [DeleteSourceModel],
  detail: [SourceModelDetailOverlay, StaticSelectFeature, FeatureDetails],
  readOnly: [FeatureDetailsReadOnly],
};

const alwaysOnInteractions = [FeatureMenu];

const DEFAULT_MODE = "select";

const Context = React.createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case "mode":
      return {
        ...state,
        mode: action.payload.mode,
        modeData: action.payload.data || null,
      };

    default:
      throw new Error();
  }
};

function Interactions({ interactions }) {
  return (
    <React.Fragment>
      {interactions.map((Component, $c) => (
        <Component key={$c} />
      ))}
    </React.Fragment>
  );
}

/**
 * State container for map interactions
 */
function InteractionContext(props) {
  const dataSource = useContext(DataSourceContext);
  const dataContext = useContext(PositionableInstanceDataContext);
  const rolesContext = useContext(RolesContext);

  const map = useMap();
  const [positionableType, setPositionableType] = useState(null);
  const [positionableSourceModel, setPositionableSourceModel] = useState(null);

  const initialState = {
    mode: rolesContext.userHasPermission("all", "Map Drawing")
      ? DEFAULT_MODE
      : "readOnly",
    modeData: null,
  };

  /**
   * Describe the general type of interaction. Stores data for the current mode.
   *
   * modeData.nextMode
   * modeData.feature
   * modeData.sourceModelIndex
   * modeData.props
   * modeData.mapEvent
   * modeData.sourceModelId
   */
  const setMode = (mode, data = null) => {
    dispatch({
      type: "mode",
      payload: {
        mode,
        data,
      },
    });
  };

  const setDefaultMode = () => {
    setMode(
      rolesContext.userHasPermission("all", "Map Drawing")
        ? DEFAULT_MODE
        : "readOnly",
    );
    setPositionableSourceModel(null);
  };

  const setSourceModel = (sourceModelIndex, nextModeData = {}) => {
    if (sourceModelIndex === null) {
      return setDefaultMode();
    }

    /**
     * Guard from entering if the maximum number of instances has been reached
     */
    const {
      targetType,
      targetId,
      maxInstances,
    } = dataSource.getDataTypeArguments(positionableType);
    const maxInstancesReached =
      isNumber(maxInstances) &&
      dataContext.countInstances(
        positionableType,
        targetType,
        targetId,
        dataSource.get(positionableType)[sourceModelIndex],
      ) >= maxInstances;

    if (maxInstancesReached) {
      return setDefaultMode();
    }

    setPositionableSourceModel(sourceModelIndex);
    setMode("create", nextModeData);
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const contextValue = {
    get mode() {
      return state.mode;
    },
    get modeData() {
      return state.modeData;
    },

    setMode,
    setDefaultMode,

    positionableType,
    setPositionableType: pType => {
      setPositionableType(pType);
      setSourceModel(null);
    },

    positionableSourceModel,
    /** index of source model in data source container */
    setPositionableSourceModel: setSourceModel,

    /** Manage interactions in map */
    removeInteractions: filterFn =>
      map
        .getInteractions()
        .getArray()
        .filter(i => filterFn(i))
        .forEach(i => map.removeInteraction(i)),
    addInteraction: interaction => map.addInteraction(interaction),
  };

  return (
    <Context.Provider value={contextValue}>
      {props.children}
      {/** @note only render interactions when map is ready */}
      {map && (
        <InteractionTrackerContext>
          {state.mode !== "readOnly" && (
            <Interactions interactions={alwaysOnInteractions} />
          )}
          <Interactions interactions={mapModes[contextValue.mode]} />
        </InteractionTrackerContext>
      )}
    </Context.Provider>
  );
}

export default InteractionContext;
export { Context };
