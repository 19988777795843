import React, { useEffect, useState } from 'react';
import { FormModal } from '../../Shared/form';
import styles from './Permissions.module.scss';
import Toggle from '../../Shared/Toggle/toggle';
import notificationApi from '../../../utils/api/notifications';

export default function Permissions(props) {
    const [notifications, setNotifications] = useState()
    const [toggleValue, setToggleValue] = useState({})
    const [notificationTypes, setNotificationTypes] = useState([])
    const obj = {}
    useEffect(() => {
        (async () => {
            setNotifications(props.notifications)
            for (const notification of props.notifications) {
                if (notification.is_enabled.length > 0) {
                    obj[notification.id] = true
                }
                else {
                    obj[notification.id] = false
                }
            }
            setToggleValue(obj)
        })();
    }, [])


    let isEnabled;


    const handleNotification = async (state) => {
        let apiArray = []

        notificationTypes.forEach((e) => {
            if (e.toggle) {
                isEnabled = [
                    "in-app",
                    "email"
                ]
            }
            else {
                isEnabled = []
            }
            const api = notificationApi.updateTypeSettings(props.userId, e.notificationId, isEnabled)
            apiArray.push(api)
        })
        Promise.all(apiArray)
            .then(data => data)
        props.hideModal()
    }

    const handleNotificationTypes = (notificationId, Toggle) => {
        let match = false
        const elementsArray = notificationTypes.length ? notificationTypes.map((ele) => {
            if (notificationId === ele.notificationId) {
                match = true
                return null
            }
            return ele
        }) : []
        const filteredNotifications = elementsArray.filter(e => e)
        if (!match) {
            filteredNotifications.push({ notificationId: notificationId, toggle: Toggle })
        }
        return filteredNotifications
    }

    return (
        <FormModal
            modalProps={{
                title: 'Permissions'
            }}
            onSubmit={handleNotification}
            onCancel={props.hideModal}
        >
            <div>
                <h3>Notifications</h3>
                <div className={styles.rowContainer}>
                    {notifications && notifications.map((notification) => {
                        return (
                            <div className={styles.singleRow} >
                                <h4>{notification.name}</h4>
                                <Toggle isChecked={toggleValue[notification.id]} handleChange={() => setToggleValue((state) => {
                                    setNotificationTypes(handleNotificationTypes(notification.id, !toggleValue[notification.id]))

                                    return {
                                        ...state,
                                        [notification.id]: !toggleValue[notification.id]
                                    }

                                })} id={notification.id} />
                            </div>
                        )
                    })}


                </div>
            </div>
        </FormModal>
    )
}

/* 
    apiArr=[]
    apiArr.push()


*/
