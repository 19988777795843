import React, { useContext } from "react";
import PropTypes from "prop-types";
import { UIControlType } from "@sw-sw/lib-form-control-types";
import InspectionContext from "../../../contexts/InspectionContext";
import PrintModal from "../../Shared/Print/PrintModal";

export interface IInspectionPrintProps {
  onClose: () => void;
}

export const getMapOptionsSchema = (disabled: boolean = false) => ({
  map_options: {
    label: "Map Options",
    controlType: UIControlType.plainText,
    style: { marginBottom: 0 },
  },
  include_findings: {
    label: "Finding Locations",
    controlType: UIControlType.checkbox,
    disabled,
    style: { marginBottom: 0 },
  },
  include_map_drawings: {
    label: "Control Measures",
    controlType: UIControlType.checkbox,
    disabled,
    style: { marginBottom: 0 },
  },
  include_map_changelog: {
    label: "Include Changelog",
    controlType: UIControlType.checkbox,
    disabled,
    style: {},
  },
});

export const getPDFOptionsSchema = (disabled: boolean) => ({
  pdf_options: {
    label: "PDF Options",
    controlType: UIControlType.plainText,
    style: { marginBottom: 0 },
  },
  include_finding_attachments: {
    label: "Finding Images",
    controlType: UIControlType.checkbox,
    disabled,
    style: {},
  },
});

/**
 * @param {{[k:string]: any}} formData
 * @param {boolean} disabled
 */
const getPrintInspectionSchema = (formData: any, disabled: boolean) => {
  const schema: {
    [key: string]: any;
  } = {
    label_report: {
      label: "Report Type",
      controlType: UIControlType.plainText,
      style: { marginBottom: 0 },
    },
    scope: {
      controlType: UIControlType.radio,
      disabled,
      validation: {
        required: true,
      },
      options: [
        { value: "all", label: "Entire Inspection" },
        { value: "ca_only", label: "Corrective Action Log Only" },
        { value: "signature_only", label: "Signature Page Only" },
      ],
    },

    ...getMapOptionsSchema(disabled),
    ...getPDFOptionsSchema(disabled),
  };

  if (formData.scope === "signature_only") {
    // hide the map options
    [
      "map_options",
      "pdf_options",
      "include_map_drawings",
      "include_findings",
      "include_map_changelog",
      "include_finding_attachments",
    ].forEach(k => {
      Object.assign(schema[k].style, { display: "none" });
    });
  }

  return schema;
};

const InspectionPrint: React.FC<IInspectionPrintProps> = ({ onClose }) => {
  const context = useContext(InspectionContext);

  return (
    <PrintModal
      jobName="inspection"
      jobData={{ inspectionId: context.inspectionId }}
      statusUpdateKey={context.inspectionId}
      onClose={onClose}
      formSchema={getPrintInspectionSchema}
      handleChange={(key: string, _val: any) => {
        if (key === "scope") {
          return Promise.resolve({
            shouldUpdate: true,
            updateKey: "include_findings",
            updateValue: true,
          });
        }

        return Promise.resolve({ shouldUpdate: false });
      }}
      initialFormData={{
        scope: "all",
        include_findings: true,
        // include_map_drawings: true,
      }}
      modalTitle="Print Inspection"
      validateData={null}
    />
  );
};

InspectionPrint.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default InspectionPrint;
