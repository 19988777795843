import React, { useContext } from "react";
/** @todo uncomment when todo below is satisfied */
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

import AppContext from "../../contexts/AppContext";
import WeatherWidget from "./WeatherWidget";

const DashWidgets = props => {
  const appStore = useContext(AppContext);
  const username = appStore.get("user").first_name;

  return (
    <header className="dashboard-widgets">
      <section className="user">
        <h2>Welcome, {username}!</h2>
        {/** @todo show current address in a readable format */}
        {/* <p>
          <FontAwesomeIcon icon={faMapMarkerAlt} className="pad-right" />
          TODO SHOW CURRENT ADDRESS
        </p> */}
      </section>

      <WeatherWidget />
    </header>
  );
};

export default DashWidgets;
