import React, { useContext } from "react";
import NotificationControl from "./NotificationControl";
import NotificationSettingsContext from "../../contexts/NotificationsContext";

const NotificationsSettingsList = props => {
  const settingsContext = useContext(NotificationSettingsContext);
  const { notificationSettings, updateTypeSettings, loading } = settingsContext;

  return (
    <div className="notification-settings">
      <h3>Notification Settings</h3>
      <p className="sub-header">Toggle the delivery methods for each notification type</p>
      {notificationSettings.map(setting => (
        <NotificationControl
          updateSettings={updateTypeSettings}
          id={setting.id}
          name={setting.name}
          isEnabled={setting.is_enabled}
          defaultEmailMessage={setting.default_email_message}
          hasCustomMessage={setting.has_custom_message}
          key={setting.id}
          loading={loading}
          description={setting.description}
        />
      ))}
    </div>
  );
};

export default NotificationsSettingsList;
