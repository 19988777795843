import { Modal } from "@sw-sw/lib-ui";
import React, { useContext, useState } from "react";
import AppContext from "../../../contexts/AppContext";
import { InspectionContext } from "../../../contexts/InspectionContext";
import inspectionQuestionReopeningLogApi from "../../../utils/api/inspectionQuestionReopeningLog";

export interface IInspectionReopenConfirmationModalProps {
  onClose: () => void;
}

const InspectionReopenConfirmationModal: React.FC<IInspectionReopenConfirmationModalProps> =
  ({ onClose }) => {
    const appContext = useContext(AppContext);
    const inspectionContext = useContext(InspectionContext);
    const [errorMsg, setErrorMsg] = useState<string>("");

    const onSubmit = async () => {
      return inspectionQuestionReopeningLogApi
        .update(inspectionContext.inspectionId)
        .then(data => inspectionContext.updateInspection(data.inspection))
        .then(() => {
          appContext.triggerSuccessNotification();
          onClose();
        })
        .catch(error => setErrorMsg(error));
    };

    return (
      <Modal
        title="Edit Inspection Confirmation"
        subTitle="Are you sure that you want to open this inspection for editing? This will delete all compliance signatures and unlock the questions for editing. This action cannot be undone."
        show={true}
        submitBtnText="Confirm"
        handleClose={onClose}
        handleSubmit={onSubmit}
        cancelBtn={true}
        errorMsg={errorMsg}
      />
    );
  };

export default InspectionReopenConfirmationModal;
