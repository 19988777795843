import { find, findIndex, get, toNumber } from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  InspectionContext,
  isInspectionSigned,
} from "../../contexts/InspectionContext";
import AppDivisionContext from "../../contexts/AppDivisionContext";
import { getDownloadUrl } from "../../utils/api/upload";
import MapEditor from "../MapEditor";
import { MapLoadingError } from "../MapEditor/MapLoadingError";
import { MapLoadingSpinner } from "../MapEditor/MapLoadingSpinner";
import { useDocType } from "../Projects/useDocType";
import { useInspectionUploads } from "../Projects/useInspectionUploads";
import { useSiteMaps } from "../Projects/useSiteMaps";
import {
  ProjectPermissionContextProvider,
  useProjectPermission,
} from "../../contexts/ProjectPermissionContext";
import { useProject } from "../../hooks/project";
import clientApi from "../../utils/api/client";
import { useProjectInspections } from "../../hooks/inspection";

export const MapEditorRouteContext = React.createContext();

/**
 * extract data from route, for map editor
 * project, map, notes, inspection findings, control measures
 *
 * @todo inspection findings dataSource
 */
function MapEditorRoute({ history, match, location }) {
  const { projectId, mapId } = match.params;
  const inspectionContext = useContext(InspectionContext);
  const appDivisionContext = useContext(AppDivisionContext);
  const projectPermissionContext = useProjectPermission(false);

  const mapImgSrc = useRef(getDownloadUrl(mapId));

  /** @todo initial state from optional route props */
  const [selectedInspectionIndex, setSelectedInspectionIndex] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [client, setClient] = useState(null);

  const projectQuery = useProject(projectId, setError);
  const docTypeId = useDocType(projectId, "Active Site Maps", setError);
  const maps = useSiteMaps(projectId, docTypeId, setError);
  const inspectionsQuery = useProjectInspections(projectId, setError);
  const inspections = inspectionsQuery.data || [];
  const inspectionUploads = useInspectionUploads(
    projectId,
    selectedInspectionIndex !== null
      ? inspections[selectedInspectionIndex].id
      : null,
    setError,
  );

  useEffect(() => {
    if (inspectionsQuery.isFetched && inspections.length) {
      const i = location.state
        ? findIndex(inspections, {
            id: toNumber(location.state.inspectionId),
          })
        : -1;

      setSelectedInspectionIndex(i > -1 ? i : 0);
    }
  }, [inspectionsQuery.isFetched, inspections]);

  useEffect(() => {
    if (selectedInspectionIndex === null) {
      inspectionContext.setInspectionLoaded(false);
      inspectionContext.resetInspection();
    } else {
      inspectionContext.updateInspection(inspections[selectedInspectionIndex]);
    }
  }, [selectedInspectionIndex]);

  /** Required variables before loading is complete */
  useEffect(() => {
    if (maps && projectQuery.data && inspections) {
      setLoading(false);
    }
  }, [maps, projectQuery.data, inspections]);

  useEffect(() => {
    if (projectQuery.data && projectQuery.data.archivedAt) {
      projectPermissionContext.setReadOnly(true);
    }

    if (projectQuery.data && projectQuery.data.client_id) {
      clientApi.show(projectQuery.data.client_id).then(data => setClient(data));
    }
  }, [projectQuery.data]);

  //

  if (error) {
    return <MapLoadingError projectId={projectId} content={error} />;
  }

  if (loading === true || !client) {
    return <MapLoadingSpinner />;
  }

  const projectUpload = find(maps, {
    upload: {
      GUID: mapId,
    },
  });

  if (!projectUpload) {
    setError("Missing required variables: projectUpload");

    return null;
  }

  /**
   * Define data sources
   *
   * @important {positionableType} must be unique!
   *
   * @see {PositionableDataSource}
   */
  const dataSources = [
    {
      positionableType: "control_measure",
      positionableContext: "legend_items",
      targetType: "project_uploads",
      targetId: projectUpload.id,
      order: 2,
      positionableIndexArguments: [projectQuery.data.id],
      sourceModelFormModalProps: {
        isSmall: false,
      },
      sourceModelFormProps: {
        readOnly: projectPermissionContext.readOnly,
      },
      enableSearch: true,
    },
    {
      positionableType: "pollutant",
      positionableContext: "legend_items",
      targetType: "project_uploads",
      targetId: projectUpload.id,
      order: 3,
      positionableIndexArguments: [projectQuery.data.id],
      sourceModelFormModalProps: {
        isSmall: false,
      },
      sourceModelFormProps: {
        readOnly: projectPermissionContext.readOnly,
      },
      enableSearch: true,
    },
    {
      positionableType: "commentables",
      positionableTypeLabel: "Notes",
      targetType: "project_uploads",
      targetId: projectUpload.id,
      positionableIndexArguments: ["project_uploads", projectUpload.id],
      order: 4,
      maxInstances: 1,
      sourceModelFormProps: {
        readOnly: projectPermissionContext.readOnly,
      },
    },
  ];

  const inspectionUpload = find(inspectionUploads || [], {
    project_upload_id: projectUpload.id,
  });

  if (inspectionUpload) {
    dataSources.push({
      positionableType: "findings",
      targetType: "project_inspection_uploads",
      targetId: inspectionUpload.id,
      positionableIndexArguments: (formData = null) => {
        return [
          // either the finding id (for updating) or the inspection id (for creation)
          formData && formData.id
            ? formData.id
            : inspectionUpload.inspection_id,
        ];
      },
      order: 1,
      sourceModelFormProps: {
        readOnly:
          projectPermissionContext.readOnly ||
          isInspectionSigned(inspections[selectedInspectionIndex]),
        templateName: get(
          inspections[selectedInspectionIndex],
          "inspection_template.name",
        ),
        enableEditComments: true,
        defaultObservation: client.default_finding_observation,
      },
    });
  }

  return (
    <ProjectPermissionContextProvider>
      <MapEditorRouteContext.Provider
        value={{
          dataSources,
          projectName: projectQuery.data.name,
          projectId: projectQuery.data.id,
          mapName: projectUpload.upload.name,
          updatedAt: new Date(
            Date.parse(projectUpload.updatedAt),
          ).toLocaleString(),
          projectTemplate: projectQuery.data.template,

          inspections,
          selectedInspection:
            selectedInspectionIndex === null
              ? null
              : inspections[selectedInspectionIndex],

          setSelectedInspection: id => {
            const i = findIndex(inspections, {
              id: toNumber(id),
            });

            if (i > -1) {
              setSelectedInspectionIndex(i);
            }
          },
          goBack: () => {
            if (history.length > 1) {
              history.goBack();
            } else {
              history.push(
                appDivisionContext.getPath(`/projects/${projectId}`),
              );
            }
          },
        }}
      >
        <MapEditor dataSources={dataSources} mapImgSrc={mapImgSrc.current} />
      </MapEditorRouteContext.Provider>
    </ProjectPermissionContextProvider>
  );
}

export default MapEditorRoute;
