import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { func } from "prop-types";
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router";
import { AppDivisionContext } from "../../../contexts/AppDivisionContext";
import { ProjectContext } from "../../../contexts/ProjectContext";
import ProjectPermissionContext from "../../../contexts/ProjectPermissionContext";
import { RolesContext } from "../../../contexts/RolesContext";
// utils
import api from "../../../utils/api";
import divisionApi from "../../../utils/api/division";
import projectApi from "../../../utils/api/project";
import { ActionButtons } from "../../Shared/ActionButtons";
import ConfirmationModal from "../../Shared/ConfirmationModal/ConfirmationModal";
import Error from "../../Shared/ResourceIndex/Error";
import SuccessNotification from "../../Shared/SuccessNotification/SuccessNotification";
import ProjectForm from "../Forms/ProjectForm";
import clientApi from "../../../utils/api/client";
import AppContext from "../../../contexts/AppContext";
import PrintInspectionsModal from "./PrintInspectionsModal";
import { ProjectPrintProvider } from "../../../contexts/ProjectPrintContext";
import PrintSwmpModal from "./PrintSwmpModal";
import { extractInitialData } from "../Forms/projectFormData";

function ProjectHeader({ onDelete, onUpdate }) {
  const [showDeleteModal, setDeleteModalVis] = useState(false);
  const [showEditModal, setEditModalVis] = useState(false);
  const [showSuccess, setSuccessVis] = useState(false);
  const [showPrintSwmpModal, setPrintSwmpModalVis] = useState(false);
  const [showPrintInspectionsModal, setShowPrintInspectionsModal] =
    useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [showRestoreModal, setShowRestoreModal] = useState(false);
  const [error, setError] = useState("");
  const [client, setClient] = useState();

  const history = useHistory();

  const roleStore = useContext(RolesContext);
  const projectContext = useContext(ProjectContext);
  const { readOnly, setReadOnly } = useContext(ProjectPermissionContext);
  const appDivisionContext = useContext(AppDivisionContext);
  const appContext = useContext(AppContext);
  const { project, projectDocs } = projectContext;

  const userCanEdit = roleStore.userHasPermission("update", "Project");
  const userCanDelete = roleStore.userHasPermission("delete", "Project");
  const userCanArchive = roleStore.userHasPermission("update", "Project");
  const secondaryActions = useRef([]);

  /** set up secondary actions */
  useEffect(() => {
    if (userCanEdit && !readOnly) {
      secondaryActions.current.push({
        icon: "pencil",
        label: "Edit",
        onClick: async () => {
          await projectContext.loadDocs(project.id);
          setEditModalVis(true);
        },
      });
    }

    secondaryActions.current.push(
      {
        icon: "print",
        label: "Print SWMP",
        onClick: () => setPrintSwmpModalVis(true),
      },
      {
        icon: "print",
        label: "Print Inspections",
        onClick: () => setShowPrintInspectionsModal(true),
      },
    );

    if (userCanDelete) {
      secondaryActions.current.push({
        icon: "trash",
        label: "Delete",
        onClick: () => setDeleteModalVis(true),
      });
    }

    if (userCanArchive && !project.archivedAt) {
      secondaryActions.current.push({
        icon: "archive",
        label: "Archive",
        onClick: () => setShowArchiveModal(true),
      });
    }

    if (userCanArchive && project.archivedAt) {
      secondaryActions.current.push({
        icon: "undo",
        label: "Restore",
        onClick: () => setShowRestoreModal(true),
      });
    }

    return () => (secondaryActions.current.length = 0);
  }, []);

  useEffect(() => {
    appContext.loadData(
      () => clientApi.index([project.client_id]).then(c => setClient(c)),
      "project details",
    );
  }, [project]);

  if (!project) {
    return <Error error="project is undefined" />;
  }

  function handleDelete() {
    return api
      .delete(`/api/projects/${project.id}`)
      .then(res => {
        setDeleteModalVis(false);
        onDelete();
      })
      .catch(e => {
        setError(
          e.response && e.response.data && e.response.data.message
            ? e.response.data.message
            : "An error has occurred",
        );
      });
  }

  function handleArchive() {
    return divisionApi.projects.archive
      .archive(appDivisionContext.appDivisionId, project.id)
      .then(data => {
        setShowArchiveModal(false);
        history.push(appDivisionContext.getPath("/projects"));
      })
      .catch(e => {
        setError(
          e.response && e.response.data && e.response.data.message
            ? e.response.data.message
            : "An error has occurred",
        );
      });
  }

  function handleRestore() {
    return divisionApi.projects.archive
      .restore(appDivisionContext.appDivisionId, project.id)
      .then(data => {
        projectContext.setProject({
          ...projectContext.project,
          ...data,
        });
        history.push(
          `/divisions/${appDivisionContext.appDivisionId}/projects/${project.id}`,
        );
        setShowRestoreModal(false);
        setReadOnly(false);
      })
      .catch(e => {
        setError(
          e.response && e.response.data && e.response.data.message
            ? e.response.data.message
            : "An error has occurred",
        );
      });
  }

  function handleEdit(formData) {
    return projectApi.update(project.id, formData).then(data => {
      setSuccessVis(true);
      onUpdate(data);

      window.setTimeout(() => {
        setSuccessVis(false);
        setEditModalVis(false);
      }, 2000);
    });
  }

  return (
    <Fragment>
      <section className="project-header pure-g">
        <div className="project-info pure-u pure-u-1">
          <div className="project-info-row">
            <div className="project-info-column overflow-hidden">
              <div className="project-title-seprator">
                <h2>
                  <FontAwesomeIcon icon="folder" className="header-icon" />
                  {project.name}
                </h2>
                <div className="project-buttons pure-u pure-u-md-1-3 pure-u-sm-1-2 pure-u-1">
                  <ActionButtons secondary={secondaryActions.current} />
                </div>
              </div>
              <h5>
                <FontAwesomeIcon icon="user" className="header-icon" />
                {client ? client[0].name : ""}
              </h5>
            </div>
          </div>

          {/* don't bother showing address if neither are set */}
          {project.address.street_1 ||
            project.address.city ||
            project.address.state ||
            project.address.zip ? (
            <h5>
              <FontAwesomeIcon icon="map-pin" className="header-icon" />
              {project.address.street_1}{project.address.street_1 ? ", " : ""}
              {project.address.city}{project.address.city ? ", " : ""}
              {project.address.state && project.address.state.abbreviation}{" "}
              {project.address.zip}
            </h5>
          ) : null}
        </div>
      </section>

      {/* modals */}
      <ConfirmationModal
        show={showDeleteModal}
        handleConfirm={handleDelete}
        handleClose={() => setDeleteModalVis(false)}
        title="Are you sure you want to delete this project?"
        subTitle={`All documents and contacts associated with ${project.name} will be deleted.`}
        buttonText="Yes, I understand"
        errorMsg={error}
      />

      <ConfirmationModal
        show={showArchiveModal}
        handleConfirm={handleArchive}
        handleClose={() => setShowArchiveModal(false)}
        title="Are you sure you want to archive this project?"
        subTitle={`This will archive the ${project.name} project, making it unavailable in searches and lists.`}
        buttonText="Yes, I understand"
        errorMsg={error}
      />

      <ConfirmationModal
        show={showRestoreModal}
        handleConfirm={handleRestore}
        handleClose={() => setShowRestoreModal(false)}
        title="Are you sure you want to restore this project?"
        subTitle={`This will restore the ${project.name} project, making it available again in searches and lists.`}
        buttonText="Yes, I understand"
        errorMsg={error}
      />

      <SuccessNotification show={showSuccess} />
      {showEditModal && (
        <ProjectForm
          handleSubmit={handleEdit}
          handleClose={() => setEditModalVis(false)}
          submitBtnText="Save"
          initialData={extractInitialData(
            { ...project, inspections: projectContext.inspections || [] },
            projectDocs.documentTypes,
          )}
          hideStartDate={projectContext.inspections.length > 0}
        />
      )}

      {showPrintSwmpModal && (
        <ProjectPrintProvider>
          <PrintSwmpModal onClose={() => setPrintSwmpModalVis(false)} />
        </ProjectPrintProvider>
      )}
      {showPrintInspectionsModal && (
        <ProjectPrintProvider>
          <PrintInspectionsModal
            onClose={() => setShowPrintInspectionsModal(false)}
          />
        </ProjectPrintProvider>
      )}
    </Fragment>
  );
}

ProjectHeader.propTypes = {
  onUpdate: func.isRequired,
  onDelete: func.isRequired,
};

export default ProjectHeader;
