import { mapUtil } from "@sw-sw/common";
import PropTypes from "prop-types";
import React, { Component, Fragment, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import AppDivisionContext from "../../../contexts/AppDivisionContext";
import { ProjectContext } from "../../../contexts/ProjectContext";
import ProjectPermissionContext from "../../../contexts/ProjectPermissionContext";
import uploadApi from "../../../utils/api/upload";
import SiteMapCard from "../../Card/SiteMapCard";
import ConfirmationModal from "../../Shared/ConfirmationModal/ConfirmationModal";
import SuccessModal from "../../Shared/SuccessModal/SuccessModal";

const { isSupportedMimeType } = mapUtil;

/**
 * Render cards for each site map in a project
 *
 * @todo optional canEdit
 * @todo generic 'map editor' route, at root of app, instead of route-per-map
 */
class SiteMapsUI extends Component {
  state = {
    selected: null,
    showConfirmation: false,
    targetMap: null,
    error: "",
    targetDoc: {},
  };

  static contextType = AppDivisionContext;

  get editorProps() {
    const { selected } = this.state;

    const editorProps = {
      onClose: () => this.deselect(),
    };

    if (selected !== null) {
      Object.assign(editorProps, {
        map: selected,
        title: this.props.projectName,
        subtitle: selected.name,
      });
    }

    return editorProps;
  }

  handleCardClick = selected => {
    this.setState({ selected }, () => {
      const { history, projectId, inspectionId } = this.props;

      history.push(
        this.context.getPath(
          `/projects/${projectId}/map/${selected.GUID}/edit`,
        ),
        {
          inspectionId: inspectionId,
        },
      );
    });
  };

  deselect = () => {
    this.setState({ selected: null }, () => {
      const { history, match } = this.props;

      history.push(this.context.getPath(match.url));
    });
  };

  handleDelete = () => {
    uploadApi.project
      .destroy(this.props.projectId, this.state.targetDoc.id)
      .then(() => {
        this.props.onDocumentDelete(this.state.targetDoc.GUID);
        this.setState({ showConfirmation: false });
      })
      .catch(err =>
        this.setState({
          error:
            err.response && err.response.data && err.response.data.message
              ? err.response.data.message
              : "An error has occurred",
        }),
      );
  };

  render() {
    return (
      <Fragment>
        {this.props.siteMaps && this.props.siteMaps.length ? (
          <div className="project-site-maps pure-g pure-g-with-gutters">
            {this.props.siteMaps.map(doc => (
              <div className="pure-u-1 pure-u-md-1-3" key={doc.GUID}>
                <SiteMapCard
                  imgSrc={uploadApi.getDownloadUrl(doc.GUID, 450)}
                  title={doc.name}
                  label="Project"
                  handleImgClick={() => this.handleCardClick(doc)}
                  handleDelete={() =>
                    this.setState({ showConfirmation: true, targetDoc: doc })
                  }
                  showActions={
                    this.props.projectPermissionContext.readOnly
                      ? false
                      : this.props.showActions
                  }
                />
              </div>
            ))}
          </div>
        ) : (
          <p>
            No site maps have been uploaded. Upload a site map{" "}
            <Link to={`/projects/${this.props.projectId}/project-docs`}>
              here
            </Link>
          </p>
        )}

        <ConfirmationModal
          show={this.state.showConfirmation}
          handleClose={() => this.setState({ showConfirmation: false })}
          handleConfirm={this.handleDelete}
          title="Are you sure you want to delete this site map?"
          subTitle="This can not be undone."
          buttonText="Delete Site Map"
        />

        <SuccessModal
          show={this.state.error.length > 0}
          handleClose={() => this.setState({ error: "" })}
          handleSubmit={() => this.setState({ error: "" })}
          submitBtnText="OK"
          message={this.state.error}
          title="Error replacing document"
          isAlert
        />
      </Fragment>
    );
  }
}

SiteMapsUI.propTypes = {
  /**
   * Uploads
   */
  siteMaps: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      GUID: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    }).isRequired,
  ).isRequired,
  projectId: PropTypes.number.isRequired,
  projectName: PropTypes.string.isRequired,
  onDocumentDelete: PropTypes.func,
  onDocumentUpload: PropTypes.func,
  showActions: PropTypes.bool,
  inspectionId: PropTypes.number,
};

SiteMapsUI.defaultProps = {
  inspectionId: 0,
};

export const SiteMaps = ({ ...props }) => {
  const history = useHistory();
  const projectContext = useContext(ProjectContext);
  const projectPermissionContext = useContext(ProjectPermissionContext);
  const docTypes = projectContext.projectDocs.documentTypes.filter(
    dType => dType.name === "Active Site Maps",
  );
  const docs = docTypes.length ? docTypes[0].documents : [];
  const siteMaps = docs.filter(doc => isSupportedMimeType(doc.mime_type));

  return (
    <SiteMapsUI
      {...props}
      history={history}
      siteMaps={siteMaps}
      projectId={projectContext.project.id}
      projectName={projectContext.project.name}
      projectPermissionContext={projectPermissionContext}
    />
  );
};

export default SiteMaps;
