import React, { useContext } from "react";
import { bool, func, number, shape, arrayOf, string } from "prop-types";
import { FormContext, FormContextProvider } from "@sw-sw/lib-form";
import ReassignForm, { getInitialData } from "./ReassignForm";
import FormModal from "../Shared/form/modal/FormModal";
import inspectionApi from "../../utils/api/inspection";

function ReassignFormModalUI({
  activeView,
  hideModal,
  inspectionId,
  projectId,
  users,
  handleReassign,
}) {
  const formContext = useContext(FormContext);

  function handleSubmit() {
    return inspectionApi
      .reassign(inspectionId, formContext.value.inspector_user_id, projectId)
      .then(resp => {
        handleReassign(resp,activeView);
        hideModal();
      });
  }

  return (
    <FormModal
      modalProps={{
        title: activeView ? `Change ${activeView.label}` : "Change Inspector",
        submitBtnText: "Save",
      }}
      onCancel={hideModal}
      onSubmit={handleSubmit}
    >
      <ReassignForm
        onChange={formContext.set}
        value={formContext.value}
        users={users}
      />
    </FormModal>
  );
}

function ReassignModal(props) {
  if (!props.show) {
    return null;
  }

  return (
    <FormContextProvider initialValue={getInitialData(props.initialId)}>
      <ReassignFormModalUI
        activeView = {props.activeView ? props.activeView : null}
        hideModal={props.hideModal}
        inspectionId={props.inspectionId}
        projectId={props.projectId}
        users={props.users}
        handleReassign={props.handleReassign}
      />
    </FormContextProvider>
  );
}

ReassignModal.propTypes = {
  initialId: number.isRequired,
  show: bool.isRequired,
  hideModal: func.isRequired,
  inspectionId: number.isRequired,
  projectId: number,
  handleReassign: func.isRequired,
  users: arrayOf(
    shape({
      value: number,
      label: string,
    }),
  ).isRequired,
};

export default ReassignModal;
