import React from "react";

function NoteIcon({ title, ...props }) {
  return (
    <svg
      width="18px"
      height="22px"
      viewBox="0 0 18 22"
      version="1.1"
      {...props}
    >
      <title>{title}</title>
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          transform="translate(-740.000000, -53.000000)"
          stroke="#385980"
          strokeWidth="2"
        >
          <g transform="translate(0.000000, 39.000000)">
            <g transform="translate(741.000000, 15.000000)">
              <path
                d="M10,0 L2,0 C0.8954305,0 0,0.8954305 0,2 L0,18 C0,19.1045695 0.8954305,20 2,20 L14,20 C15.1045695,20 16,19.1045695 16,18 L16,6 L10,0 Z"
                fill="#D8D8D8"
              />
              <polyline points="10 0 10 6 16 6" />
              <path d="M12,11 L4,11" />
              <path d="M12,15 L4,15" />
              <polyline points="6 7 5 7 4 7" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

NoteIcon.defaultProps = {
  title: "",
};

export default NoteIcon;
