import React, { Fragment } from "react";
import moment from "moment";

import DashTable from "../utils/DashTable";
import DashCalendar from "../utils/DashCalendar";
import DashHeader from "../utils/DashHeader";
import DashboardViews from "../utils/DashboardViews";
import { shape, bool, string, number, arrayOf } from "prop-types";

function getCtaText({ inspectionStatus, dueDate }) {
  switch (inspectionStatus) {
    case "started":
      return "Complete Inspection";
    case "complete":
      return "Sign Inspection";
    case "new":
      return "Start Inspection";
    default:
      if (moment().format("MM-DD-YYYY") === dueDate) {
        return "Start Inspection";
      }

      return null;
  }
}

function getCtaRoute({ inspectionId, inspectionStatus, dueDate, projectId }) {
  // due today and not yet started
  if (
    inspectionStatus === "scheduled" &&
    moment().format("MM-DD-YYYY") === dueDate
  ) {
    return `/projects/${projectId}/start-inspection`;
  }

  return `/inspection/${inspectionId}/${
    inspectionStatus === "complete" ? "certification" : "questionnaire"
  }`;
}

function getMenuOptions() {
  return [<li key="dir">Get Directions</li>];
}

const InspectorView = props => {
  return (
    <DashboardViews>
      {({ isCalendar, date, ...headerProps }) => {
        return (
        <Fragment>
          <DashHeader isCalendar={isCalendar} date={date} {...headerProps}>
            <DashHeader.Title>Inspections Due</DashHeader.Title>
            <DashHeader.DateSelect />
            <DashHeader.Buttons />
          </DashHeader>

          {isCalendar ? (
            <DashCalendar
              data={props.data}
              activeDate={date}
              getCtaRoute={getCtaRoute}
              getCtaText={getCtaText}
            />
          ) : (
            <DashTable
              tableKeys={["siteName", "clientName", "overdueItems", "dueDate"]}
              tableData={props.data}
              getCtaRoute={getCtaRoute}
              getCtaText={getCtaText}
              getMenuOptions={getMenuOptions}
              dynamicCta
            />
          )}
        </Fragment>
      )}}
    </DashboardViews>
  );
};

InspectorView.propTypes = {
  data: arrayOf(
    shape({
      projectId: number.isRequired,
      inspectionId: number.isRequired,
      inspectionStatus: string.isRequired,
      dueDate: string.isRequired,
      inspectionOverdue: bool.isRequired,
      overdueItems: number.isRequired,
      siteName: string.isRequired,
      clientName: string.isRequired,
    }).isRequired,
  ),
};

export default InspectorView;
