import React from "react";

function LineIcon({ title, ...props }) {
  return (
    <svg
      width="18px"
      height="18px"
      viewBox="0 0 18 18"
      version="1.1"
      {...props}
    >
      <title>{title}</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-677.000000, -55.000000)" fill="#FFFFFF">
          <g transform="translate(522.000000, 48.000000)">
            <g transform="translate(163.899495, 16.399495) rotate(-315.000000) translate(-163.899495, -16.399495) translate(158.899495, 3.899495)">
              <path d="M4.44036503,0.251469652 L4.44036503,24.3925562" />
              <polygon points="3.45361724 4.27498408 5.9204867 4.27498408 5.9204867 20.3690418 3.45361724 20.3690418" />
              <polygon points="9.07339256 4.77792339 0.137651316 4.77792339 4.60490522 0.225065339" />
              <polygon points="9.07339256 19.8661025 0.137651316 19.8661025 4.60490522 24.4189606" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

LineIcon.defaultProps = {
  title: "",
};

export default LineIcon;
