import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";

import { Context } from "../DataSourceContext";
import positionableApi from "../../../utils/api/positionable";
import { getApi } from "../../../utils/positionable";

const onApiError = response => {
  console.error("api error", response);
};

export const getTargetKey = (positionableType, targetType, targetId) =>
  [positionableType, targetType, targetId].join("--");

/**
 * Childless component used to fetch
 * positionable source and instance models
 */
function PositionableDataSource({
  positionableType,
  positionableContext,
  targetType,
  targetId,
  positionableIndexArguments,
  positionableTypeLabel,
  maxInstances,
  sourceModelFormModalProps,
  sourceModelFormProps,
}) {
  const dataSource = useContext(Context);
  const targetKey = getTargetKey(positionableType, targetType, targetId);
  const args =
    typeof positionableIndexArguments === "function"
      ? positionableIndexArguments()
      : positionableIndexArguments;
  const posArgs = args.join("_");

  /**
   * Get positionable source models
   */
  useEffect(() => {
    if (positionableTypeLabel) {
      dataSource.setDataTypeLabel(positionableType, positionableTypeLabel);
    }

    dataSource.setDataTypeArguments(positionableType, {
      targetType,
      targetId,
      maxInstances,
      // save the original index arguments because
      // it could be a function that depends on a sourceModel
      positionableIndexArguments,
      positionableType,
      positionableContext,
      sourceModelFormModalProps,
      sourceModelFormProps,
    });

    /** @todo cache calls to the same positionable type */
    getApi(positionableType)
      .index(...args)
      .then(data => {
        dataSource.addDataType(
          positionableType,
          data.map(model => {
            /**
             * @important prepend "data source" info to each positionable source model
             *
             * Used to save instance to the appropriate target
             */
            return {
              ...model,
              positionableType,
            };
          }),
        );
      })
      .catch(error => {
        /** Add an empty data type, for display */
        dataSource.addDataType(positionableType, []);

        return onApiError(error);
      });
  }, [posArgs, positionableType, sourceModelFormProps]);

  /**
   * Get positionable instances
   */
  useEffect(() => {
    /** @todo cache calls to the same (tType, tId, pType) */
    positionableApi
      .index(
        targetType,
        targetId,
        /** the presence of positionableContext switches these arguments */
        positionableContext ? positionableContext : positionableType,
        positionableContext ? positionableType : null,
      )
      .then(data => {
        dataSource.set(
          targetKey,
          data.map(instanceModel => {
            if (positionableContext) {
              instanceModel.positionable_type = positionableType;
            }

            return instanceModel;
          }),
        );
      })
      .catch(onApiError);

    return () => {
      // console.log(1);
      dataSource.unset(targetKey);
    };
  }, [targetKey]);

  return <div />;
}

/**
 * Specify the type of positionable source model
 * specify the target model for positionable instances
 */
PositionableDataSource.propTypes = {
  positionableType: PropTypes.string.isRequired,
  targetType: PropTypes.string.isRequired,
  targetId: PropTypes.number.isRequired,

  /**
   * Sometimes, the pType of {instanceModels}s is different in the backend vs frontend.
   *
   * For example) "legend_item" positionables have different sub types: "control_measure" & "pollutant".
   * The frontend considers each subtype as a seperate {positionableType},
   * but the backend considers the subtypes as part of the "legend_item" {positionableType}.
   *
   * Use this prop to instruct the backend to gather instance models from the correct table, with the correct filter.
   * When this prop is present, the {positionableType} value becomes a _filter_ on the {positionableApi.index} method.
   * Further, this prop instructs the frontend to override the backend's value for {instanceModel.positionableType}.
   */
  positionableContext: PropTypes.string,
  /**
   * @type { any[]|(sourceModel?: any) => any[] }
   *
   * Arguments applied to the `${positionableType}Api` "index" function
   */
  positionableIndexArguments: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.func,
  ]),
  /**
   * Label for map editor nav. Optional.
   * When missing, the label is generated using the {positionableType}
   */
  positionableTypeLabel: PropTypes.string,
  /**
   * Define the sort order of the data source, in the map editor nav
   */
  order: PropTypes.number.isRequired,
  /**
   * The maximum number of instances, of this type, allowed to be drawn
   */
  maxInstances: PropTypes.number,
  /**
   * Properties for the modal to edit source model
   */
  sourceModelFormModalProps: PropTypes.object,
  /**
   * Properties on the form to edit source model
   */
  sourceModelFormProps: PropTypes.object,
  /**
   * Whether to enable the search function in positionable lists
   */
  enableSearch: PropTypes.bool,
};

PositionableDataSource.defaultProps = {
  positionableIndexArguments: [],
  positionableContext: null,
  sourceModelFormModalProps: {},
  sourceModelFormProps: {},
  enableSearch: false,
};

export default PositionableDataSource;
