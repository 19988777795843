import React, { useContext, useCallback } from "react";
import ContextMenuInteraction from "./MapContextMenu";
import { Context as InteractionContext } from "../../InteractionContext";
import SelectFeatureOnHover from "../../Select/SelectFeatureOnHover";
import { Context as SelectionContext } from "../../Select/SelectFeature";
import { Context as DataSourceContext } from "../../../DataSourceContext";

/**
 * Switches interaction mode
 */
function FeatureMenuUI({ ...props }) {
  const dataSourceContext = useContext(DataSourceContext);
  const { getDataTypeLabel } = dataSourceContext;
  const {
    setMode,
    setPositionableType,
    setPositionableSourceModel,
  } = useContext(InteractionContext);

  const selectionContext = useContext(SelectionContext);

  const isFeatureReadOnly = useCallback(
    selectedFeature =>
      dataSourceContext.isReadOnlyDataType(
        selectedFeature.get("positionable_type"),
      ),
    [dataSourceContext],
  );

  const getOptions = useCallback(
    (event, contextmenuFeature) => {
      const geometryType =
        contextmenuFeature && contextmenuFeature.get("geometry").getType();
      const selectedFeature = selectionContext.getSelectedFeature();

      // empty menu when the positionable type is configured to be read only
      if (selectedFeature && isFeatureReadOnly(selectedFeature)) {
        return [];
      }

      return contextmenuFeature
        ? [
            {
              label: geometryType === "Point" ? "Move" : "Edit",
              onClick: () => {
                const feature = selectionContext.getSelectedFeature();

                if (feature) {
                  /**
                   * @note First, unset the source model because we
                   * may be in drawing mode and
                   * don't want the item to remain selected
                   * when entering modification mode
                   */
                  setPositionableSourceModel(null);

                  setMode("modify", { feature });
                }
              },
            },
            {
              label: "Remove from Map",
              onClick: event => {
                let feature = selectionContext.getSelectedFeature();

                /**
                 * @note edge case
                 * when {filterModeDataFeature} is set,
                 * source the feature from the event
                 */
                if (!feature && event.feature) {
                  feature = event.feature;
                }

                if (feature) {
                  setMode("delete", {
                    feature,
                  });
                }
              },
            },
          ]
        : [
            // default context menu
            // most click handlers set layer, then display modal
            {
              positionableType: "findings",
              label: "Add Finding",
              onClick: () => {
                setPositionableType("findings");
                setMode("createSourceModel", {
                  nextMode: "create",
                  mapEvent: event,
                });
              },
            },
            {
              positionableType: "control_measure",
              label: "Add Control Measure",
              onClick: () => setPositionableType("control_measure"),
            },
            {
              positionableType: "pollutant",
              label: "Add Pollutant",
              onClick: () => setPositionableType("pollutant"),
            },
            {
              label: `Add ${getDataTypeLabel("commentables", false)}`,
              positionableType: "commentables",
              onClick: () => {
                setPositionableType("commentables");
                setMode("createSourceModel", {
                  nextMode: "create",
                  mapEvent: event,
                });
              },
            },
          ].filter(
            ({ positionableType }) =>
              !dataSourceContext.isReadOnlyDataType(positionableType),
          );
    },
    [dataSourceContext, isFeatureReadOnly, selectionContext],
  );

  return <ContextMenuInteraction {...props} getOptions={getOptions} />;
}

export function FeatureMenu() {
  return (
    <SelectFeatureOnHover filterModeDataFeature>
      <FeatureMenuUI />
    </SelectFeatureOnHover>
  );
}

export default FeatureMenu;
