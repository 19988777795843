import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { positionableUtil } from "@sw-sw/common";
import classNames from "classnames";
import moment from "moment";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { RolesContext } from "../../../contexts/RolesContext";
import { dateFormat } from "../../../utils";
import findingUtil from "../../../utils/findings";
import InspectionContext from "./../../../contexts/InspectionContext";
import FindingCheckbox from "./FindingCheckbox";
import FindingImg from "./FindingImg";
export const makeGoToDetailsAction =
  ({ history }) =>
    (inspectionId, findingId) => {
      history.push(`/inspection/${inspectionId}/findings/${findingId}/details`);
    };

export default function FindingCard({ finding }) {
  const {
    selectedFindings,
    readOnlyFindings,
    updateSelectedFindings,
    inspection,
  } = useContext(InspectionContext);
  const findingUploads = finding.finding_uploads;
  const history = useHistory();
  const { userHasPermission } = useContext(RolesContext);
  const goToDetails = makeGoToDetailsAction({ history });

  const canReadImages = userHasPermission("read", "Finding Images");
  const dateCompleted = finding.date_completed
    ? moment.utc(finding.date_completed).format(dateFormat)
    : null;
  return (
    <div
      className={classNames("finding-wrapper pure-u pure-u-1 pure-u-md-1-2", {
        "finding-complete": finding.date_completed !== null,
      })}
      key={finding.id}
    >
      <div className="finding">
        <div
          className="finding-content"
          onClick={() => {
            goToDetails(
              inspection.id ? inspection.id : finding.initial_inspection_id,
              finding.id,
            );
          }}
        >
          <div className="finding-text-info">
            <h4>
              {!readOnlyFindings && !finding.date_completed && (
                <FindingCheckbox
                  checked={selectedFindings.includes(finding.id)}
                  handleClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    updateSelectedFindings(finding.id);
                  }}
                />
              )}
              <span
                className="tag pad-right"
                style={{
                  backgroundColor: (finding.type === ("CA" || "A" || "MI") || finding.color === null) ? findingUtil.getBGColor(finding.type, null) : finding.color
                }}
              >
                #{ finding.number }
              </span>
              <span>{finding.type === "CA" ? "Corrective Action" :
              finding.type === "A" ? "Achievement" :
              finding.type === "MI" ? "Maintainence Item" :
              finding.type}</span>
              {finding.date_completed ? (
                <FontAwesomeIcon
                  icon="check"
                  className="finding-card-checkmark"
                />
              ) : null}
            </h4>

            <div className="finding-content-comments">
              {positionableUtil.findings.getCardLabel(finding).map(item => (
                <p key={item}>{item}</p>
              ))}
            </div>

            {finding.InspectionFindingModel &&
              finding.InspectionFindingModel.carried_over ? (
              <span className="carry-over">
                Carry Over <i className="fa fa-exclamation-circle" />
              </span>
            ) : null}

            <div className="start-end-dates">
              <div>
                Initiated:
                <strong>
                  {moment.utc(finding.date_initiated).format(dateFormat)}
                </strong>
              </div>

              {finding.date_completed ? (
                <div>
                  Completed:
                  <strong>{dateCompleted}</strong>
                </div>
              ) : null}
            </div>
          </div>

          {canReadImages && findingUploads && findingUploads.length ? (
            <FindingImg
              sources={findingUploads.map(fndUpl => fndUpl.upload.GUID)}
              lightbox
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}
