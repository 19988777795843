import React, { useContext } from "react";
import { isEqual, findIndex } from "lodash";
import AppContext from "./AppContext";
import rolesApi from "../utils/api/roles";

const activePathMap = {
  Dashboard: ["dashboard"],
  Project: ["projects", "project", "inspection", "finding"],
  Client: ["clients"],
  Regulations: ["groups"],
  "Legend Item": ["map-legend"],
  Admin: ["admin"],
  Divisions: ["divisions"],
  "Inspection Templates": ["inspection-templates"],
};

const Context = React.createContext({
  /** @returns {Promise<any>} */
  fetchRoles: () => Promise.reject(),
  userHasPermission: (action, location) => false,
});

/**
 * controller for authorization and global navigation
 */
export const RolesStore = props => {
  const appStore = useContext(AppContext);
  const user = appStore.get("user") || {};
  const { permissions, pages } = user;

  let {navigation} = user;

  if (user.roleName === "Client Manager") {
    navigation = navigation.filter(function (obj) {
      return obj.label !== 'Inspection Templates'
    })
  }

  const userHasPermission = (action, location) => {
    if (user.roleName === "Admin") {
      return true;
    }

    const page = pages.find(p => p.label === location);

    if (!page) {
      console.error("Cannot authorize page/permission/role: ", {
        action,
        location,
      });

      return false;
    }

    const locationId = page.id;

    return permissions.some(permission =>
      isEqual(permission, {
        perm: action,
        pageId: locationId,
      }),
    );
  };

  const navigationIsAllowed = navRoute => {
    return (
      findIndex(pages, ({ route }) => route === `/${navRoute.split("/")[1]}`) >
      -1
    );
  };

  const getNavItems = () =>
    navigation.map(navItem => ({
      name: navItem.label,
      icon: navItem.icon,
      route: navItem.route,
      isActivePath: activePathMap[navItem.label],
    }));

  return (
    <Context.Provider
      value={{
        userHasPermission,
        navigationIsAllowed,
        getNavItems,
        fetchRoles: () => {
          if (!appStore.has("roles")) {
            return rolesApi.index().then(roles => {
              appStore.set("roles", roles);

              return roles;
            });
          }

          return Promise.resolve(appStore.get("roles"));
        },
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export default Context;
export { Context, Context as RolesContext };
