import React, { Component, useContext, useState } from "react";
import PropTypes from "prop-types";
import { MapContext as MapContextProvider, MapCanvas } from "@sw-sw/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "react-responsive"

import { Header } from "./Header";
import Toolbar from "./Toolbar";
import PositionableDataSource from "./Positionable/DataSource";
import DataSourceContext from "./DataSourceContext";
import PositionableNavigation from "./Positionable/Navigation";
import InteractionContext from "./Interaction/InteractionContext";
import PositionableInstanceDataContext from "./Positionable/InstanceDataContext";
import DataSourceFeatures from "./DataSourceFeatures";
import RolesContext from "../../contexts/RolesContext";

const MapEditorContext = React.createContext({});

/** prop-less ui for MapEditor */
const MapEditorUI = () => {
  const permCheck = useContext(RolesContext).userHasPermission;
  const [ sidebarState, setSidebarState ] = useState(false)
  const isTabletOrLess = useMediaQuery({ maxWidth: 768 })

  return (
    <div className="map-editor">
      <div className="map-editor-top">
        <Header />
        <Toolbar permCheck={permCheck} />
      </div>

      <div className="pure-g map-editor-main">
        {
          sidebarState && isTabletOrLess
          ? <></>
          : <div className="pure-u pure-u-1-3 pure-u-md-1-4 pure-u-lg-1-6 map-editor-sidebar">
              <PositionableNavigation permCheck={permCheck} />
            </div>
        }

        <div className="pure-u pure-u-2-3 pure-u-md-3-4 pure-u-lg-5-6 map-editor-map">
          <div className="map-editor-hamburger-icon" onClick={() => setSidebarState(!sidebarState)}>
            <FontAwesomeIcon
              className="map-editor-hamburger-svg"
              icon={sidebarState ? faBars : faTimes}
              size="xs"
            />
          </div>

          <MapEditorContext.Consumer>
            {({ mapImgSrc, error }) =>
              error ? (
                <p className="error">
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    className="alert"
                  />{" "}
                  {error}
                </p>
              ) : (
                <MapCanvas mapImgSrc={mapImgSrc} />
              )
            }
          </MapEditorContext.Consumer>
        </div>
      </div>
    </div>
  );
};

/**
 * one map editor to rule them all
 *
 * @todo stateless function component
 *
 * Composes necessary contexts and components for site map editing
 */
class MapEditor extends Component {
  static propTypes = {
    mapImgSrc: PropTypes.string,

    dataSources: PropTypes.arrayOf(
      PropTypes.shape({
        ...PositionableDataSource.propTypes,
      }).isRequired,
    ).isRequired,
  };

  state = {
    error: null,
  };

  onInit = x => {
    console.log("map initialized!", x);
  };

  onInitError = err => {
    let message = "Unknown error";

    if (typeof err === "string") message = err;
    else if (err && err.message) message = err.message;

    console.error("map init error:", err);

    this.setState({
      error: `There was an error loading the map (${message})`,
    });
  };




  render() {
    return (
      <MapEditorContext.Provider
        value={{ mapImgSrc: this.props.mapImgSrc, error: this.state.error }}
      >
        <DataSourceContext>
          <MapContextProvider
            mapImgSrc={this.props.mapImgSrc}
            dataSources={[]}
            onInit={this.onInit}
            onInitError={this.onInitError}
          >
            <PositionableInstanceDataContext>
              <InteractionContext>
                <MapEditorUI />
              </InteractionContext>
              <DataSourceFeatures />
            </PositionableInstanceDataContext>
          </MapContextProvider>

          {this.props.dataSources.map((source, i) => (
            <PositionableDataSource {...source} key={i} />
          ))}
        </DataSourceContext>
      </MapEditorContext.Provider>
    );
  }
}

export default MapEditor;
