import React, { useCallback, useState } from "react";
import { getProjectProps, projectsPropType } from "./ClientUserView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import DashTable from "../utils/DashTable";
import DashCalendar from "../utils/DashCalendar";
import DashHeader from "../utils/DashHeader";
import DashboardViews from "../utils/DashboardViews";
import { flatten } from "lodash";
import inspectionApi from "../../../utils/api/inspection";
import SuccessModal from "../../Shared/SuccessModal/SuccessModal";
import { useHistory } from "react-router-dom";
import ReassignModal from "../ReassignModal";


const views = [
  { key: "managers", label: "Managers" },
  { key: "inspectors", label: "Inspectors" },
  { key: "inspectionsDue", label: "Inspections Due" },
  { key: "statistics", label: "Statistics" },
];

const tableKeys = ["siteName", "clientName", "overdueItems", "dueDate"];

function getMenuOptions({ projectId, getLink }) {
  return (
    <div onClick={() => getLink(projectId)}>
      <li>Start Inspection</li>
    </div>
  );
}
function extractInspectorData(inspector) {
  return inspector.inspections.map(inspection => ({
    inspectorId: inspector.id,
    ...inspection,
  }));
}

function InspectorTable({
  inspector,
  index,
  handleRowClick,
  expanded,
  setErrorMessage,
  setShowErrorModal,
  ...dashTableProps
}) {
  const inspections = extractInspectorData(inspector);

  return (
    <>
      <tr onClick={() => handleRowClick(index)} className="pointer">
        <td>{inspector.name}</td>
        <td className="caret-cell">
          <FontAwesomeIcon
            icon={expanded.includes(index) ? faAngleUp : faAngleDown}
            className="caret"
          />
        </td>
      </tr>

      {expanded.includes(index) && (
        <tr key={`expansion-${index}`}>
          <td className="expansion-cell" colSpan={3}>
            <DashTable
              {...dashTableProps}
              tableData={inspections}
              emptyMessage="No Inspections Scheduled"
              hideHeaderOnEmpty
            />
          </td>
        </tr>
      )}
    </>
  );
}

function TableView({ data, activeView, getLink, ...props }) {
  const [expanded, setExpanded] = useState([]);

  const handleRowClick = useCallback(
    i => {
      if (expanded.includes(i)) {
        const filterState = expanded.filter(item => item !== i);

        setExpanded(filterState);
      } else {
        setExpanded([...expanded, i]);
      }
    },
    [expanded],
  );

  switch (activeView) {
    case "inspectionsDue":
      return (
        <DashTable
          {...props}
          tableData={data}
          tableKeys={tableKeys}
          emptyMessage="No Inspections Assigned"
        />
      );

    case "inspectors":
      return (
        <section className="table-holder">
          <table className="list-view pure-table pure-table-horizontal area-manager-table">
            <thead className="list-header">
              <tr>
                <th>Name</th>
                <th className="caret-cell" />
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((inspector, i) => (
                  <InspectorTable
                    tableKeys={tableKeys}
                    expanded={expanded}
                    handleRowClick={handleRowClick}
                    getMenuOptions={args =>
                      getMenuOptions({ ...args, getLink })
                    }
                    inspector={inspector}
                    index={i}
                    key={i}
                    {...props}
                  />
                ))}
            </tbody>
          </table>
        </section>
      );

    case "managers":
      return (
        <section className="table-holder">
          <table className="list-view pure-table pure-table-horizontal area-manager-table">
            <thead className="list-header">
              <tr>
                <th>Name</th>
                <th className="caret-cell" />
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((inspector, i) => {
                  return (
                    <InspectorTable
                      tableKeys={tableKeys}
                      expanded={expanded}
                      handleRowClick={handleRowClick}
                      getMenuOptions={getMenuOptions}
                      inspector={inspector}
                      index={i}
                      key={i}
                      {...props}
                    />
                  );
                })}
            </tbody>
          </table>
        </section>
      );
    case "statistics": {
      const dashTableProps = getProjectProps(data);

      return <DashTable {...dashTableProps} />;
    }

    default:
      return null;
  }
}

const RegionalManagerView = props => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorModal, setErrorModal] = useState(false);
  const [formInitialData, setFormInitialData] = useState({
    inspectorId: 0,
    inspectionId: 0,
    projectId: 0,
  });

  async function getLink(projectId) {
    inspectionApi
      .create(projectId, "Routine")
      .then(i => history.push(`/inspection/${i.id}/questionnaire`))
      .catch(err => {
        setErrorMessage(err.response.data.message);
        setErrorModal(true);
      });
  }

  const onCtaClick = useCallback(
    ({ inspectorId, inspectionId, projectId }) => {
      setFormInitialData({
        inspectionId,
        inspectorId,
        projectId,
      });
      setShowModal(true);
    },
    [showModal, formInitialData],
  );

  return (
    <section className="inspections-paper">
      <DashboardViews views={views}>
        {({ isCalendar, date, activeView, ...headerProps }) => (
          <>
            <DashHeader
              isCalendar={activeView === "inspectionsDue" && isCalendar}
              date={date}
              activeView={activeView}
              {...headerProps}
            >
              <DashHeader.Tabs />
              <DashHeader.DateSelect />
              <DashHeader.Buttons />
            </DashHeader>

            {isCalendar && activeView === "inspectionsDue" ? (
              <DashCalendar
                data={
                  activeView === "inspectionsDue"
                    ? props.data[activeView]
                    : flatten(
                      props.data[activeView].map(insp =>
                        extractInspectorData(insp),
                      ),
                    )
                }
                activeDate={date}
                getCtaText={() => "Reassign"}
                onCtaClick={onCtaClick}
              />
            ) : (activeView === "inspectionsDue" ?
              <TableView
                showCTA = {false}
                activeView={activeView}
                data={props.data[activeView]}
                onCtaClick={onCtaClick}
                getCtaText={() => "Complete"}
              />
              :
              <TableView
                getLink={getLink}
                activeView={activeView}
                data={props.data[activeView]}
                onCtaClick={onCtaClick}
                getCtaText={() => "Reassign"}
              />
            )}
            <ReassignModal
              activeView={views.find(element => element.key === activeView)}
              initialId={formInitialData.inspectorId}
              projectId={formInitialData.projectId}
              show={showModal}
              hideModal={() => setShowModal(false)}
              inspectionId={formInitialData.inspectionId}
              handleReassign={props.handleReassign}
              users={props.data[activeView].map(inspector => ({
                value: inspector.id,
                label: inspector.name,
              }))}
            />
          </>
        )}
      </DashboardViews>
      {errorModal && (
        <SuccessModal
          show={errorModal}
          handleClose={() => setErrorModal(false)}
          handleSubmit={() => setErrorModal(false)}
          submitBtnText="OK"
          message={errorMessage}
          title="Error"
          isAlert
        />
      )}
    </section>
  );
};

RegionalManagerView.propTypes = {
  data: projectsPropType.isRequired,
};

export default RegionalManagerView;
