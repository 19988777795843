import { ISignatureEditorData } from "@sw-sw/lib-certification";
import { FormContextProvider } from "@sw-sw/lib-form";
import { Button } from "@sw-sw/lib-ui";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../contexts/AppContext";
import {
  SignatureEditorContext,
  SignatureEditorMode,
} from "../../../contexts/SignatureEditorContext";
import { CertificationUI } from "../../Inspections/Certification/CertificationUI";
import Loading from "../../Shared/ResourceIndex/Loading";
import DeleteButton from "./Buttons/DeleteButton";
import EditButton from "./Buttons/EditButton";
import ReorderButtons, { ReorderActionPayload } from "./Buttons/ReorderButtons";
import { SignatureEditorButtonProps } from "./Buttons/SignatureEditorButton";
import EditModal from "./EditModal";

export const SignatureEditor: React.FC<{}> = ({}) => {
  const appContext: any = useContext(AppContext);
  const signatureEditorContext = useContext(SignatureEditorContext);
  const [editorMode, setEditorMode] = useState<SignatureEditorMode>(
    SignatureEditorMode.LOADING,
  );
  const [data, setData] = useState<ISignatureEditorData>(
    JSON.parse(JSON.stringify(signatureEditorContext.data)),
  );

  useEffect(() => {
    setEditorMode(SignatureEditorMode.READ);
  }, []);

  if (editorMode === SignatureEditorMode.LOADING || !data) {
    return <Loading />;
  }

  const resetData = () => {
    if (signatureEditorContext.data) {
      setData(JSON.parse(JSON.stringify(signatureEditorContext.data)));
    }
  };

  const onSave = async () => {
    await signatureEditorContext.onUpdate(data);

    appContext.triggerSuccessNotification();
    setEditorMode(SignatureEditorMode.READ);
  };

  const onDelete = ({
    resourceIndex,
    resourceType,
    groupType,
  }: SignatureEditorButtonProps) => {
    let dataCopy = JSON.parse(JSON.stringify(data));

    dataCopy[resourceType][groupType].splice(resourceIndex, 1);

    setData(dataCopy);
  };

  const onReorder = ({
    resourceType,
    resourceIndex,
    targetIndex,
    groupType,
  }: ReorderActionPayload) => {
    let dataCopy = JSON.parse(JSON.stringify(data));
    let tempLine = dataCopy[resourceType][groupType][resourceIndex];

    dataCopy[resourceType][groupType][resourceIndex] =
      dataCopy[resourceType][groupType][targetIndex];
    dataCopy[resourceType][groupType][targetIndex] = tempLine;

    setData(dataCopy);
  };

  return (
    <div className="template-signature-editor">
      <div className="button-container">
        {editorMode === SignatureEditorMode.EDIT && (
          <Button primary onClick={onSave}>
            Save
          </Button>
        )}
        <Button
          outline
          onClick={() => {
            if (editorMode === SignatureEditorMode.EDIT) {
              setEditorMode(SignatureEditorMode.READ);
              resetData();
            } else {
              setEditorMode(SignatureEditorMode.EDIT);
            }
          }}
        >
          {`${
            editorMode === SignatureEditorMode.EDIT
              ? "Cancel"
              : "Edit Signatures"
          }`}
        </Button>
      </div>
      <FormContextProvider>
        <CertificationUI
          signatureGroups={data.lines}
          attestationGroups={data.attestations}
          isCcr={false}
          minCertificationDate={new Date()}
          onSave={async (...args) => {
            console.trace(args);
          }}
          readOnly={true}
          renderEditButton={
            editorMode === SignatureEditorMode.EDIT
              ? props => <EditButton {...props} />
              : undefined
          }
          renderDeleteButton={
            editorMode === SignatureEditorMode.EDIT
              ? props =>
                  data[props.resourceType][props.groupType].length > 1 ? (
                    <DeleteButton onDelete={onDelete} {...props} />
                  ) : null
              : undefined
          }
          renderReorderButtons={
            editorMode === SignatureEditorMode.EDIT
              ? props => (
                  <ReorderButtons
                    onReorder={onReorder}
                    resourceSize={data.lines[props.groupType].length}
                    {...props}
                  />
                )
              : undefined
          }
        />
      </FormContextProvider>

      {signatureEditorContext.modal && (
        <FormContextProvider>
          <EditModal value={data} onSubmit={setData} />
        </FormContextProvider>
      )}
    </div>
  );
};
