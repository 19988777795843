import {
  faCaretDown,
  faCaretUp,
  faFolder,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingMessage } from "@sw-sw/lib-ui";
import classnames from "classnames";
import React, {
  Context,
  createRef,
  Fragment,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  Link as ReactRouterLink,
  RouteComponentProps,
  useHistory,
  withRouter,
} from "react-router-dom";
import AppContext from "../../contexts/AppContext";
import AppDivisionContext from "../../contexts/AppDivisionContext";
import ClientContext from "../../contexts/ClientContext";
import DivisionContext from "../../contexts/DivisionContext";
import InspectionContext from "../../contexts/InspectionContext";
import NotificationContext from "../../contexts/NotificationsContext";
import ProjectContext from "../../contexts/ProjectContext";
import RegulationContext from "../../contexts/RegulationContext";
import NotificationBell from "../Shared/Notifications/NotificationBell";
import NotificationPopper from "../Shared/Notifications/NotificationPopper";
import BreadCrumbs from "./BreadCrumbs";
import DivisionPopper from "./poppers/DivisionPopper";
import UserPopper from "./poppers/UserPopper";

export type ISecondaryNavigationProps = RouteComponentProps<any> & {
  className: string;
  match: {
    path: string;
    params: { id?: string; uuid?: string };
  };
};

const PopperMenu = ({
  type,
  handleClose,
  coordinates,
  onChange,
}: {
  type: string;
  handleClose: (data?: any) => void;
  coordinates: {
    right: number;
    top: number;
  };
  onChange: (division?: number) => void;
}) => {
  switch (type) {
    case "user":
      return <UserPopper handleClose={handleClose} coordinates={coordinates} />;
    case "division":
      return (
        <DivisionPopper
          handleClose={handleClose}
          coordinates={coordinates}
          onChange={onChange}
        />
      );
    case "notification":
      return (
        <NotificationPopper
          handleClose={handleClose}
          coordinates={coordinates}
        />
      );
    default:
      return null;
  }
};

export const getRootProperties = (match: {
  path: string;
  params: { uuid?: string; id?: string };
  url: string;
}) => {
  // eslint-disable-next-line
  const resource = mapResourceToContext.find(res => match.url.match(res.regex));

  return {
    to: match.params.uuid || match.params.id || "",
    text: resource
      ? resource.resourceName === "Inspection"
        ? "Projects"
        : resource.resourceName
      : "",
    rootTo: resource ? resource.rootTo : "/dashboard",
  };
};

const mapResourceToContext: Array<{
  resourceName: string;
  regex: RegExp;
  context?: Context<any>;
  hook?: (clientid: number) => void;
  contextKey?: string;
  rootTo: string;
}> = [
  {
    resourceName: "Inspection Templates",
    regex: /\/inspection-templates/,
    rootTo: "/inspection-templates",
  },
  {
    resourceName: "Projects",
    regex: /\/inspection/,
    context: InspectionContext,
    contextKey: "inspection",
    rootTo: "/projects",
  },
  {
    resourceName: "Projects",
    regex: /\/divisions\/(\d+)\/projects/,
    context: ProjectContext,
    contextKey: "project",
    rootTo: "/projects",
  },
  {
    resourceName: "Regulations",
    regex: /\/divisions\/(\d+)\/groups/,
    context: RegulationContext,
    contextKey: "regulation",
    rootTo: "/groups",
  },
  {
    resourceName: "Clients",
    regex: /\/divisions\/(\d+)\/clients/,
    //hook: useClient,
    context: ClientContext,
    contextKey: "client",
    rootTo: "/clients",
  },
  {
    resourceName: "Legend Items",
    regex: /\/divisions\/(\d+)\/map-legend/,
    rootTo: "/map-legend",
  },
  {
    resourceName: "Users",
    regex: /\/divisions\/(\d+)\/admin/,
    rootTo: "/admin",
  },
  {
    resourceName: "Dashboard",
    regex: /\/divisions\/(\d+)\/dashboard/,
    rootTo: "/dashboard",
  },
  {
    resourceName: "Divisions",
    regex: /\/divisions/,
    context: DivisionContext,
    contextKey: "selectedDivision",
    rootTo: "/divisions",
  },
];

/**
 * @todo refactor breadcrumbs into its own component. Remove non-generic code.
 * @todo use labels from navigation data. Don't hard code labels.
 */
const SecondaryNavigation: React.FC<ISecondaryNavigationProps> = ({
  className,
  match,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [menuType, setMenuType] = useState("user"); // oneOf: ['user', 'notification', 'division']
  const [coordinates, SetCoordinates] = useState({ right: 0, top: 0 });
  const userRef = createRef<any>();
  const divisionRef = createRef<any>();

  const appContext = useContext(AppContext);
  const inspectionContext = useContext(InspectionContext);
  const notificationContext = useContext(NotificationContext);
  const divisionContext = useContext(DivisionContext);
  const appDivisionContext = useContext(AppDivisionContext);

  const history = useHistory();

  const user = appContext.get("user");

  const notificationCount = notificationContext.notifications.filter(
    ({ is_opened }: { is_opened: Boolean }) => !is_opened,
  ).length;

  const Link = ({ children, ...rest }: { children: any; to: any }) => {
    // needs work and more specificity
    return (
      <ReactRouterLink
        {...rest}
        to={rest.to}
        onClick={() => setShowMenu(false)}
      >
        {children}
      </ReactRouterLink>
    );
  };

  const getDivision = () => {
    const division = appDivisionContext.divisions.find(
      (div: { id: number }) => div.id === appDivisionContext.appDivisionId,
    );
      
    return division ? division.name : null;
  };
  
  useEffect(()=>{
    appDivisionContext.refetchDivisions()
  },[window.location.href])

  function handleClick(type: string) {
    const { right, bottom } =
      type === "division"
        ? divisionRef.current.getBoundingClientRect()
        : userRef.current.getBoundingClientRect();

    SetCoordinates({ right: window.innerWidth - right, top: bottom });
    setMenuType(type);
    setShowMenu(true);
  }

  function getRootBreadCrumb() {
    const { rootTo, text } = getRootProperties(match);

    return <Link to={appDivisionContext.getPath(`${rootTo}`)}>{text}</Link>;
  }

  function getBreadCrumbs() {
    const [path, param] = getParamFromProps(match);
    const isInspection = path === "inspection";

    const to = match.params.uuid || match.params.id;
    const resource = mapResourceToContext.find(res =>
      match.url.match(res.regex),
    );

    if (!param) return null;

    return to ? (
      <BreadCrumbs
        Link={Link}
        to={to}
        inspection={
          isInspection && inspectionContext.inspection
            ? inspectionContext.inspection
            : null
        }
        resource={resource}
      />
    ) : null;
  }

  const getParamFromProps = (matchFromProps: {
    path: string;
    params: { id?: string; uuid?: string };
  }) => {
    const [path, ...rest] = matchFromProps.path.split("/").slice(1);

    const { params } = matchFromProps;
    const paramsKeys = Object.keys(params);

    if (paramsKeys.includes("division_id")) {
      return [rest[1], rest.slice(2)];
    } else {
      return [path, rest];
    }
  };

  const onDivisionChange = (division = appDivisionContext.appDivisionId) => {
    const { rootTo } = getRootProperties(match);
    
    history.push(appDivisionContext.getPath(rootTo, division || undefined));
  };

  return (
    <Fragment>
      <header className={classnames("top-nav", className)}>
        <nav
          aria-label="secondary navigation"
          className="inline-nav breadcrumbs"
        >
          {getRootBreadCrumb()}
          {getBreadCrumbs()}
        </nav>

        {appContext.isLoading ? (
          <div className="inline-nav app-loading-status">
            <LoadingMessage what={appContext.loadingResource} />
          </div>
        ) : null}

        <div className="inline-nav user-details flex-row">
          {getDivision() && !divisionContext.disableSwitcher && (
            <span
              onClick={() => handleClick("division")}
              className="flex-row division-selector pointer"
              ref={divisionRef}
            >
              <FontAwesomeIcon icon={faFolder} className="pad-right" />
              <h4>{getDivision()}</h4>
              <FontAwesomeIcon
                icon={
                  showMenu && menuType === "division" ? faCaretUp : faCaretDown
                }
                className="pad-left"
              />
            </span>
          )}

          {notificationContext.loaded && (
            <NotificationBell
              handleClick={() => handleClick("notification")}
              notificationCount={notificationCount}
            />
          )}

          {/* wrap in span because FA doesn't forward refs */}
          <span
            onClick={() => handleClick("user")}
            className="flex-row pointer"
            ref={userRef}
          >
            <h4>{user.name}</h4>
            <FontAwesomeIcon
              icon={showMenu && menuType === "user" ? faCaretUp : faCaretDown}
              className="pad-left"
            />
          </span>
        </div>
      </header>

      {showMenu ? (
        <PopperMenu
          type={menuType}
          coordinates={coordinates}
          handleClose={data => {
            if (menuType === "notification" && notificationCount > 0 && !data) {
              notificationContext.markNotificationAsRead();
            }
            setShowMenu(false);
          }}
          onChange={menuType === "division" ? onDivisionChange : () => {}}
        />
      ) : null}
    </Fragment>
  );
};

export default withRouter(SecondaryNavigation);
