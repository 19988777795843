import xhrService from "../api";

export const inspectionTemplateApi = {
  index: () => {
    return xhrService.get("/api/inspections/templates").then(res => res.data);
  },

  indexAll: () => {
    return xhrService.get("/api/inspection-templates").then(res => res.data);
  },

  get: id => {
    return xhrService
      .get(`/api/inspection-templates/${id}`)
      .then(res => res.data)
      .catch(({ response }) => {
        throw new Error(response.data.message);
      });
  },

  create: data =>
    xhrService.post(`/api/inspection-templates`, data).then(res => res.data),

  update: (id, data) =>
    xhrService
      .put(`/api/inspection-templates/${id}`, data)
      .then(res => res.data),

  destroy: id => {
    return xhrService
      .delete(`/api/inspection-templates/${id}`)
      .then(res => res.data)
      .catch(({ response }) => {
        throw new Error(response.data.message);
      });
  },
};

export default inspectionTemplateApi;
