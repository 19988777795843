import { useContext, useEffect } from "react";
import { QueryClient, QueryObserverOptions, useQuery } from "react-query";
import AppContext from "../contexts/AppContext";
import projectInspectionApi, {
  ProjectInspection,
} from "../utils/api/projectInspection";
import {
  getFindingCounts,
  InspectionFindingCount,
} from "../utils/findingCount";

export type InspectionWithFindingCount = ProjectInspection & {
  findingCount: InspectionFindingCount;
};

function getQueryKey(projectId: number) {
  return ["project", "inspections", projectId];
}

function addFindingCount(
  inspection: ProjectInspection,
): InspectionWithFindingCount {
  return { ...inspection, findingCount: getFindingCounts(inspection) };
}

export function useProjectInspections(
  projectId: number,
  queryOptions: Omit<
    QueryObserverOptions<InspectionWithFindingCount[]>,
    "queryKey" | "queryFn" | "enabled" | "keepPreviousData"
  > = {},
) {
  const appContext = useContext(AppContext);
  const query = useQuery<InspectionWithFindingCount[]>({
    queryKey: getQueryKey(projectId),
    queryFn: () =>
      appContext.loadData(async () => {
        if (projectId === 0) {
          return [];
        }

        const data: ProjectInspection[] = projectId
          ? await projectInspectionApi.index(projectId)
          : [];

        return data.map(addFindingCount);
      }),
    enabled: false,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    ...queryOptions,
  });

  useEffect(() => {
    query.refetch();
  }, [projectId]);

  return query;
}

export function setProjectInspectionsQueryData({
  projectId,
  data,
  queryClient,
}: {
  projectId: number;
  data: ProjectInspection[];
  queryClient: QueryClient;
}): void {
  const key = getQueryKey(projectId);

  queryClient.setQueryData<InspectionWithFindingCount[]>(
    key,
    data.map(addFindingCount),
  );
  queryClient.invalidateQueries(key);
}
