import React from "react";

function MouseIcon({ title, ...props }) {
  return (
    <svg
      width="12px"
      height="18px"
      viewBox="0 0 12 18"
      version="1.1"
      {...props}
    >
      <title>{title}</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-565.000000, -56.000000)" fill="#DBDBE0">
          <g transform="translate(0.000000, 39.000000)">
            <g transform="translate(520.000000, 12.000000)">
              <path
                d="M55.6875535,7.85398723 L42.7559418,13.0084354 C42.2369585,13.223479 42.2150574,13.9322156 42.7129638,14.189938 L46.756882,16.2090384 L43.8586714,19.0439849 C43.404561,19.4949991 43.404561,20.2464146 43.8586714,20.6974288 C44.3127818,21.148443 45.0693557,21.148443 45.5234662,20.6974288 L48.3349027,17.9051611 L50.1732455,21.5776023 C50.4327371,22.0712954 51.1463392,22.0503683 51.3628591,21.5349173 L56.5308952,8.69147578 C56.7255139,8.17603096 56.2065306,7.66058615 55.6875472,7.85387795 L55.6875535,7.85398723 Z"
                id="Fill-1"
                transform="translate(49.462730, 14.873697) rotate(-67.000000) translate(-49.462730, -14.873697) "
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

MouseIcon.defaultProps = {
  title: "",
};

export default MouseIcon;
