import io from "socket.io-client";
import { env } from "../config";

export type Socket = SocketIOClient.Socket;

const getUrl = (ns: string) =>
  [env.REACT_APP_WS_BASE_URL || env.REACT_APP_API_BASE_URL, ns].join("/");

/**
 * Map of namespaces to connected sockets
 */
const connectedSockets: {
  [name: string]: Socket;
} = {};

const wsUtil = {
  connect: async (namespace: string) => {
    console.log("WS: attempt connect: ", { namespace });

    if (!namespace) {
      throw new Error('"namespace" is required');
    }

    var sk: Socket | null = null;

    console.log("ws connect", namespace, connectedSockets);

    if (connectedSockets[namespace]) {
      // return connectedSockets[namespace];
      sk = connectedSockets[namespace];

      if (!sk.connected) {
        sk.connect();
      }

      return sk;
    }

    const query: { token?: string } = {};
    const token = JSON.parse(localStorage.getItem("token") || "");

    if (token) {
      query.token = token;
    }

    connectedSockets[namespace] = io(getUrl(namespace), {
      forceNew: true,
      query,
    });

    const socket = connectedSockets[namespace];

    socket.on("connect", () => {
      console.log("WS: connected: ", { namespace, socket });
    });

    socket.on("error", (err: any) => {
      console.log("WS: error!", { namespace, err });
    });

    socket.on("disconnect", () => {
      console.log("WS: Disconnected!", { namespace });

      delete connectedSockets[namespace];
    });

    if (!connectedSockets[namespace]) {
      throw new Error(`socket "${namespace}" not found!`);
    }

    return sk;
  },

  disconnect: (namespace: string) => {
    if (connectedSockets[namespace]) {
      return connectedSockets[namespace].disconnect();
    }

    return null;
  },

  send: async (namespace: string, message: string, payload: any) => {
    console.log("WS: Send", { connectedSockets, namespace, message, payload });

    if (!connectedSockets[namespace]) {
      await wsUtil.connect(namespace);
    }

    connectedSockets[namespace].emit(message, payload);
  },
  on: (namespace: string, event: string, handler: Function) => {
    return connectedSockets[namespace].on(event, handler);
  },
};

export default wsUtil;
export { wsUtil };
