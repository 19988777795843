import React, { useState } from "react";
import CustomMessageModal from "./CustomMessageModal";
import ReactToolTip from "react-tooltip";
import { string, number, func, arrayOf, bool } from "prop-types";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDesktop,
  faEnvelopeOpenText,
  faCog,
  faQuestionCircle,
  faSms
} from "@fortawesome/free-solid-svg-icons";
import { capitalize } from "lodash";
const NotificationControl = props => {
  const [showModal, setShowModal] = useState(false);
  const {
    name,
    id,
    isEnabled,
    defaultEmailMessage,
    hasCustomMessage,
    updateSettings,
    loading,
    description,
  } = props;
  const handleCheck = key => {
    const payload = [...isEnabled];
    if (isEnabled.includes(key)) {
      updateSettings(
        id,
        payload.filter(item => item !== key),
        defaultEmailMessage,
      );
    } else {
      payload.push(key);
      updateSettings(id, payload, defaultEmailMessage);
    }
  };
  const handleMessage = message => {
    updateSettings(id, isEnabled, message.customMessage);
    setShowModal(false)
  };
  const renderCheck = (key, index) => (
    <div key={index}>
      <label
        htmlFor={`custom-checkbox-${key}`}
        className={classnames({
          "check-holder": true,
          disabled: loading,
        })}
        onClick={() => handleCheck(key)}
        data-tip={capitalize(key)}
        data-delay-show="125"
        onMouseOver={() => {
          ReactToolTip.show();
        }}
      >
        <FontAwesomeIcon
          icon={getDeliveryTypeIcon(key)}
          id={`custom-checkbox-${key}`}
          className={classnames({
            active: isEnabled.includes(key),
            inactive: !isEnabled.includes(key),
            selectable: !loading,
          })}
        />
      </label>
    </div>
  );
  const options = ["in-app", "email"];
  const smsOptions = ['in-app', "email", "sms"]
  const notificationEnabledForsms = ["Missed Inspection", "Inspection Ready To Sign", "Inspection Complete"]
  
  const deliveryTypeIconMap = {
    "in-app": faDesktop,
    email: faEnvelopeOpenText,
    sms: faSms
  };

  const getDeliveryTypeIcon = key => {
    if (!(key in deliveryTypeIconMap)) {
      throw new Error("No icon for Delivery Type");
    } else {
      return deliveryTypeIconMap[key];
    }
  };
  const handleSettingsClick = event => {
    event.preventDefault();
    setShowModal(true);
  };
  return (
    <div className="setting-control" key={id}>
      <div className="flex">
        <div className="name">
          {name}
          <span
            className="help-icon"
            data-tip={description}
            data-delay-show="125"
            onMouseOver={() => ReactToolTip.show()}
          >
            <FontAwesomeIcon icon={faQuestionCircle} size="xs" />
          </span>
        </div>
      </div>
      {hasCustomMessage && (
        <CustomMessageModal
          show={showModal}
          hideModal={() => setShowModal(false)}
          handleSubmit={handleMessage}
          defaultEmailMessage={defaultEmailMessage}
          subTitle={`Set a default message that will appear in the ${name} notification email. The message will be seen by members of the project's distribution list.`}
        />
      )}

      
      {notificationEnabledForsms.includes(name) ?
        <form className="flex">
          {hasCustomMessage && (
            <FontAwesomeIcon icon={faCog} onClick={handleSettingsClick} className="settings-icon" />
          )}

            {smsOptions.map((option, index) => renderCheck(option, index))}
        </form> :
        <form className="flex">
          {hasCustomMessage && (
            <FontAwesomeIcon icon={faCog} onClick={handleSettingsClick} className="settings-icon" />
          )}

            {options.map((option, index) => renderCheck(option, index)).concat(<span style={{"padding-left": "1.2rem"}}>&nbsp;</span>)}
        </form>
      }

      <ReactToolTip className="tool-tip" />
    </div>
  );
};
NotificationControl.propTypes = {
  id: number.isRequired,
  name: string.isRequired,
  description: string.isRequired,
  isEnabled: arrayOf(string).isRequired,
  updateSettings: func.isRequired,
  hasCustomMessage: bool.isRequired,
};
export default NotificationControl;