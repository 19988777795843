import React, { useContext, useEffect } from "react";
import BmpDetails from "../../components/Projects/Details/BmpDetails";
import FindingListing from "../../components/Projects/Details/CorrectiveActionsTab";
import Inspections from "../../components/Projects/Details/Inspections";
import ProjectContacts from "../../components/Projects/Details/ProjectContacts";
import ProjectLog from "../../components/Projects/Details/ProjectLog";
import SiteMaps from "../../components/Projects/Details/SiteMaps";
import ProjectDocuments from "../../components/Projects/ProjectDocuments";
import Loading from "../../components/Shared/ResourceIndex/Loading";
import { ProjectContext } from "../../contexts/ProjectContext";
import AppContext from "../../contexts/AppContext";

/**
 *
 * @important Beware of infinite loops, when a parent element depends on ProjectContext and its child uses this HOC.
 */
export function withProjectDocs(Component) {
  return props => {
    const { projectDocs, project, loadDocs } = useContext(ProjectContext);
    const appContext = useContext(AppContext);

    if (!project) {
      throw new Error("project is not loaded");
    }

    useEffect(() => {
      appContext.loadData(() => loadDocs(project.id), "project documents");
    }, []);

    if (!projectDocs) {
      return <Loading what="project documents" />;
    }

    return <Component {...props} {...projectDocs} />;
  };
}
/**
 * @todo simplify these. see "All Inspections" and "Contacts" for optimal config
 */
export const projectTabs = [
  {
    to: "/inspections",
    label: "All Inspections",
    showTab: func => func("read", "Inspection"),
    Component: Inspections,
  },
  {
    to: "/project-docs",
    label: "Project Documents",
    Component: withProjectDocs(ProjectDocuments),

    getProps(_, componentContext) {
      const {
        onDocumentUpload,
        onDocumentDelete,
        shouldRenderRegulations,
        context,
        onMove,
        onDrop,
      } = componentContext;

      if (context.project) {
        return {
          projectId: context.project.id,
          onDocumentUpload,
          onDocumentDelete,
          onMove,
          onDrop,
          shouldRenderRegulations,
        };
      }

      return null;
    },
    showTab: func => func("read", "Project") || func("read", "Regulations"),
  },
  {
    to: "/bmp-docs",
    label: "BMP Details",
    Component: props => {
      const appContext = useContext(AppContext);

      return props.projectId ? (
        <BmpDetails {...props} appContext={appContext} />
      ) : null;
    },
    getProps(_, componentContext) {
      const { context } = componentContext;

      if (context.project) {
        return {
          groupId: context.project.document_group,
          projectId: context.project.id,
        };
      }

      return null;
    },
    isRegulation: true,
  },
  {
    to: "/active-site-map",
    label: "Active Site Map",
    Component: withProjectDocs(SiteMaps),
    getProps(state, componentContext) {
      const { context, onDocumentUpload, onDocumentDelete } = componentContext;

      if (!context.project) {
        return null;
      }

      return {
        onDocumentDelete,
        onDocumentUpload,
        showActions: true,
      };
    },
  },
  {
    to: "/contacts",
    label: "Contacts",
    Component: ProjectContacts,
  },
  {
    to: "/findings",
    label: "Corrective Actions",
    Component: FindingListing,
  },
  {
    to: "/log",
    label: "Log",
    Component: props => (props.projectId ? <ProjectLog {...props} /> : null),
    getProps(_, componentContext) {
      const { context } = componentContext;

      return {
        projectId: context.project.id,
      };
    },
  },
];
