import {
  CertificationIndexResponse,
  CertSchemaField,
  CreateApiParam,
  fieldKeyCodec,
  getInitialFormData,
  SignatureGroupType,
} from "@sw-sw/lib-certification";
import { FormContextProvider } from "@sw-sw/lib-form";
import { get, set } from "lodash";
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useQuery } from "react-query";
import LoadingModal from "../components/Shared/Loading/LoadingModal";
import { Loading } from "../components/Shared/ResourceIndex/Loading";
import SuccessNotification from "../components/Shared/SuccessNotification/SuccessNotification";
import { certificationApi } from "../utils/api/certification";
import AppContext from "./AppContext";
import { InspectionContext } from "./InspectionContext";

type CertificationContextValue = {
  // loading: boolean;
  error?: string;
  state: CertificationIndexResponse;
  onSave: SaveHandler;
  // setShowModal: Dispatch<ShowModalState>;
  flashSuccess: () => void;
};

type ShowModalState = null | "loading" | "success";
type SaveHandler = (
  arg: Pick<CreateApiParam, "lineType" | "group"> & {
    formData: any;
  },
) => Promise<void>;

const InspectionCertificationContext =
  React.createContext<CertificationContextValue>({
    // loading: true,
    onSave: async () => {},
    flashSuccess: () => {},
    state: {} as CertificationIndexResponse,
  });

export const InspectionCertificationContextProvider: React.FC<
  PropsWithChildren<{}>
> = ({ children }) => {
  const inspectionContext = useContext(InspectionContext);
  const appContext = useContext(AppContext);
  const { id: inspectionId } = inspectionContext.inspection;
  const query = useQuery({
    queryKey: ["cert", inspectionId],
    queryFn: () => certificationApi.index(inspectionId),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
  const [showModal, setShowModal] = useState<ShowModalState>(null);

  const inspectorDefaultDate = () => {
    const date = new Date(inspectionContext.inspection.created_date);
    const offset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() + offset);
  };

  const initialFormData = useMemo(() => {
    if (query.data) {
      const { default_email_message: emailMessage = "" } =
        appContext.getNotificationSetting("Inspection Complete") || {};

      const initialData = getInitialFormData({
        ...query.data,
        user: {
          ...appContext.get("user"),
          emailMessage,
        },
        // for inspection signatures
        inspectionDate: inspectorDefaultDate(),
      });

      return Object.keys(initialData).reduce((data, key) => {
        /** @important, use lodash.set, to ensure compatibility with context */
        set(data, key, initialData[key]);

        return data;
      }, {});
    }

    return {};
  }, [query.data]);

  const flashSuccessModal = () => {
    window.setTimeout(() => setShowModal(null), 2000);
    setShowModal("success");
  };

  const onSave: SaveHandler = useCallback(
    async ({ group, lineType, formData }) => {
      setShowModal("loading");

      const data: CreateApiParam = {
        inspectionId,
        group,
        lineType,
        date: get(
          formData,
          fieldKeyCodec.encode(lineType, CertSchemaField.date),
        ),
      };

      if (group === SignatureGroupType.inspection) {
        data.emailMessage = get(formData, CertSchemaField.emailMessage);
      }

      return certificationApi
        .create(data)
        .then(async () => {
          flashSuccessModal();

          query.refetch();

          // lock inspection questions after compliance signature
          if (data.lineType === SignatureGroupType.compliance) {
            inspectionContext.updateInspection(
              Object.assign(inspectionContext.inspection, {
                compliance_date: data.date,
              }),
            );
          }
        })
        .catch(err => {
          setShowModal(null);

          return Promise.reject(err);
        });
    },
    [inspectionId, query.data],
  );

  useEffect(
    () => () => {
      query.remove();
    },
    [],
  );

  if (query.isLoading || query.data === undefined) {
    return <Loading />;
  }

  return (
    <InspectionCertificationContext.Provider
      value={{
        // loading: query.isLoading,
        error: String(query.error) || undefined,
        state: query.data,
        onSave,
        flashSuccess: flashSuccessModal,
      }}
    >
      <FormContextProvider initialValue={initialFormData}>
        {children}
      </FormContextProvider>

      <SuccessNotification show={showModal === "success"} />
      <LoadingModal show={showModal === "loading"} />
    </InspectionCertificationContext.Provider>
  );
};

export default InspectionCertificationContext;
