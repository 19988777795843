import {
  FormContext,
  FormSchemaFields,
  UploadControlContextProvider,
} from "@sw-sw/lib-form";
import { UIControlType } from "@sw-sw/lib-form-control-types";
import { orderBy } from "lodash";
import PropTypes from "prop-types";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../contexts/AppContext";
import { ProjectContext } from "../../../contexts/ProjectContext";
import ProjectPermissionContext from "../../../contexts/ProjectPermissionContext";
import RolesContext from "../../../contexts/RolesContext";
import legendItemApi from "../../../utils/api/legendItem";
import uploadApi from "../../../utils/api/upload";
import { parseYMD } from "../../../utils/date";
import Loading from "../../Shared/ResourceIndex/Loading";
import ObservationsFormControl from "./Observations/ObservationsFormControl";
import { questionConfigValueApi } from "../../../utils/api/questionConfigValue";
import { inspectionQuestionApi } from "../../../utils/api/inspectionQuestion";
import clientApi from "../../../utils/api/client";
import projectApi from "../../../utils/api/project";
import findingTypesApi from "../../../utils/api/findingTypes";

const findingObservationTemplates = [
  {
    type: "CA",
    label: "Corrective Action Notice",
    content:
      "Corrective Action Item is to be addressed next business day after the inspection, per scheduled control measure maintenance.",
  },
  {
    label: "Completed as recommended",
    content: "Completed as recommended",
  },
];

export const defaultCompletionTemplateContent =
  findingObservationTemplates[1].content;

export const getFindingCommentsSchema = (
  canCreate = false,
  canRead = false,
  canEdit = false,
  canDelete = false,
  readOnly = false,
) => {
  const schema = {
    /** field to add comments */
    comments: {
      label: "Observations",
      controlType: UIControlType.textareaTmpl,
      "aria-label": "Observations",
      templates: findingObservationTemplates,
      disabled: !canCreate,
      style: { marginBottom: "0.25rem" },
    },
  };

  if (canRead) {
    /** display-only "field" for previous comments */
    schema.observations = {
      controlType: UIControlType.custom,
      renderControl: controlProps => (
        <ObservationsFormControl
          {...controlProps}
          canEdit={canEdit}
          canDelete={canDelete}
          readOnly={readOnly}
        />
      ),
    };
  }

  return schema;
};

export const extractFindingData = (model, inspectionId) => {
  const {
    id,
    commentables,
    date_completed,
    date_initiated,
    finding_uploads = [],
    location,
    type,
    condition,
    control_measure,
    legend_item_id,
  } = model;

  const images = finding_uploads
    .filter(findingUpload => !findingUpload.is_completed)
    .map(toImageUpload);
  const completedImages = finding_uploads
    .filter(findingUpload => findingUpload.is_completed)
    .map(toImageUpload);

  return {
    id,
    inspectionId,
    images: images,
    completedImages: completedImages,
    observations: orderBy(commentables || [], "created_at", "desc"),
    comments: "",
    type: type ? type : "CA",
    location: location ? location : "See site inspection map for location",
    date_initiated: date_initiated
      ? parseYMD(date_initiated.substr(0, 10))
      : null,
    date_completed: date_completed
      ? parseYMD(date_completed.substr(0, 10))
      : null,
    condition,
    control_measure,
    legend_item_id: legend_item_id,
  };
};

export const toImageUpload = ({ id, upload, annotations }) => {
  return {
    id,
    guid: upload.GUID,
    type: upload.mime_type,
    name: upload.name,
    rotation: upload.rotation ? upload.rotation : 0,
    annotations: annotations ? annotations : [],
  };
};

export const getInitialData = (defaultDateInitiated, type) => ({
  type: type,
  location: "See site inspection map for location",
  date_initiated: defaultDateInitiated,
  date_completed: null,
  images: [],
  observations: [],
  comments: "",
});

/**
 *
 * @param {boolean} commentOnly
 * @param {string} userRole User's Role.Name
 * @param {function} permCheck
 * @param {{condition_options: Array, name: string}} template
 * @param {boolean} readOnly disables inputs
 */
function getSchema(
  templateID,
  commentOnly,
  userRole,
  permCheck,
  template,
  readOnly,
  formData,
  enableEditComments,
  legendItems,
  archived,
  projectStartDate,
  conditionOptions,
  disableImage,
) {
  const restrictedForRoles =
    ["BMP Contractor", "Client User"].indexOf(userRole) > -1;

  const templateConditionOptions =
    conditionOptions && conditionOptions.length
      ? conditionOptions
      : template.condition_options;

  const displayStyle = { display: commentOnly ? "none" : null };
  const [findingTypes, setFindingTypes] = useState([])
  const handleName = (name) => {

    if (name === "A") {
      return "Achievement"
    }
    else if (name === "MI") {
      return "Maintainence Item"
    }
    else if (name === "CA") {
      return "Corrective Action"
    }
    else {
      return name
    }
  }
  React.useEffect(() => {
    (async () => {
      {
        const data = await findingTypesApi.index(templateID)
        const findingName = data.filter((ele) => ele.details.is_enabled)
        .map((ele) => ({ value: ele.name, label: handleName(ele.name)}))

        setFindingTypes(findingName)
      }
    })();
  }, []);

  const schema = {
    type: {
      label: "Finding Type",
      controlType: UIControlType.select,
      validation: { required: true },
      options: findingTypes,
      style: displayStyle,
      disabled: restrictedForRoles || readOnly,
      className: "finding-mobile",
    },
    location: {
      label: "Location",
      controlType: UIControlType.text,
      validation: {
        required: true,
      },
      style: displayStyle,
      disabled: readOnly,
    },
    legend_item_id: {
      label: "Legend Item",
      controlType: UIControlType.select,
      options:
        legendItems.length > 0
          ? legendItems.map(legItem => {
            return {
              label: legItem.name,
              value: legItem.id,
            };
          })
          : [],
      style: displayStyle,
      disabled: readOnly,
    },
    date_initiated: {
      label: "Date Initiated",
      controlType: UIControlType.date,
      validation: {
        required: true,
        minDate: moment(projectStartDate).toDate(),
        maxDate: new Date(),
      },
      style: { flex: "1 1 50%", ...displayStyle },
      disabled: restrictedForRoles || readOnly,
    },
    date_completed: {
      label: "Date Completed",
      controlType: UIControlType.date,
      style: { flex: "1 1 50%", ...displayStyle },
      validation: {
        maxDate: new Date(),
        minDate: formData.date_initiated ? formData.date_initiated : undefined,
      },
      disabled: readOnly,
    },

    ...getFindingCommentsSchema(
      archived || readOnly ? false : permCheck("create", "finding_comments"),
      permCheck("read", "finding_comments"),
      archived
        ? false
        : enableEditComments
          ? permCheck("update", "finding_comments")
          : false,
      archived
        ? false
        : enableEditComments
          ? permCheck("delete", "finding_comments")
          : false,
      readOnly,
    ),

    control_measure: {
      label: "Control Measure",
      style: { flex: "1 1 50%", ...displayStyle },
      disabled: readOnly,
      validation: { required: true },
    },
    condition: {
      label: "Condition",
      controlType: UIControlType.select,
      options: templateConditionOptions.map(option => ({
        label: option,
        value: option,
      })),
      style: { flex: "1 1 50%", ...displayStyle },
      disabled: readOnly,
      validation: { required: true },
    },
    images: {
      label:
        formData.completedImages && formData.completedImages.length
          ? "Initial Images"
          : "",
      controlType: UIControlType.imageUpload,
      style: displayStyle,
    },
  };

  if (formData.completedImages && formData.completedImages.length) {
    schema.completedImages = {
      label: "Completion Images",
      controlType: UIControlType.imageUpload,
      style: displayStyle,
    };
  }

  if (template.name !== "Delaware") {
    delete schema.legend_item_id;
  }

  /** @todo refactor to use schema from server */
  if (
    !(template.name === "CDOT" || (conditionOptions && conditionOptions.length))
  ) {
    delete schema.condition;
    delete schema.control_measure;
  }

  if (!permCheck("read", "Finding Images") || disableImage) {
    delete schema.images;
  }
  
return schema;
}

/**
 * UI to create a note. Does not save data
 */

function FindingsForm({
  commentOnly,
  templateName,
  templateID,
  readOnly,
  defaultObservation,
  enableEditComments,
  inspectionId,
}) {
  const appStore = useContext(AppContext);
  const permCheck = useContext(RolesContext).userHasPermission;
  const projectPermissionContext = useContext(ProjectPermissionContext);
  const projectContext = useContext(ProjectContext);
  const [loading, setLoading] = useState(false);
  const [legendItems, setLegendItems] = useState([]);
  const formContext = useContext(FormContext);
  const [questionId, setQuestionId] = useState(null);
  const [conditionOptions, setConditionOptions] = useState([]);
  const [disableImage, setDisableImage] = useState(false);
  const [project, setProject] = useState((projectContext || {}).project);
  const {
    observations,
    commentables,
    date_initiated: dateInitiated,
    date_completed: dateCompleted,
  } = formContext.value;

  useEffect(() => {
    (async () => {
      if (project) {
        if (project.client_id) {
          const c = await clientApi.show(project.client_id);

          if (c && c.disable_images) {
            setDisableImage(c.disable_images);
          }
        }
      } else {
        const url = window.location.href;
        const regex = /projects\/(\d+)\/map/;
        const m = regex.exec(url);

        if (m !== null && m.length > 1) {
          const projectId = m[1];
          const projectDetail = await projectApi.get(projectId);
          setProject(projectDetail);
        }
      }
    })();
  }, [project]);

  useEffect(() => {
    (async () => {
      if (inspectionId) {
        const res = await inspectionQuestionApi.show(inspectionId);

        if (res && res.template && res.template.question_groups) {
          outer: for (const group of res.template.question_groups) {
            if (group.name === "Condition Group") {
              for (const question of group.questions) {
                if (question.input_label === "Condition") {
                  setQuestionId(question.id);
                  break outer;
                }
              }
            }
          }
        }
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (questionId) {
        const questionConfigValues = await questionConfigValueApi.index(
          questionId,
        );

        if (questionConfigValues) {
          for (const questionConfigValue of questionConfigValues) {
            if (
              "questionTypeFieldId" in questionConfigValue &&
              typeof questionConfigValue.value === "string"
            ) {
              setConditionOptions(JSON.parse(questionConfigValue.value));
              break;
            }
          }
        }
      }
    })();
  }, [questionId]);

  useEffect(() => {
    if (templateName === "Delaware") {
      legendItemApi.index(null, null, project.document_group).then(data => {
        setLegendItems(data);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (
      defaultObservation &&
      observations &&
      !observations.length &&
      !commentables
    ) {
      formContext.set("comments", defaultObservation);
    }
  }, [defaultObservation]);

  //populate Observations with "Completed as recommended" when Date Completed is filled
  useEffect(() => {
      if(formContext.value.date_completed && !formContext.value.comments)
        formContext.set("comments", findingObservationTemplates[1].content)

  }, [formContext.value.date_completed]);

  useEffect(() => {
    if (dateInitiated && dateCompleted && dateInitiated > dateCompleted) {
      formContext.set("date_completed", null);
    }
  }, [dateInitiated, dateCompleted]);

  // wait to gather roles and templates
  if (!appStore || loading) return <Loading />;
  
return (
    <UploadControlContextProvider
      {...{
        allowDelete: readOnly ? false : permCheck("delete", "Finding Images"),
        allowAnnotation: readOnly ? false : permCheck("all", "Annotations"),
        allowCreate: readOnly ? false : true,
        allowRotate: readOnly ? false : true,
        getUrl: uploadApi.getDownloadUrl,
      }}
    >
      <FormSchemaFields
        className="findings-form"
        schema={getSchema(
          templateID,
          commentOnly,
          appStore.get("user.roleName"),
          permCheck,
          {
            name: templateName,
            condition_options: appStore.get(
              "constants.findingConditionOptions",
            ),
          },
          readOnly,
          formContext.value,
          enableEditComments,
          legendItems,
          projectPermissionContext.readOnly,
          project ? project.start_date : null,
          conditionOptions,
          disableImage,
        )}
        formData={formContext.value}
        onChange={formContext.set}
      />
    </UploadControlContextProvider>
  );
}

FindingsForm.propTypes = {
  commentOnly: PropTypes.bool, // only display "observations" and "comments"
  readOnly: PropTypes.bool,
  templateName: PropTypes.string.isRequired,
  /**
   * Used to set the default _initial_ observation.
   */
  defaultObservation: PropTypes.string,

  /**
   * Determine if the edit and delete actions are available for comments/notes
   *
   * When true, the schema function will check user permission/role,
   * before rendering the edit & delete controls.
   *
   * When false, the edit and delete controls will never be rendered
   */
  enableEditComments: PropTypes.bool,
  inspectionId: PropTypes.number.isRequired,
};

FindingsForm.defaultProps = {
  initialValue: {},
  commentOnly: false,
  readOnly: false,
  templateName: "Unknown",
  defaultObservation: "",
  enableEditComments: true,
};

export default FindingsForm;

