import { useContext, useMemo } from "react";
import { InspectionContext } from "../contexts/InspectionContext";
import ProjectPermissionContext from "../contexts/ProjectPermissionContext";
import RolesContext from "../contexts/RolesContext";

type FindingActions = {
  primary: string | null;
  secondary: { icon: string; label: string; onClick: () => void }[];
};
export function useFindingActions({
  onDelete,
  edit = false,
}: {
  onDelete: () => void;
  edit?: boolean;
}): FindingActions {
  const roleContext = useContext(RolesContext);
  const inspectionContext = useContext(InspectionContext);
  const projectPermissionContext = useContext(ProjectPermissionContext);
  const readOnly = useMemo(
    () => projectPermissionContext.readOnly,
    [inspectionContext.readOnlyFindings, projectPermissionContext.readOnly],
  );

  const primary =
    !readOnly &&
    roleContext.userHasPermission(edit ? "update" : "create", "Findings")
      ? "Save"
      : null;

  const secondary =
    !readOnly && roleContext.userHasPermission("delete", "Findings")
      ? [
          {
            icon: "trash",
            label: "Delete Finding",
            onClick: () => onDelete(),
          },
        ]
      : [];

  return { primary, secondary };
}
