import { FormContext } from "@sw-sw/lib-form";
import { LayoutTypes } from "@sw-sw/lib-inspection-templates";
import {
  defaultTemplateRenderer,
  QuestionContext,
  QuestionGroupContext,
  RenderContextValue,
  UserDataContext,
} from "@sw-sw/ui-inspection-templates";
import classnames from "classnames";
import React, { useContext, useEffect } from "react";

export const TemplateQuestion: RenderContextValue["Question"] = props => {
  const formContext = useContext(FormContext);
  const question = useContext(QuestionContext);
  const group = useContext(QuestionGroupContext);
  const userDataContext = useContext(UserDataContext);

  useEffect(() => {
    if (question.id) {
      /** @important ensure context is aware of question data */
      formContext.set(
        `question-${question.id}`,
        userDataContext.answers[question.id] || "",
      );
    }

    return () => {
      formContext.set(`question-${question.id}`, undefined);
    };
  }, []);

  return (
    <defaultTemplateRenderer.Question
      {...props}
      className={classnames({
        "form-group": group.layout_type === LayoutTypes.FORM,
      })}
    />
  );
};
