import { FormContext, FormContextProvider } from '@sw-sw/lib-form';
import { VersionContext } from '@sw-sw/lib-ui';
import { get, has, isNull } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';
import AppContext from '../../../contexts/AppContext';
import InspectionContext from '../../../contexts/InspectionContext';
import ProjectContext from '../../../contexts/ProjectContext';
import ProjectPermissionContext from '../../../contexts/ProjectPermissionContext';
import { RolesContext } from '../../../contexts/RolesContext';
import { VersionContextProvider } from '../../../contexts/VersionContext';
import { useFindingActions } from '../../../hooks/finding';
import findingApi from '../../../utils/api/finding';
import { ActionButtonsWithScrollFix } from '../../Shared/ActionButtons';
import ToastError from '../../Shared/ToastError/ToastError';
import DeleteFindingModal from './DeleteFindingModal';
import FindingsForm, {
  extractFindingData,
  toImageUpload,
} from "./FindingsForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import FindingReopenConfirmationModal from "./FindingReopenConfirmationModal";
import { FindingType } from "@sw-sw/lib-domain";

function FindingDetailFormUI({
  onBackClick,
  inspectionId,
  finding,
  onSubmit,
  onDelete,
  findingNumber,
  ...props
}) {
  const formContext = useContext(FormContext);
  const inspectionContext = useContext(InspectionContext);
  const projectContext = useContext(ProjectContext);
  const projectPermissionContext = useContext(ProjectPermissionContext);
  const permCheck = useContext(RolesContext).userHasPermission;
  const inspectionComplete = inspectionContext.readOnlyFindings;
  const [isCompleted, setIsCompleted] = useState(
    props.isCompleted || inspectionComplete,
  );
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [showReopenConfirmationModal, setShowReopenConfirmationModal] =
    useState(false);
  const findingActions = useFindingActions({
    onDelete: () => setConfirmDelete(true),
    edit: true,
  });
  const history = useHistory();
  const { findings } = inspectionContext;

  function handleSubmit() {
    formContext.setBusy(true);

    const toastId = toast('Updating finding');

    return findingApi
      .update(finding.id, { ...formContext.value, inspectionId })
      .then((updatedFinding) => {
        // reset comments/observations
        const { observations } = extractFindingData(
          updatedFinding,
          inspectionId,
        );

        formContext.set('comments', '');
        formContext.set('observations', observations);

        // set images from api
        formContext.set(
          'images',
          updatedFinding.finding_uploads.map(toImageUpload),
        );

        // update context
        const { setFindings } = inspectionContext;

        setFindings(
          findings.map((f) =>
            f.id === updatedFinding.id ? updatedFinding : f,
          ),
        );

        setIsCompleted(!isNull(updatedFinding.date_completed));
        formContext.setBusy(false);

        toast.update(toastId, {
          render: 'Updated Finding',
          type: 'success',
        });

        return onSubmit(updatedFinding);
      })
      .catch(async (error) => {
        formContext.setBusy(false);

        let toastMessage = '';

        if (has(error, 'response.data.message')) {
          toastMessage = error.response.data.message;
        } else if (has(error, 'response.data')) {
          toastMessage = error.response.data;
        } else {
          toastMessage =
            'Cannot save finding due to an unknown error. Please try again later.';
        }

        toast.update(toastId, {
          render: (
            <ToastError
              message='Cannot update finding because of error: '
              error={toastMessage}
            />
          ),
          autoClose: false,
          type: 'error',
        });
        setIsCompleted(true);
        inspectionContext.reloadInspection();
      });
  }

  function handleEdit() {
    if (finding.type === FindingType.CA) {
      setShowReopenConfirmationModal(true);
    } else {
      setIsCompleted(false);
    }
  }

  function editText() {
    return permCheck("all", "reopenFinding") ? (
      <React.Fragment>
        <FontAwesomeIcon icon={faPencilAlt} /> Edit Finding
      </React.Fragment>
    ) : null;
  }

  return (
    <section className='finding-detail container'>
      <ActionButtonsWithScrollFix
        primary={isCompleted ? editText() : findingActions.primary}
        primaryDisabled={!formContext.isValid}
        onClick={isCompleted ? handleEdit : handleSubmit}
        secondary={findingActions.secondary}
        busy={showReopenConfirmationModal || formContext.isBusy}
        spacer={false}
      />
      <div className='controls'>
        <h5 className='finding-link'>
          <span
            className='pointer'
            onClick={() => history.push(`/inspection/${inspectionId}/findings`)}
          >
            <i className='fa fa-caret-left pad-right' />
            All Findings
          </span>
        </h5>
        <h3>Overview for #{findingNumber}</h3>
      </div>

      <FindingsForm
        templateName={get(projectContext, 'template.name')}
        readOnly={
          findingActions.primary === null ||
          projectPermissionContext.readOnly ||
          isCompleted
        }
        defaultObservation={get(
          projectContext,
          'project.client.default_finding_observation',
          '',
        )}
        inspectionId={parseInt(inspectionId)}
        templateID={get(projectContext, 'template.id')}
      />

      <DeleteFindingModal
        show={confirmDelete}
        handleClose={() => setConfirmDelete(false)}
        onDelete={onDelete}
        findingId={finding.id}
        inspectionId={inspectionId}
      />
      {showReopenConfirmationModal && (
        <FindingReopenConfirmationModal
          onClose={() => {
            setShowReopenConfirmationModal(false);
          }}
          findingId={finding.id}
          onSuccess={() => {
            formContext.set("date_completed", null);
            inspectionContext.reloadInspection();
            setIsCompleted(false);
          }}
        />
      )}
    </section>
  );
}

/**
 *
 * @param {{getFindings: Function, setFinding: Function}} dependencies
 */
const makeGetFindingApi =
  ({ getFindings, setFinding }) =>
    async (findingsId) => {
      const findings = await getFindings(findingsId);

      setFinding(findings);
    };

/**
 *
 * @param {{ extractFindingData: Function, setInitialValue: Function }} dependencies
 */
const makeSetFormData =
  ({ extractFindingData: extractFindingDataProp, setInitialValue }) =>
    (finding, inspectionId) => {
      const initialValue = extractFindingDataProp(finding, inspectionId);

      setInitialValue(initialValue);
    };

const useFormData = (finding) => {
  const [initialValue, setInitialValue] = useState(null);
  const setFormData = makeSetFormData({
    extractFindingData,
    setInitialValue,
  });

  useEffect(() => {
    if (!finding) {
      return;
    }

    setFormData(finding);
  }, [finding]);

  return initialValue;
};

const useGetFindingApi = (findingsId) => {
  const [finding, setFinding] = useState(null);
  const appContext = useContext(AppContext);
  const getFindings = makeGetFindingApi({
    getFindings: findingApi.show,
    setFinding,
  });

  useEffect(() => {
    appContext.loadData(() => getFindings(findingsId), 'Finding');
  }, []);

  return finding;
};

function FindingDetail({ onBackClick, onSubmit, onDelete }) {
  const { userHasPermission } = useContext(RolesContext);
  const { inspection } = useContext(InspectionContext);
  const match = useRouteMatch();
  const finding = useGetFindingApi(match.params.findingId);
  const initialValue = useFormData(finding);

  if (!initialValue || !finding) {
    return null;
  }

  return (
    <VersionContextProvider>
      <FormContextProvider initialValue={initialValue}>
        <VersionContext.Consumer>
          {(versionContext) => (
            <FindingDetailFormUI
              inspectionId={match.params.inspectionId}
              finding={finding}
              onBackClick={onBackClick}
              onSubmit={() => {
                versionContext.resetDate();
                onSubmit();
              }}
              onDelete={onDelete}
              permCheck={userHasPermission}
              isCompleted={
                !isNull(initialValue.date_completed) ||
                !isNull(inspection.compliance_date)
              }
              findingNumber={finding.number}
            />
          )}
        </VersionContext.Consumer>
      </FormContextProvider>
    </VersionContextProvider>
  );
}

export default FindingDetail;
