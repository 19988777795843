import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import classnames from "classnames";
import PropTypes from "prop-types";

const DATE_FORMAT = "MMMM YYYY";

class DashHeader extends Component {
  static propTypes = {
    activeView: PropTypes.string,
    views: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        label: PropTypes.string,
      }),
    ),
    setView: PropTypes.func,
    date: PropTypes.object,
    increment: PropTypes.func,
    decrement: PropTypes.func,
    isCalendar: PropTypes.bool.isRequired,
    switchToList: PropTypes.func,
    switchToCalendar: PropTypes.func,
  };

  static Title = ({ children }) => <h3>{children}</h3>;

  static Buttons = ({ isCalendar, switchToList, switchToCalendar }) => (
    <div className="view-buttons">
      <button
        className={classnames("view-button", {
          active: !isCalendar,
        })}
        onClick={switchToList}
        title="List view"
      >
        <FontAwesomeIcon icon={faBars} className="icon" />
        <span className="button-text">List View</span>
      </button>

      <button
        className={classnames("view-button", {
          active: isCalendar,
        })}
        onClick={switchToCalendar}
        title="Calendar view"
      >
        <FontAwesomeIcon icon={faCalendar} className="icon" />
        <span className="button-text">Calendar View</span>
      </button>
    </div>
  );

  static DateSelect = ({ isCalendar, date, increment, decrement }) => {
    if (!isCalendar) return null;

    return (
      <div className="date-selector">
        <button onClick={decrement} className="date-change-button">
          <div className="icon-holder">
            <FontAwesomeIcon icon={faAngleLeft} />
          </div>
        </button>

        <span className="active-date">{date.format(DATE_FORMAT)}</span>

        <button onClick={increment} className="date-change-button">
          <div className="icon-holder">
            <FontAwesomeIcon icon={faAngleRight} />
          </div>
        </button>
      </div>
    );
  };

  static Tabs = ({ activeView, setView, views }) => {
    if (views.length === 0) return null;

    return (
      <div className="dash-tab-menu">
        {views.map(({ key, label }) => (
          <h3
            key={key}
            className={classnames("dash-tab", {
              "active-tab": key === activeView,
            })}
            onClick={() => setView(key)}
          >
            {label}
          </h3>
        ))}
      </div>
    );
  };

  render() {
    const { children, ...props } = this.props;

    return (
      <div
        className={classnames(
          "inspections-paper-header",
          this.props.isCalendar ? "calendar" : "list",
        )}
      >
        {/* pass props along to children */}
        {React.Children.map(children, child => {
          return React.isValidElement(child)
            ? React.cloneElement(child, props)
            : null;
        })}
      </div>
    );
  }
}

export default DashHeader;
