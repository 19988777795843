import instance from "../xhrService";
import { Address, State } from "./address";
import { Project } from "./project";

const baseUrl = "/api/clients";
const geoDataUrl = `${baseUrl}/geoData`;
const getClientsUrl = (a: number) => `${baseUrl}/${a}`;
const getContactsUrl = (a: number, b?: number) =>
  `${baseUrl}/${a}/contacts${b ? `/${b}` : ""}`;

export type Contact = {
  id: number;
  first_name: string;
  last_name: string;
  name: string;
  email: string;
};

export type Client = {
  id: number;
  name: string;
  default_finding_observation?: string;
  users: Array<any>;
  address: Address;
};

type ClientApi = {
  index: (clientIds?: Array<number>) => Promise<Array<Client>>;
  show: (clientId: number) => Promise<Client>;
  create: (data: Omit<Client, "id">) => Promise<Client>;
  update: (clientId: number, data: Omit<Client, "id">) => Promise<Client>;
  destroy: (clientId: number) => Promise<any>;
  geoData: {
    index: () => Promise<Array<State>>;
  };
  projects: {
    index: (clientId: number) => Promise<Array<Project>>;
  };
  contacts: {
    create: (clientId: number, userIds: Array<number>) => Promise<any>;
    destroy: (clientId: number, userId: number) => Promise<any>;
  };
};

export const clientApi: ClientApi = {
  /**
   * By default route will return all clients.
   * Pass an array of client ids to retrieve only those clients
   */
  index: clientIds => {
    return instance
      .get(
        baseUrl,
        clientIds
          ? {
              params: {
                clientIds,
              },
            }
          : {},
      )
      .then(res => res.data);
  },
  show: clientId => {
    return instance.get(getClientsUrl(clientId)).then(res => res.data);
  },
  create: data => instance.post(baseUrl, data).then(res => res.data),
  update: (clientId, data) => {
    return instance.put(getClientsUrl(clientId), data).then(res => res.data);
  },
  destroy: clientId => {
    return instance.delete(getClientsUrl(clientId)).then(res => res.data);
  },

  geoData: {
    index: () => instance.get(geoDataUrl).then(res => res.data),
  },

  projects: {
    index: clientId =>
      instance.get(`/api/clients/${clientId}/projects`).then(res => res.data),
  },

  contacts: {
    /**
     * @param {number} clientId
     * @param {number[]} userIds
     */
    create: (clientId, userIds) => {
      return instance
        .post(getContactsUrl(clientId), { userIds })
        .then(res => res.data);
    },

    destroy: (clientId, userId) => {
      return instance
        .delete(getContactsUrl(clientId, userId))
        .then(res => res.data);
    },
  },
};

export default clientApi;
