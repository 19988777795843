import React, { useContext } from "react";
import { string, bool, number, shape, func } from "prop-types";

import FormModal from "../../Shared/form/modal/FormModal";
import { defaultFindingObservationTemplates } from "../../../utils/clients";
import { FormContext, FormContextProvider } from "@sw-sw/lib-form";
import { FormSchemaFields } from "@sw-sw/lib-form";
import validator from "../../../utils/FormValidator";
import Loading from "../../Shared/ResourceIndex/Loading";
import { useStates } from "../../../hooks/address";
import { UIControlType } from "@sw-sw/lib-form-control-types";

function getSchema(states) {
  return {
    name: {
      label: "Client Name",
      autoComplete: "organization",
      validation: {
        required: true,
      },
    },
    phone: {
      label: "Phone",
      autoComplete: "tel",
      mask: "phone",
      validation: {
        required: true,
        format: "phone",
      },
    },
    street1: {
      label: "Address",
      placeholder: "Street",
      autoComplete: "address-line1",
      "aria-label": "Street address line 1 of 2",
      validation: {
        required: true,
      },
    },
    street2: {
      placeholder: "Street Line 2",
      "aria-label": "Street address line 2 of 2",
      autoComplete: "address-line2",
    },
    city: {
      placeholder: "City",
      "aria-label": "City",
      autoComplete: "address-level2",
      validation: {
        required: true,
      },
    },
    state: {
      controlType: UIControlType.select,
      placeholder: "State",
      "aria-label": "State",
      autoComplete: "address-level1",
      options: states,
      labelKey: "name",
      valueKey: "id",
      style: { flex: "1 1 50%" },
      validation: {
        required: true,
      },
    },
    zip: {
      placeholder: "Zip",
      "aria-label": "zip code",
      className: "zip",
      autoComplete: "postal-code",
      maxLength: 5,
      style: { flex: "1 1 50%" },
      validation: {
        required: true,
      },
      inputMode: "numeric",
      parse: validator.parseNumber,
    },
    default_finding_observation: {
      label: "Default Finding Observation",
      controlType: UIControlType.textareaTmpl,
      templates: defaultFindingObservationTemplates,
    },
    disable_images: {
      label: "Disable Images",
      controlType: UIControlType.checkbox,
      placeholder: "Disable Images",
      "aria-label": "Disable Images",
    }
  };
}

function getInitialData(client) {
  const { address = {} } = client;

  return {
    name: client.name,
    street1: address.street_1,
    street2: address.street_2,
    city: address.city,
    state: address.state ? address.state.id : undefined,
    zip: address.zip,
    phone: address.phone,
    default_finding_observation: client.default_finding_observation,
    disable_images: client.disable_images,
  };
}

function ClientDetailsFormUI({ states, ...props }) {
  const formContext = useContext(FormContext);

  return (
    <FormModal
      className="add-client-form"
      onCancel={props.handleClose}
      onSubmit={props.handleSubmit}
      modalProps={{
        title: props.title,
      }}
    >
      <FormSchemaFields
        schema={getSchema(states)}
        onChange={formContext.set}
        formData={formContext.value}
        initialFormData={getInitialData(props.client)}
      />
    </FormModal>
  );
}

function ClientDetailsForm(props) {
  const statesQuery = useStates();

  if (!props.show) return null;

  if (statesQuery.isLoading) return <Loading />;

  return (
    <FormContextProvider>
      <ClientDetailsFormUI states={statesQuery.data} {...props} />
    </FormContextProvider>
  );
}

const clientShape = shape({
  name: string,
  address: shape({
    phone: string,
    street_1: string,
    street_2: string,
    city: string,
    zip: string,
    state: shape({ id: number }),
  }),
});

ClientDetailsForm.propTypes = {
  // initial data
  client: clientShape,
  // modal props
  handleSubmit: func.isRequired,
  handleClose: func.isRequired,
  show: bool.isRequired,
  title: string.isRequired,
};

ClientDetailsForm.defaultProps = {
  client: {},
};

export default ClientDetailsForm;
