import { LoadingMessage } from "@sw-sw/lib-ui";
import { find } from "lodash";
import React, { useContext, useEffect } from "react";
import { useQuery } from "react-query";
import { InspectionContext } from "../../../contexts/InspectionContext";
import findingApi from "../../../utils/api/finding";
import FindingCard from "./FindingCard";

export default function FindingListing() {
  const inspectionContext = useContext(InspectionContext);

  if (!inspectionContext.inspection.id) {
    throw new Error("inspection must be loaded in InspectionContext");
  }

  const findingsQuery = useQuery({
    queryFn: () => findingApi.index(inspectionContext.inspection.id),
    queryKey: ["inspectionFindings", inspectionContext.inspection.id],
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  useEffect(() => {
    if (!findingsQuery.isFetching) {
      const findings = inspectionContext.findings.map(finding => {
        return {
          ...finding,
          ...(find(findingsQuery.data, { id: finding.id }) || {}),
        };
      });

      inspectionContext.setFindings(findings);
    }
  }, [findingsQuery.isFetching]);

  return (
    <section className="pure-g findings-listing">
      {findingsQuery.isLoading ? (
        <LoadingMessage what="notes and images" className="pure-u pure-u-1-1" />
      ) : null}

      {!inspectionContext.findings.length ? (
        <p className="pure-u pure-u-1-1">No findings</p>
      ) : (
        inspectionContext.findings
          .sort((a, b) => a.number - b.number)
          .map((finding, index) => (
            <FindingCard key={index} finding={finding} />
          ))
      )}
    </section>
  );
}

FindingListing.propTypes = {};
