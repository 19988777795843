import { faTimes, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { getUtil } from "../../utils/positionable";
import ButtonGroup from "../Shared/ButtonGroup/ButtonGroup";
import HandIcon from "../Shared/Icons/Hand";
import LineIcon from "../Shared/Icons/Line";
import MouseIcon from "../Shared/Icons/Mouse";
import NoteIcon from "../Shared/Icons/Note";
import PointIcon from "../Shared/Icons/Point";
import usePositionableSourceModel from "./hooks/usePositionableSourceModel";
import { Context as InteractionContext } from "./Interaction/InteractionContext";
import { Context as InstanceDataContext } from "./Positionable/InstanceDataContext";

/**
 * Initial state of options
 *
 * @typedef defaultOptions
 * @type {Array}
 */
export const getDefaultOptions = permCheck => [
  {
    label: "Move",
    name: "modify",
    iconComponent: HandIcon,
    disabled: true,
  },
  {
    label: "Select",
    name: "select",
    iconComponent: MouseIcon,
    separatorAfter: true,
  },
  {
    label: "Draw Point",
    name: "point",
    iconComponent: PointIcon,
    disabled: true,
  },
  {
    label: "Draw Line",
    name: "line",
    iconComponent: props => <LineIcon rotate={45} {...props} />,
    disabled: true,
  },
  {
    label: "Draw Circle",
    name: "circle",
    icon: "shapes",
    separatorAfter: true,
    disabled: true,
  },
  {
    label: "Note",
    name: "note",
    iconComponent: NoteIcon,
    disabled: !permCheck("all", "Map Drawing"),
  },
];

/** @param {defaultOptions} state */
const reducer = (state, action) => {
  switch (action.type) {
    case "select":
      return state.map(option => {
        return {
          ...option,
          active: option.name === action.payload,
        };
      });
    case "disable":
      return state.map(option => {
        return {
          ...option,
          disabled: option.name === action.payload ? true : option.disabled,
        };
      });

    case "enable":
      return state.map(option => {
        return {
          ...option,
          disabled: option.name === action.payload ? false : option.disabled,
        };
      });
    default:
      throw new Error();
  }
};

function CancelButton(props) {
  return (
    <>
    {props.isTabletOrLess ? 
      <FontAwesomeIcon icon={faTimes} {...props}/> : 
      <button className="pure-button pure-button-secondary" {...props}>
        <span>Cancel</span>
      </button>
    }      
    </>
  );
}

/**
 * Map Editor "Toolbar"
 *
 * Only "shows" the active tools, controlled by user interactions on map
 * Provides api for user to save or discard changes
 *
 * @todo separator in options
 */
export function Toolbar(props) {
  const { permCheck } = props;
  const defaultOptions = getDefaultOptions(permCheck);
  const [options, dispatch] = useReducer(reducer, defaultOptions);
  const interactionContext = useContext(InteractionContext);
  const dataContext = useContext(InstanceDataContext);
  const sourceModel = usePositionableSourceModel();
  const [busy, setBusy] = useState(false);
  const isTabletOrLess = useMediaQuery({ maxWidth: 768 })

  if (!dataContext) {
    return null;
  }

  /**
   * select active button, based on interaction mode type
   *
   * allows selecting appropriate button for line/point/polygon/circle
   */
  useEffect(() => {
    if (interactionContext) {
      if (sourceModel && interactionContext.mode === "create") {
        dispatch({
          type: "select",
          payload: getUtil(sourceModel.positionableType).getDrawingType(
            sourceModel,
          ),
        });
      } else {
        dispatch({
          type: "select",
          payload: "",
        });
      }

      if (interactionContext.mode === "modify") {
        dispatch({
          type: "enable",
          payload: "modify",
        });
      } else {
        dispatch({
          type: "disable",
          payload: "modify",
        });
      }

      if (interactionContext.mode === "select") {
        dispatch({
          type: "enable",
          payload: "select",
        });
      }
    }
  }, [interactionContext, sourceModel]);

  const onClickToolbarItem = index => {
    const name = options[index].name;

    switch (name) {
      case "point":
      case "line":
      case "circle":
        if (interactionContext.mode === "create") {
          interactionContext.setDefaultMode();
        }
        break;
      case "note":
        interactionContext.setPositionableType("commentables");
        interactionContext.setMode("createSourceModel");
        break;
      default:
        break;
    }
  };

  const onDiscard = () => {
    dataContext.discard();
    interactionContext.setPositionableType(null);
  };
  const onSave = () => {
    setBusy(true);
    interactionContext.setDefaultMode();

    dataContext.save().then(
      () => {
        interactionContext.setPositionableSourceModel(null);
        setBusy(false);
      },
      err => {
        setBusy(false);

        return Promise.reject(err);
      },
    );
  };

  return (
    <div className="map-editor-toolbar">
      <div>
        <ButtonGroup options={options} onChange={onClickToolbarItem} />
      </div>
      
        {dataContext.hasPendingUpdates && (
          <div className="map-editor-toolbar-actions">
            <CancelButton onClick={onDiscard} isTabletOrLess={isTabletOrLess}/>
            {isTabletOrLess ? 
              <FontAwesomeIcon className="map-editor-save-icon" icon={faSave} onClick={onSave}/> :
              <button
                className="pure-button pure-button-primary"
                onClick={onSave}
                disabled={busy}
              >
                <span>Save</span>
              </button>
            }
          </div>
        )}
        {!dataContext.hasPendingUpdates && interactionContext.mode === "modify" && (
          <div className="map-editor-toolbar-actions">
            <CancelButton onClick={onDiscard} isTabletOrLess={isTabletOrLess}/>
          </div>
        )}
    </div>
  );
}

export default Toolbar;
