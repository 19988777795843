import { FormContext, FormContextProvider } from "@sw-sw/lib-form";
import { getRecordReducer, Modal } from "@sw-sw/lib-ui";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { Redirect } from "react-router-dom";
import AppContext from "../../../contexts/AppContext";
import { AppDivisionContext } from "../../../contexts/AppDivisionContext";
import AuthenticationContext from "../../../contexts/AuthenticationContext";
import userApi from "../../../utils/api/user";
import FormActions from "../../Shared/form/modal/FormActions";
import SuccessModal from "../../Shared/SuccessModal/SuccessModal";
import RegisterUserForm from "../Forms/Register";

const RegistrationFormUI = ({ onSubmit }) => {
  const formContext = useContext(FormContext);

  return (
    <div>
      <RegisterUserForm
        formData={formContext.value}
        onChange={formContext.set}
      />
      <FormActions onSubmit={onSubmit} showCancel={false} />
    </div>
  );
};

function InspectorRegistrationForm(props) {
  const { uuid } = props.match.params;
  const appStore = useContext(AppContext);
  const authStore = useContext(AuthenticationContext);
  const appDivisionContext = useContext(AppDivisionContext)

  const [redirectPath, setRedirect] = useState(false);
  const [errorMsg, setError] = useState(null);
  const [state, setState] = useReducer(getRecordReducer(), {
    successMsg: false,
    valid: false,
    isLoaded: false,
  });
  const [initialData, setInitialData] = useState(null);

  useEffect(() => {
    if (!state.isLoaded) {
      userApi
        .validate(uuid)
        .then(data => {
          setState({
            type: "merge",
            payload: {
              valid: true,
              isLoaded: true,
            },
          });
          const { first_name, last_name, position } = data.user;

          setInitialData({ first_name, last_name, position });
        })
        .catch(err => {
          setError(
            err.response && err.response.data && err.response.data.message
              ? err.response.data.message
              : null,
          );

          setState({
            type: "merge",
            payload: {
              isLoaded: true,
              valid: false,
            },
          });
          setInitialData({});
          scheduleRedirect("/");
        });
    }
  }, []);

  const handleSubmit = formData => {
    return authStore.register(uuid, formData).then(() => {
      setState({
        type: "merge",
        payload: {
          successMsg: true,
        },
      });

      scheduleRedirect(appDivisionContext.getPath("/dashboard"));
    });
  };

  const scheduleRedirect = path => {
    window.setTimeout(() => {
      appStore.reloadInitialData().then(() => {
        setRedirect(path);
      });
    }, 5000);
  };

  // not loaded
  if (!state.isLoaded || !initialData) return null;

  if (redirectPath) {
    return <Redirect to={redirectPath} />;
  }

  // invalid user
  if (!state.valid) {
    return (
      <Modal
        show
        disableClose
        hideButtons
        handleClose={() => {}}
        title="Registration Error"
        classes="registration-form"
      >
        <p>{errorMsg || "Unknown Error"}</p>
        <p>You will be redirected momentarily.</p>
      </Modal>
    );
  }

  // submit form success!
  if (state.successMsg) {
    return (
      <SuccessModal
        show
        disableClose
        hideButtons
        handleClose={() => {}}
        title="Registration Success"
        message="You will be redirected, momentarily."
        classes="registration-form"
      />
    );
  }

  return (
    <Modal
      show
      disableClose
      hideButtons
      handleClose={() => {}}
      title="Complete Registration"
      errorMsg={errorMsg}
      submitBtnText="Done"
      classes="registration-form"
      handleSubmit={() => {}}
    >
      <p>Please complete the form below to finish setting up your account.</p>
      <p>All fields are required.</p>

      <FormContextProvider initialValue={initialData}>
        <RegistrationFormUI onSubmit={handleSubmit} />
      </FormContextProvider>
    </Modal>
  );
}

InspectorRegistrationForm.propTypes = {};

export default InspectorRegistrationForm;
