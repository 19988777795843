import xhrService from "../xhrService";

const isLoggedIn = () => {
  const token = localStorage.getItem("token");

  return token && token.length && token.length > 0;
};

const saveToken = token => localStorage.setItem("token", JSON.stringify(token));

export const userApi = {
  index: (context = null, pageNumber = null, searchQuery = null) => {
    const params = {};

    if (context) {
      params.context = context;
    }

    if (searchQuery) {
      params.s = searchQuery;
    }

    if (pageNumber) {
      params.p = pageNumber;
    }

    return xhrService.get("/api/users").then(res => res.data);
  },
  create: data => xhrService.post("/api/users", data).then(res => res.data),
  get: (id, includeFormData = false) => {
    const params = {};

    if (includeFormData) {
      params.context = "form";
    }

    return xhrService.get(`/api/users/${id}`, { params }).then(res => res.data);
  },
  getProfile: id => {
    return xhrService.get(`/api/users/profile/${id}`).then(res => res.data);
  },

  getCreateOpts: data => {
    //(roleId, clientIds) => {
    return xhrService
      .get(`/api/users/groups/creation`, { params: data })
      .then(res => res.data);
  },

  isLoggedIn,
  login: (email, password) =>
    xhrService
      .post("/api/login", { email, password })
      .then(({ data }) => {
        if (data.token) {
          saveToken(data.token);
        }

        // don't leak token throughout application
        return true;
      })
      .catch(error => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          return Promise.reject(error.response.data.message);
        }

        return Promise.reject(null);
      }),
  saveToken,
  logout: () => {
    localStorage.removeItem("token");
    localStorage.removeItem("divisionId");
  },
  resetPassword: (token, form) => {
    return xhrService
      .post(`/api/resetPassword`, { token, ...form })
      .then(({ data }) => {
        if (data.token) {
          saveToken(data.token);
        }

        return data;
      });
  },
  forgotPassword: email => {
    return xhrService.post("/api/forgotPassword", email).then(res => res.data);
  },
  register: (token, form) => {
    return xhrService
      .post(`/api/register`, {
        token,
        ...form,
      })
      .then(res => res.data)
      .then(data => {
        if (data.token) {
          saveToken(data.token);
        }

        return data;
      });
  },

  /** Get the profile of logged in user */
  profile: () => {
    if (isLoggedIn()) {
      return xhrService
        .get("/api/users/profile")
        .then(res => res.data)
        .catch(() => {
          // invalid or deprecated token
          localStorage.removeItem("token");

          return Promise.reject(null);
        });
    }

    return Promise.reject("User is not logged in");
  },
  update: (id, data) =>
    xhrService.post(`/api/users/update/${id}`, data).then(res => res.data),

  updateProfile: data =>
    xhrService.post(`/api/users/profile`, data).then(res => res.data),

  /**
   * Validate a user
   */
  validate: token =>
    xhrService.post(`/api/user/validate`, { token }).then(res => res.data),

  /**
   * On confirm, resend user invitation
   */
  invite: id =>
    xhrService.post(`/api/users/${id}/invite`).then(res => res.data),

  toggleActivation: id =>
    xhrService.put(`/api/users/${id}/status`).then(res => res.data),

  updateElectronicSignatureAuthorization: (id, action) =>
    xhrService
      .post(`/api/users/${id}/signature_authorization/${action}`)
      .then(res => res.data),
};

export default userApi;
