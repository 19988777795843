import { find } from "lodash";
import React, { useState } from "react";
import findingApi from "../utils/api/finding";
import inspectionApi from "../utils/api/inspection";
import { parseYMD } from "../utils/date";

const Context = React.createContext();

export const isInspectionSigned = inspection =>
  !!inspection.certification_date && !!inspection.compliance_date;

export const InspectionStore = props => {
  const [inspection, setInspection] = useState({});
  const [selectedFindings, setSelectedFindings] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [findings, setFindings] = useState([]);
  const [inspectionLoaded, setInspectionLoaded] = useState(false);
  const [template, setTemplate] = useState(null);

  const resetTemplate = () => {
    setTemplate(null);
    setTemplate(template);
  };

  const setSaveError = err => {
    return err.response.data.message;
  };

  const handleSelect = id => {
    const newState = [...selectedFindings];

    if (newState.includes(id)) {
      const filterState = newState.filter(item => !(item === id));

      setSelectedFindings(filterState);
      if (allSelected) {
        setAllSelected(false);
      }
    } else {
      newState.push(id);
      const newAllSelected =
        findings.filter(finding => !finding.date_completed).length ===
        newState.filter(finding => !finding.date_completed).length;

      setAllSelected(newAllSelected);
      setSelectedFindings(newState);
    }
  };

  const completeFindings = formData => {
    return findingApi.bulk
      .complete(inspection.id, formData)
      .then(newFindings => {
        const updatedFindingIds = newFindings.map(f => f.id);
        const existingFindings = findings.filter(
          f => !updatedFindingIds.includes(f.id),
        );

        setFindings([...existingFindings, ...newFindings]);
      });
  };

  const bulkAddNotes = (id, formFindings, comments) => {
    return findingApi.bulk.addNotes(id, formFindings, comments).then(resp => {
      setFindings(resp.data);
      setAllSelected(false);
      setSelectedFindings([]);
    });
  };

  const selectAllFindings = () => {
    if (allSelected) {
      setAllSelected(false);
      setSelectedFindings([]);
    } else {
      setAllSelected(true);
      setSelectedFindings(
        findings
          .filter(finding => !finding.date_completed)
          .map(finding => finding.id),
      );
    }
  };
  const handleDefaultChecks = checkedFindings => {
    setFindings(checkedFindings);
    setSelectedFindings(checkedFindings.map(finding => finding.id));
    setAllSelected(true);
  };

  const reloadInspection = async () => {
    if (inspection) {
      const data = await inspectionApi.get(inspection.id);

      setInspection(data.inspection);
    }
  };

  return (
    <Context.Provider
      value={{
        inspection,
        inspectionId: inspection ? inspection.id : null,
        reloadInspection,
        findings,
        allSelected,
        setFindings,

        // finding selection
        selectedFindings,
        setAllSelected,
        setSelectedFindings,
        selectAllFindings,
        updateSelectedFindings: handleSelect,

        completeFindings,

        handleDefaultChecks,
        resetInspection: () => {
          setFindings([]);
          setSelectedFindings([]);
          setAllSelected(false);
          setInspectionLoaded(false);
          setInspection({});
        },
        updateInspection: setInspection,
        inspectionLoaded,
        setInspectionLoaded,
        bulkAddNotes,

        readOnlyFindings: isInspectionSigned(inspection),

        // calculate the default date for initiating findings, based on inspection due date
        getDefaultFindingDate: () => {
          if (!inspection || !inspection.created_date) {
            return new Date();
          }

          return parseYMD(inspection.created_date);
        },
        getProjectId: () => inspection.project_id,
        onSave: formValues => {
          if (!formValues) {
            throw new Error("`formValues` is required");
          }

          return inspectionApi.update(inspection.id, formValues).then(data => {
            setInspection({ ...data.inspection, number: data.inspNumber });

            return data;
          });
          // .catch(err => {
          //   console.trace("inspectionContext.onSave", err);

          //   // return Promise.reject(err);
          // });
        },

        onUpdateFindings: updatedFindings => {
          const updatedInspection = {
            // copy all inspection properties
            ...inspection,
            // loop through current findings
            findings: inspection.findings.map(currentFinding => {
              // find by id
              const newFinding = find(
                updatedFindings,
                updatedFinding => updatedFinding.id === currentFinding.id,
              );

              // only need to update the comments and date_complete props
              return newFinding
                ? {
                    ...currentFinding,
                    commentables: newFinding.commentables,
                    date_completed: newFinding.date_completed,
                  }
                : currentFinding;
            }),
          };

          setInspection(updatedInspection);
        },

        template,
        setTemplate,
        resetTemplate,
        setSaveError,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export default Context;
export const InspectionContext = Context;
