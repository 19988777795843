import React, { useState, useCallback, Fragment } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { flatten } from "lodash";
import PropTypes from "prop-types";

import DashTable from "../utils/DashTable";
import DashCalendar from "../utils/DashCalendar";
import DashHeader from "../utils/DashHeader";
import DashboardViews from "../utils/DashboardViews";
import ReassignModal from "../ReassignModal";

const views = [
  { key: "inspectors", label: "Inspectors" },
  { key: "inspectionsDue", label: "Inspections Due" },
];

const tableKeys = ["siteName", "clientName", "overdueItems", "dueDate"];

function getMenuOptions({ inspectionId }) {
  return [
    <Link
      to={`/inspection/${inspectionId}/questionnaire`}
      className="unStyled-link"
      key={inspectionId}
    >
      <li>Start Inspection</li>
    </Link>,
  ];
}

function extractInspectorData(inspector) {
  return inspector.inspections.map(inspection => ({
    inspectorId: inspector.id,
    ...inspection,
  }));
}

function TableView({ data, activeView, ...props }) {
  const [expanded, setExpanded] = useState([]);

  const handleRowClick = useCallback(
    i => {
      if (expanded.includes(i)) {
        const filterState = expanded.filter(item => item !== i);

        setExpanded(filterState);
      } else {
        setExpanded([...expanded, i]);
      }
    },
    [expanded],
  );

  if (activeView === "inspectionsDue") {
    return (
      <DashTable
        {...props}
        tableData={data}
        tableKeys={tableKeys}
        emptyMessage="No Inspections Assigned"
      />
    );
  }

  return (
    <section className="table-holder">
      <table className="list-view pure-table pure-table-horizontal area-manager-table">
        <thead className="list-header">
          <tr>
            <th>Name</th>
            <th className="caret-cell" />
          </tr>
        </thead>
        <tbody>
          {data.map((inspector, i) => (
            <InspectorTable
              tableKeys={tableKeys}
              expanded={expanded}
              handleRowClick={handleRowClick}
              getMenuOptions={getMenuOptions}
              inspector={inspector}
              index={i}
              key={i}
              {...props}
            />
          ))}
        </tbody>
      </table>
    </section>
  );
}

function InspectorTable({
  inspector,
  index,
  handleRowClick,
  expanded,
  ...dashTableProps
}) {
  const inspections = extractInspectorData(inspector);

  return (
    <Fragment>
      <tr onClick={() => handleRowClick(index)} className="pointer">
        <td>{inspector.name}</td>
        <td className="caret-cell">
          <FontAwesomeIcon
            icon={expanded.includes(index) ? faAngleUp : faAngleDown}
            className="caret"
          />
        </td>
      </tr>

      {expanded.includes(index) && (
        <tr key={`expansion-${index}`}>
          <td className="expansion-cell" colSpan={3}>
            <DashTable
              {...dashTableProps}
              tableData={inspections}
              emptyMessage="No Inspections Scheduled"
              hideHeaderOnEmpty
            />
          </td>
        </tr>
      )}
    </Fragment>
  );
}

const AreaManagerView = props => {
  const [showModal, setShowModal] = useState(false);
  const [formInitialData, setFormInitialData] = useState({
    inspectorId: 0,
    inspectionId: 0,
    projectId: 0,
  });

  const onCtaClick = useCallback(
    ({ inspectorId, inspectionId, projectId }) => {
      setFormInitialData({
        inspectionId,
        inspectorId,
        projectId,
      });
      setShowModal(true);
    },
    [showModal, formInitialData],
  );

  return (
    <Fragment>
      <DashboardViews views={views}>
        {({ isCalendar, date, activeView, ...headerProps }) => (
          <Fragment>
            <DashHeader
              isCalendar={isCalendar}
              date={date}
              activeView={activeView}
              {...headerProps}
            >
              <DashHeader.Tabs />
              <DashHeader.DateSelect />
              <DashHeader.Buttons />
            </DashHeader>

            {isCalendar ? (
              <DashCalendar
                data={
                  activeView === "inspectionsDue"
                    ? props.data[activeView]
                    : flatten(
                        props.data[activeView].map(insp =>
                          extractInspectorData(insp),
                        ),
                      )
                }
                activeDate={date}
                getCtaText={() => "Reassign"}
                onCtaClick={onCtaClick}
              />
            ) : (
              <TableView
                activeView={activeView}
                data={props.data[activeView]}
                onCtaClick={onCtaClick}
                getCtaText={() =>
                  activeView === "inspectors" ? "Reassign" : "Complete"
                }
              />
            )}
          </Fragment>
        )}
      </DashboardViews>
      <ReassignModal
        initialId={formInitialData.inspectorId}
        projectId={formInitialData.projectId}
        show={showModal}
        hideModal={() => setShowModal(false)}
        inspectionId={formInitialData.inspectionId}
        handleReassign={props.handleReassign}
        users={props.data.inspectors.map(inspector => ({
          value: inspector.id,
          label: inspector.name,
        }))}
      />
    </Fragment>
  );
};

const inspectionShape = {
  projectId: PropTypes.number,
  inspectionId: PropTypes.number,
  inspectionStatus: PropTypes.string,
  dueDate: PropTypes.string,
  inspectionOverdue: PropTypes.bool,
  overdueItems: PropTypes.number,
  siteName: PropTypes.string,
  clientName: PropTypes.string,
};

const directReportShape = {
  name: PropTypes.string,
  inspections: PropTypes.arrayOf(PropTypes.shape(inspectionShape)),
};

AreaManagerView.propTypes = {
  data: PropTypes.shape({
    inspectors: PropTypes.arrayOf(PropTypes.shape(directReportShape)),
    inspectionsDue: PropTypes.arrayOf(PropTypes.shape(inspectionShape)),
  }),
  handleReassign: PropTypes.func.isRequired,
};

export default AreaManagerView;
