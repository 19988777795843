import React, { useContext } from "react";
import { ProjectContext } from "../../../contexts/ProjectContext";
import { useProjectCorrectiveActions } from "../../../hooks/projectDetails";
import FindingCard from "../../Inspections/Findings/FindingCard";
import Loading from "../../Shared/ResourceIndex/Loading";

export default function FindingListing(_props: {
  showFindings: string;
  inspectionId: number;
  Finding: string;
}) {
  const projectContext = useContext(ProjectContext);
  const correctiveActionsQuery = useProjectCorrectiveActions(
    projectContext.project ? projectContext.project.id : 0,
  );

  if (!correctiveActionsQuery.data) {
    return <Loading what="corrective actions" />;
  }

  return (
    <div className="inspection-findings">
      <section className="pure-g findings-listing">
        {!correctiveActionsQuery.data.length ? (
          <p className="pure-u">No findings</p>
        ) : (
          correctiveActionsQuery.data.map((correctiveActionFinding: any) => (
            <FindingCard
              key={correctiveActionFinding.id}
              finding={correctiveActionFinding}
            />
          ))
        )}
      </section>
    </div>
  );
}
