/**
 * Custom routes with special guards in the render function
 */

import React, { useContext, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import AppContext from "../../contexts/AppContext";
import AuthenticationContext from "../../contexts/AuthenticationContext";
import RolesContext from "../../contexts/RolesContext";
import Loading from "../Shared/ResourceIndex/Loading";

/**
 * Private route. Only accessible by logged-in user.
 */
export const PrivateRoute = routeProps => {
  const { Component, Layout, checking, ...rest } = routeProps;
  const appStore = useContext(AppContext);
  const authStore = useContext(AuthenticationContext);
  const rolesStore = useContext(RolesContext);
  const user = appStore.get("user");

  if (!appStore.initialDataLoaded) {
    return <Loading />;
  }

  return (
    <Route
      exact
      {...rest}
      render={props =>
        authStore.loggedIn &&
        rolesStore.navigationIsAllowed(props.location.pathname) ? (
          <Layout user={user} checking={checking} {...props}>
            <Component user={user} {...props} />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              search: `?to=${props.location.pathname}${props.location.search}`,
              state: { from: props.location.pathname },
            }}
          />
        )
      }
    />
  );
};

/**
 * Public route. Only acessible by annon/guest user
 */
export const PublicRoute = routeProps => {
  const { Component, Layout, ...rest } = routeProps;
  const authStore = useContext(AuthenticationContext);

  /** When logged in, force logout, before rendering */
  useEffect(() => {
    if (authStore.loggedIn) {
      authStore.logout();
    }
  }, []);

  return (
    <Route
      exact
      {...rest}
      render={props => (
        <Layout {...props}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

/**
 * Generic route. Accesible by anyone.
 */
export const GenericRoute = routeProps => {
  const { Component, Layout, ...rest } = routeProps;

  return (
    <Route
      exact
      {...rest}
      render={props => (
        <Layout {...props}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

export { DivisionRoute } from "./DivisionRoute";
export { RedirectRoute } from "./RedirectRoute";
